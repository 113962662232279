import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
export const CREATE_INTERNAL_ISSUE = 'create_internal_issue';
export const UPDATE_INTERNAL_ISSUE = 'update_internal_issue';
export const DELETE_INTERNAL_ISSUE = 'delete_internal_issue';
export const SEARCH_INTERNAL_ISSUES = 'search_internal_issues';
export const FETCH_INTERNAL_ISSUES_SUMMARY = 'fetch_internal_issues_summary';
export const FETCH_INTERNAL_ISSUES_HISTORY = 'fetch_internal_issues_history';
export const FETCH_INTERNAL_ISSUE_BY_ID = 'fetch_internal_issue_by_id';
export const CHANGE_INTERNAL_ISSUE_ASSIGNMENT = 'change_internal_issue_assignment';
export const CHANGE_INTERNAL_ISSUE_STATUS = 'change_internal_issue_status';
export const CHANGE_INTERNAL_ISSUE_STATUS_V2 = 'CHANGE_INTERNAL_ISSUE_STATUS_V2';
export const INTERNAL_ISSUES_COUNT_PER_VEHICLE = 'internal_issues_count_per_vehicle';
export const CREATE_VEHICLE_MAINTENANCE_TICKET = 'CREATE_VEHICLE_MAINTENANCE_TICKET';
export const GET_ASSIGNMENT_SUMMARY = 'GET_ASSIGNMENT_SUMMARY';
export const VEHICLE_ASSIGN_LIST = 'VEHICLE_ASSIGN_LIST';
export function createInternalIssue(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}internal/issue/`, data, config);
    return {
        type: CREATE_INTERNAL_ISSUE,
        promise: request,
    };
}
export function createVehicleMaintenanceTicket(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}vehicles/maintenance/`, data, config);
    return {
        type: CREATE_VEHICLE_MAINTENANCE_TICKET,
        promise: request,
    };
}
export function updateInternalIssue(accesstoken, id, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}internal/issue/${id}`, data, config);
    return {
        type: UPDATE_INTERNAL_ISSUE,
        promise: request,
    };
}
export function deleteInternalIssue(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}internal/issue/${id}`, config);
    return {
        type: DELETE_INTERNAL_ISSUE,
        promise: request,
    };
}
export function searchInternalIssues(accesstoken, { id, groupId, vehicleId, fromDate, toDate, status, text, type, reportedById, assignedToIds, category, accountManagerUserId, notAssigned, overdue, issuePriority, tagIds, userTagIds, issueEntityType, issueEntityId, accountId, universalSearchText, subject, page = 1, size, sort, fromCreatedDate, toCreatedDate, fromLastModifiedDate, ToLastModifiedDate, createdBetweenToggle, modifiedBetweenToggle, teamName, replied, repliedWithIn24Hrs, repliedAfter24hrs, isTransporter, plantCode, }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
            groupId,
            vehicleId,
            ...(!(createdBetweenToggle || modifiedBetweenToggle) && { fromDate, toDate }),
            status,
            text,
            universalSearchText,
            subject,
            type,
            reportedById,
            assignedToIds,
            category,
            accountManagerUserId,
            notAssigned,
            overdue,
            issuePriority,
            tagIds,
            userTagIds,
            issueEntityType,
            issueEntityId,
            accountId,
            page: page - 1,
            size,
            sort,
            ...(createdBetweenToggle && { fromCreatedDate, toCreatedDate }),
            ...(modifiedBetweenToggle && { fromLastModifiedDate, ToLastModifiedDate }),
            teamName,
            replied,
            repliedWithIn24Hrs,
            repliedAfter24hrs,
            isTransporter,
            vehicleLocation: plantCode,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/search`, config);
    return {
        type: SEARCH_INTERNAL_ISSUES,
        promise: request,
    };
}
export function fetchInternalIssuesSummary(accesstoken, { id, groupId, vehicleId, fromDate, toDate, status, text, universalSearchText, subject, type, reportedById, assignedToIds, category, accountManagerUserId, notAssigned, overdue, issuePriority, tagIds, userTagIds, issueEntityType, issueEntityId, accountId, page = 1, size, sort, fromCreatedDate, toCreatedDate, fromLastModifiedDate, ToLastModifiedDate, createdBetweenToggle, modifiedBetweenToggle, teamName, replied, repliedWithIn24Hrs, repliedAfter24hrs, isTransporter, plantCode, }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
            groupId,
            vehicleId,
            ...(!(createdBetweenToggle || modifiedBetweenToggle) && { fromDate, toDate }),
            status,
            text,
            universalSearchText,
            subject,
            type,
            reportedById,
            assignedToIds,
            notAssigned,
            overdue,
            issuePriority,
            tagIds,
            userTagIds,
            issueEntityType,
            issueEntityId,
            category,
            accountManagerUserId,
            accountId,
            page: page - 1,
            size,
            sort,
            ...(createdBetweenToggle && { fromCreatedDate, toCreatedDate }),
            ...(modifiedBetweenToggle && { fromLastModifiedDate, ToLastModifiedDate }),
            teamName,
            replied,
            repliedWithIn24Hrs,
            repliedAfter24hrs,
            isTransporter,
            vehicleLocation: plantCode,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/summary`, config);
    return {
        type: FETCH_INTERNAL_ISSUES_SUMMARY,
        promise: request,
    };
}
export function fetchInternalIssueHistory(accesstoken, vid) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/ticket-history?vehicleIds=${vid}`, config);
    return {
        type: FETCH_INTERNAL_ISSUES_HISTORY,
        promise: request,
    };
}
export function fetchInternalIssueById(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/${id}`, config);
    return {
        type: FETCH_INTERNAL_ISSUE_BY_ID,
        promise: request,
    };
}
export function fetchInternalIssuesCountPerVehicle(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/count/by/vehicle`, config);
    return {
        type: INTERNAL_ISSUES_COUNT_PER_VEHICLE,
        promise: request,
    };
}
export function changeInternalIssueAssignment(accesstoken, id, assignedToId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const data = {
        id,
        assignedToId,
    };
    const request = axios.put(`${ROOT_API_URL}internal/issue/change-assignment`, data, config);
    return {
        type: CHANGE_INTERNAL_ISSUE_ASSIGNMENT,
        promise: request,
    };
}
export function changeInternalIssueStatus(accesstoken, id, issueStatus, comment) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const data = {
        id,
        issueStatus,
        comment,
    };
    const request = axios.put(`${ROOT_API_URL}internal/issue/change-status`, data, config);
    return {
        type: CHANGE_INTERNAL_ISSUE_STATUS,
        promise: request,
    };
}
export function changeInternalIssueStatusV2(accesstoken, params, body) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const data = {
        ...body,
    };
    const request = axios.put(`${ROOT_API_URL}internal/issue/change-status`, data, config);
    return {
        type: CHANGE_INTERNAL_ISSUE_STATUS_V2,
        promise: request,
    };
}
export function fetchVehicleAssignedList(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/vehicle`, config);
    return {
        type: VEHICLE_ASSIGN_LIST,
        promise: request,
    };
}
export function getAssignmentSummary(accesstoken, issueId) {
    if (!issueId) {
        return {
            type: GET_ASSIGNMENT_SUMMARY,
            promise: Promise.reject(),
        };
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            issueId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}internal/issue/assignment-summary`, config);
    return {
        type: GET_ASSIGNMENT_SUMMARY,
        promise: request,
    };
}
