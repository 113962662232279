/**
 * Created by udbhav on 18/06/18.
 */

import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_API_URL_V2 } from '../../constant';
import { isEmpty, set } from 'lodash';

export const CREATE_ISSUE_V2 = 'create_issue_v2';
export const UPDATE_ISSUE_V2 = 'update_issue_v2';
export const CHANGE_ISSUE_ASSIGNMENT = 'change_issue_assignment';
export const CHANGE_ISSUE_STATUS = 'change_issue_status';
export const FETCH_ISSUES_V2 = 'fetch_issues_v2';
export const FETCH_ISSUE_BY_ID = 'fetch_issue_by_id';
export const DELETE_ISSUE = 'delete_issue';
export const FETCH_ISSUES_SUMMARY_V2 = 'fetch_issues_summary_v2';
export const FETCH_ISSUES_SUMMARY_BACKGROUND_V2 = 'fetch_issues_summary_background_v2';
export const FETCH_ISSUE_BY_SUB_TYPE = 'fetch_issue_by_sub_type';
export const FETCH_ISSUE_REPORT = 'FETCH_ISSUE_REPORT';
export const FETCH_ISSUES_COUNT = 'fetch_issues_count';
export const CHANGE_ISSUE_STATUS_BULK = 'change_issue_status_bulk';

export function createIssueV2(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}issues/`.replace('v1', 'v2'), values, config);

    return {
        type: CREATE_ISSUE_V2,
        promise: request,
    };
}

export function updateIssueV2(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}issues/${id}`.replace('v1', 'v2'), values, config);

    return {
        type: UPDATE_ISSUE_V2,
        promise: request,
    };
}

export function changeIssueAssignment(accesstoken, id, assignedToId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const values = {
        id,
        assignedToId,
    };
    const request = axios.put(`${ROOT_API_URL}issues/change-assignment`.replace('v1', 'v2'), values, config);

    return {
        type: CHANGE_ISSUE_ASSIGNMENT,
        promise: request,
    };
}

export function changeIssueStatus(accesstoken, id, issueStatus, comment, issueImages, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const values = {
        id,
        issueStatus,
        comment,
    };
    !isEmpty(issueImages) && set(values, 'issueImages', issueImages);
    const request = axios.put(`${ROOT_API_URL}issues/change-status`.replace('v1', 'v2'), values, config);

    return {
        type: CHANGE_ISSUE_STATUS,
        promise: request,
    };
}

export function changeBulkIssueStatus(params) {
    const config = {};
    const request = axios.put(`${ROOT_API_URL}issues/bulk/change-status`.replace('v1', 'v2'), params, config);
    return {
        type: CHANGE_ISSUE_STATUS_BULK,
        promise: request,
    };
}

export function searchIssues(
    accesstoken,
    {
        groupId,
        vehicleId,
        fromDate,
        toDate,
        status,
        text,
        type,
        subType,
        reportedById,
        assignedToId,
        notAssigned,
        overdue,
        issuePriority,
        tagIds,
        userTagIds,
        issueEntityType,
        issueEntityId,
        page = 1,
        size,
        sort,
        category = null,
        accountId,
        transporterId = null,
    }
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            vehicleId,
            fromDate,
            toDate,
            status,
            text,
            type,
            subType,
            reportedById,
            assignedToId,
            notAssigned,
            overdue,
            issuePriority,
            tagIds,
            userTagIds,
            issueEntityType,
            issueEntityId,
            page: page - 1,
            size,
            sort,
            category,
            accountId,
            transporterId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}issues/search`.replace('v1', 'v2'), config);

    return {
        type: FETCH_ISSUES_V2,
        promise: request,
    };
}

export function fetchIssueById(accesstoken, id, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}issues/${id}`.replace('v1', 'v2'), config);

    return {
        type: FETCH_ISSUE_BY_ID,
        promise: request,
    };
}

export function deleteIssue(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}issues/${id}`.replace('v1', 'v2'), config);

    return {
        type: DELETE_ISSUE,
        promise: request,
    };
}

export function fetchIssuesSummaryV2(
    accesstoken,
    {
        groupId,
        vehicleId,
        fromDate,
        toDate,
        status,
        text,
        type,
        subType,
        reportedById,
        assignedToId,
        notAssigned,
        overdue,
        issuePriority,
        tagIds,
        userTagIds,
        issueEntityType,
        issueEntityId,
        category = null,
        accountId,
        transporterId = null,
    }
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            vehicleId,
            fromDate,
            toDate,
            status,
            text,
            type,
            subType,
            reportedById,
            assignedToId,
            notAssigned,
            overdue,
            issuePriority,
            tagIds,
            userTagIds,
            issueEntityType,
            issueEntityId,
            category,
            accountId,
            transporterId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}issues/summary`.replace('v1', 'v2'), config);

    return {
        type: FETCH_ISSUES_SUMMARY_V2,
        promise: request,
    };
}

export function fetchIssuesSummaryBackgroundV2(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}issues/summary/count`.replace('v1', 'v2'), config);

    return {
        type: FETCH_ISSUES_SUMMARY_BACKGROUND_V2,
        promise: request,
    };
}

export function fetchIssueByAlertType(accesstoken, type, subTypes) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            subTypes: subTypes ? subTypes.join(',') : '',
            type,
        }),
    };

    const request = axios.get(`${ROOT_API_URL_V2}issues/count`, config);

    return {
        type: FETCH_ISSUE_BY_SUB_TYPE,
        promise: request,
    };
}

export function fetchIssueReport(params) {
    const config = {
        params: parseQueryParams(params),
    };

    const request = axios.get(`${ROOT_API_URL}reports/issue`, config);

    return {
        type: FETCH_ISSUE_REPORT,
        promise: request,
    };
}

export function fetchIssuesCount({
    fromDate,
    toDate,
    status,
    category = null,
    groupId,
    vehicleId,
    text,
    type,
    subType,
    reportedById,
    assignedToId,
    notAssigned,
    overdue,
    issuePriority,
    tagIds,
    userTagIds,
    issueEntityType,
    issueEntityId,
    accountId,
    transporterId = null,
}) {
    const config = {
        params: parseQueryParams({
            fromDate,
            toDate,
            status,
            category,
            groupId,
            vehicleId,
            text,
            type,
            subType,
            reportedById,
            assignedToId,
            notAssigned,
            overdue,
            issuePriority,
            tagIds,
            userTagIds,
            issueEntityType,
            issueEntityId,
            accountId,
            transporterId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}issues/listing`.replace('v1', 'v2'), config);

    return {
        type: FETCH_ISSUES_COUNT,
        promise: request,
    };
}
