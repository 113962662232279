import axios from 'axios';
import { ROOT_API } from '../../../constant';
export const CREATE_EXPENSE = 'CREATE_EXPENSE';
export const FETCH_EXPENSES = 'FETCH_EXPENSES';
export const FETCH_EXPENSE = 'FETCH_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export function createExpense(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API}v1/finance/expense-type`, data, config);
    return {
        type: CREATE_EXPENSE,
        promise: request,
    };
}
export function updateExpense(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API}v1/finance/expense-type`, data, config);
    return {
        type: UPDATE_EXPENSE,
        promise: request,
    };
}
export function fetchExpenses(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API}v1/finance/expense-type`, config);
    return {
        type: FETCH_EXPENSES,
        promise: request,
    };
}
export function deleteExpense(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API}v1/finance/expense-type/${id}`, config);
    return {
        type: DELETE_EXPENSE,
        promise: request,
    };
}
export function fetchExpense(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API}v1/finance/expense-type/${id}`, config);
    return {
        type: FETCH_EXPENSE,
        promise: request,
    };
}
