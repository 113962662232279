import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FxMultiFileDropzoneComponent from 'sharedV2/fileUpload/FxMultiFileDropzoneComponent';
import { get } from 'lodash';
const FxMultiFileDropzoneFormikComponent = (props) => {
    const { onChange, label, field, form, isRequired, ...restProps } = props;
    const currentImageTagToUse = props.currentImageTagToUse || get(form.values, props.currentImageTagToUseKey || '', '');
    return (_jsxs("div", { children: [label && (_jsxs("div", { children: [_jsx("label", { children: label }), isRequired && _jsx("abbr", { className: "text-danger", children: "*" })] })), _jsx(FxMultiFileDropzoneComponent, { ...restProps, fileList: field.value, fileErrorCallback: (fileList) => { }, fileSuccessCallback: (fileList) => {
                    form.setFieldValue(field.name, fileList);
                }, fileDeleteCallback: (fileList, deletedFile) => {
                    form.setFieldValue(field.name, fileList);
                }, currentImageTagToUse: currentImageTagToUse })] }));
};
export default FxMultiFileDropzoneFormikComponent;
