import { get, includes } from 'lodash';

export const IOT_EMAILS = [
    'tabish@fleetx.io',
    'himanshu.gupta@fleetx.io',
    'amit.kumar1@fleetx.io',
    'lokesh.kumar@fleetx.io',
    'akash.aggarwal@fleetx.io',
    'saket.verma@fleetx.io',
    'archit.vyas@fleetx.io',
    'aman.thapliyal@fleetx.io',
    'charan.bhavisetti@fleetx.io',
    'satish@fleetx.io',
    'lokesh@fleetx.io',
];

export const showIot = () => {
    const email = get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId');
    return includes(IOT_EMAILS, email);
};
