import { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Progress, Col } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchImagekitSHAV2, getServerTime } from '../../actions/dashboard/utils_action';
import { IMAGEKIT_PUBLIC_API_KEY, getImageKitLink, image, isViewportMDOrAbove } from '../../constant';
import { getMomentTime, MINUTES_1 } from '../../utils/date_utils';
import LightBoxUtils from '../../utils/light_box_utils';
import { FxCircularProgressBar, FxThumbNailList, FxFlex } from '../../shared';
import { get, round } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getValidUploadFileTypes, validateFileBeforeUpload } from 'utils/fileUploadutils';

class MultiFileDropzoneComponentThumbnail extends Component {
    source = null;

    constructor(props) {
        super(props);
        this.state = {
            acceptedFile: null,
            progress: 0,
            isOpen: false,
            uploadedUrl: props.uploadedUrl,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uploadedUrl !== this.props.uploadedUrl) {
            let uploadedUrl =
                this.props.uploadedUrl && this.props.uploadedUrl.match(/^http/)
                    ? this.props.uploadedUrl.split('fx')[1]
                    : this.props.uploadedUrl;
            this.setState({ uploadedUrl });
        }
    }

    onCloseRequest = () => {
        this.setState({
            isOpen: false,
        });
    };
    handleOnClick = () => {
        this.setState({
            isOpen: true,
        });
    };

    renderImageThumbnail(imageUrl, style) {
        return (
            <div onClick={this.handleOnClick} className="d-inline-block mr-2 cursor-pointer">
                <div className="d-inline-block m-0 thumbnail">
                    <img style={style} src={imageUrl} className="img-thumbnail" alt="PO/Link" />
                </div>
            </div>
        );
    }

    onDrop = async (acceptedFiles, rejectedFiles) => {
        const { currentIndex } = this.props;

        const acceptedFile = acceptedFiles[0];

        if (!acceptedFile) {
            return;
        }
        if (this.source) {
            this.source.cancel('');
        }

        const fileValidationObj = validateFileBeforeUpload(acceptedFile);
        if (get(fileValidationObj, 'error')) {
            alert(get(fileValidationObj, 'errorMsg'));
            return;
        }

        var CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        const config = {
            onUploadProgress: (progressEvent) =>
                this.setState({ progress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }),
            cancelToken: this.source.token,
        };

        this.setState({
            acceptedFile: acceptedFile,
        });

        let timeStamp = Math.floor(getMomentTime().valueOf());

        try {
            const result = await this.props.getServerTime(this.props.accesstoken);
            timeStamp = get(result, 'payload.data.timestamp', timeStamp);
        } catch (e) {}

        const token = uuidv4();
        const expire = round((timeStamp + MINUTES_1 * 59) / 1000, 0);
        this.props.fetchImagekitSHAV2(this.props.accesstoken, acceptedFile.name, token, expire).then((result) => {
            if (!result.error) {
                const reader = new FileReader();
                const signature = get(result, 'payload.data.signature');
                const expireAPI = get(result, 'payload.data.expire');

                reader.onload = (event) => {
                    const data = new FormData();
                    data.append('file', event.target.result.substring(event.target.result.indexOf(',') + 1));
                    data.append('fileName', acceptedFile.name);
                    data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                    data.append('signature', signature);
                    data.append('token', token);
                    data.append('expire', expireAPI);
                    data.append('useUniqueFilename', true);
                    data.append('folder', `clients/${this.props.loggedInUser.accountId}/${this.props.folderPath}`);
                    axios
                        .post('https://upload.imagekit.io/api/v1/files/upload', data, config)
                        .then(
                            function (res) {
                                this.setState({ uploadedUrl: res.data.filePath });
                                this.props.fileSuccessCallback(res.data.filePath, currentIndex);
                            }.bind(this)
                        )
                        .catch(
                            function (err) {
                                this.props.fileSuccessCallback('', currentIndex);
                                //this.fileErrorCallback(err.response.data.message);
                            }.bind(this)
                        );
                };
                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');

                reader.readAsDataURL(acceptedFile);
            }
        });
    };

    deleteImage = (index, remove) => {
        const { currentIndex } = this.props;
        if (this.source) {
            this.source.cancel('');
        }

        this.setState({
            acceptedFile: null,
            uploadedUrl: null,
        });

        this.props.fileDeleteCallback(index, remove);
    };

    componentWillUnmount() {
        this.source = null;
    }

    render() {
        const { name, accept, message, lgThumbnailView, showDropZone, showAddMore, currentIndex, remove } = this.props;
        const { acceptedFile, uploadedUrl, progress } = this.state;
        const uploadedImageLink = getImageKitLink(uploadedUrl);
        return (
            <FxFlex direction={isViewportMDOrAbove() ? 'row' : 'column'} className="mb-4" style={{ flexWrap: 'wrap' }}>
                {this.props.showImageThumbnail && this.state.isOpen && (
                    <LightBoxUtils images={[uploadedImageLink]} onCloseRequest={this.onCloseRequest} />
                )}

                {!acceptedFile && showDropZone && this.props.imageList[currentIndex] && (
                    <Dropzone
                        name={name}
                        onDrop={this.onDrop}
                        accept={getValidUploadFileTypes(accept)}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps({ className: 'dropzone', style: this.props.style })}>
                                    <input {...getInputProps()} />
                                    <p>{message}</p>
                                    <p>Or</p>
                                    <p
                                        className="p-2"
                                        style={{ 'borderRadius': '2.5rem', 'backgroundColor': '#959595' }}
                                    >
                                        Select Photos
                                    </p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
                {get(this.props, 'imageList.length') > 0 && (
                    <FxThumbNailList
                        imageList={this.props.imageList}
                        thumbStyle={{
                            height: '10rem',
                            width: '10rem',
                        }}
                        deleteImage={this.deleteImage}
                        remove={remove}
                    ></FxThumbNailList>
                )}
                {!uploadedUrl && acceptedFile && (
                    <div className="ml-2" style={{ ...this.props.thumbStyle }}>
                        <Col md={12} className="p-1 my-4 img-thumbnail">
                            <div
                                className="text-center"
                                style={{
                                    width: '10rem',
                                    height: '10rem',
                                    minWidth: '10rem',
                                    minHeight: '10rem',
                                    maxWidth: '10rem',
                                    maxHeight: '10rem',
                                    lineHeight: '10rem',
                                }}
                            >
                                {progress !== 0 && progress < 100 && (
                                    <FxCircularProgressBar
                                        sqSize={90}
                                        percentage={50}
                                        strokeWidth={8}
                                    ></FxCircularProgressBar>
                                )}
                                {progress === 0 && (
                                    <img
                                        style={{ width: '90px', height: '90px', zIndex: 2 }}
                                        src={image('/public/img/icons/image-upload-placeholder.svg')}
                                    ></img>
                                )}
                            </div>
                            <div
                                className="pos-abs"
                                style={{ right: '-1rem', top: '-0.5rem' }}
                                data-id={uploadedUrl}
                                onClick={this.deleteImage}
                            ></div>
                        </Col>
                        <a target="_blank" href={uploadedImageLink}>
                            <Col md={12}>
                                <div
                                    className="ml-1"
                                    style={{
                                        fontSize: '0.7rem',
                                        fontWeight: 'bold',
                                        color: '#303030',
                                    }}
                                >
                                    {acceptedFile.name}
                                </div>
                            </Col>
                            <Col md={12}>
                                <div
                                    className="ml-1"
                                    style={{
                                        fontSize: '0.6rem',
                                        fontWeight: '500',
                                        color: '#a8a8a8',
                                    }}
                                >
                                    Uploading
                                </div>
                            </Col>
                        </a>
                    </div>
                )}

                {/* {uploadedUrl && acceptedFile && (
                        <Row className="ml-2" style={{ ...this.props.thumbStyle }}>
                            {this.props.showImageThumbnail && (
                                <Col md={12} className="p-0 m-0">
                                    {this.renderImageThumbnail(uploadedImageLink, {
                                        width: '10rem',
                                        height: '10rem',
                                        minWidth: '10rem',
                                        minHeight: '10rem',
                                        maxWidth: '10rem',
                                        maxHeight: '10rem',
                                    })}
                                    <div
                                        className="pos-abs"
                                        style={{ right: '-1rem', top: '-0.5rem' }}
                                        data-id={uploadedUrl}
                                        onClick={this.deleteImage.bind(this)}
                                    >
                                        <i className="hover fa fa-times-circle" />
                                    </div>
                                </Col>
                            )}
                            <a target="_blank" href={uploadedImageLink}>
                                <Col md={12}>
                                    <div
                                        className="ml-1"
                                        style={{
                                            fontSize: '0.7rem',
                                            fontWeight: 'bold',
                                            color: '#303030',
                                        }}
                                    >
                                        {acceptedFile.name}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div
                                        className="ml-1"
                                        style={{
                                            fontSize: '0.6rem',
                                            fontWeight: '500',
                                            color: '#a8a8a8',
                                        }}
                                    >
                                        Uploaded
                                    </div>
                                </Col>
                            </a>
                        </Row>
                    )} */}
                {showAddMore && this.props.imageList[currentIndex] && progress === 0 && (
                    <Dropzone name={name} onDrop={this.onDrop} accept={accept} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="p-1 mt-2">
                                <div {...getRootProps({ className: 'dropzone', style: this.props.addMoreStyle })}>
                                    <input {...getInputProps()} />
                                    <p className="text-center align-self-center mt-auto">
                                        <i className="fa fa-2x fa-plus" />
                                        <br />
                                        <span>{'Add More'}</span>
                                        <br />
                                        <span>{'Documents'}</span>
                                    </p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
            </FxFlex>
        );
    }
}

MultiFileDropzoneComponentThumbnail.propTypes = {
    showImageThumbnail: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        accesstoken: state.login.data.access_token,
        loggedInUser: state.settings.loggedInUser,
    };
}

export default connect(mapStateToProps, { fetchImagekitSHAV2, getServerTime })(MultiFileDropzoneComponentThumbnail);
