import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forgotPassword, resetPassword } from 'actions/login/login_action';
import { message } from 'antd';
import ForgotPasswordForm from 'components/login/ForgotPasswordForm';
import { handleError, isValidEmail, isValidMobileNo } from 'constant';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { assign, get, trim, toString } from 'lodash';
import { FxButton } from 'sharedV2/index';
import { FxArrowLeftOutlined } from 'sharedV2/FxIcons';
import * as Yup from 'yup';
import { getPageTitle } from '../../../white_label_constants';
const values = {
    email: '',
    otp: '',
    newPassword: '',
};
const ForgotPasswordNew = (props) => {
    const token = useRef(null);
    const [step, setStep] = useState(1);
    const [initialValues, setInitialValues] = useState(values);
    const changeStep = (step, values) => {
        setStep(step);
        if (step === 1) {
            setInitialValues({
                ...values,
                otp: '',
                newPassword: '',
            });
        }
    };
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        ...(step === 1 && {
            email: Yup.string()
                .test('email', 'Required', (value) => {
                return !!value;
            })
                .test('email', 'Invalid email or mobile number', (value) => {
                return isValidEmail(value) || isValidMobileNo(value);
            }),
        }),
        ...(step === 2 && {
            otp: Yup.string()
                .test('otp', 'Required', (value) => {
                return !!value;
            })
                .test('otp', 'Invalid OTP', (value) => {
                return get(value, 'length', null) === 4;
            }),
            newPassword: Yup.string()
                .test('newPassword', 'Required', (value) => {
                return !!value;
            })
                .test('newPassword', 'Password must contain at least 1 uppercase character', (value) => {
                return /^(?=.*[A-Z])/i.test(toString(value));
            })
                .test('newPassword', 'Password must contain at least 1 numeric character', (value) => {
                return /^(?=.*[0-9])/i.test(toString(value));
            })
                .test('newPassword', 'Password must contain at least 1 special character', (value) => {
                return /^(?=.*[!@#\$%\^&\*])/i.test(toString(value));
            })
                .test('newPassword', 'Password must be eight characters or longer', (value) => {
                return /^(?=.{8,30})/i.test(toString(value));
            }),
        }),
    });
    const onSubmit = (values) => {
        const data = assign({}, values);
        if (step === 1) {
            let { email } = data;
            email = trim(email);
            props
                .forgotPassword({
                email,
            })
                .then((result) => {
                if (!result.error) {
                    messageApi.success('OTP sent successfully');
                    token.current = result.payload.data.data;
                    changeStep(2);
                }
                else {
                    messageApi.error(handleError(result.payload.response));
                }
            });
        }
        else {
            let { otp, newPassword } = data;
            otp = trim(otp);
            newPassword = trim(newPassword);
            props
                .resetPassword({
                otp,
                token: token.current,
                newPassword,
            })
                .then((result) => {
                if (!result.error) {
                    messageApi.success({
                        content: 'Password reset successfully',
                        duration: 2,
                        onClose: () => {
                            navigate('/users/login');
                        },
                    });
                }
                else {
                    messageApi.error(handleError(result.payload.response));
                }
            });
        }
    };
    return (_jsxs(_Fragment, { children: [contextHolder, _jsx(Helmet, { title: getPageTitle('Forgot Password') }), _jsx("div", { className: "container", children: _jsx("div", { className: "w-100", children: _jsx(Formik, { validationSchema: validationSchema, initialValues: initialValues, onSubmit: onSubmit, enableReinitialize: true, children: (formikProps) => {
                            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "py-3 h-10", children: step === 1 ? (_jsx(Link, { to: "/users/login", children: _jsx(FxButton, { ghost: true, size: "large", type: "primary", icon: _jsx(FxArrowLeftOutlined, {}), children: "Back to Login" }) })) : (_jsx(FxButton, { ghost: true, size: "large", type: "primary", icon: _jsx(FxArrowLeftOutlined, {}), onClick: () => {
                                                changeStep(1, formikProps.values);
                                            }, children: "Go Back" })) }), _jsxs("div", { className: "d-flex flex-col justify-content-center align-items-center h-90 w-100", children: [_jsx(ForgotPasswordForm, { formikProps: formikProps, step: step }), ";"] })] }));
                        } }) }) }), _jsx("img", { src: "/public/img/forgot_bg.svg", alt: "", style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                } })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        forgotPassword,
        resetPassword,
    }, dispatch);
}
function mapStateToProps() {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordNew);
