import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce, find, get, includes, round } from 'lodash';
import { Button } from 'reactstrap';
import { Field } from 'formik';
import { FxSelectField as Select, FxTextFieldStackedLabel } from 'shared/index';
import { TAX_TYPES_V2, handleError } from 'constant';
import { mappedObjectToOptions, mappedParts } from 'components/utils/mapping_utils';
import { UNIT_TYPE_TO_UNITS_MAP, UNIT_TYPES, UNIT_TYPES_LABELS } from 'utils/rateChartsUtils';
import { getCompletePartListMaster } from 'actions/dashboard/part_stock_inventory/parts_entry_action';
import { isAmountBasedTaxInServiceEntry } from './service_entry_account_utils';
const ServiceParts = (props) => {
    const { index, innerIndex, innerArrayHelpers, formikProps, isEdit, setIsCreatePartModalOpen, showUnitPartCostField, partOptions, } = props;
    const { values, setFieldValue } = formikProps;
    const [disableTaxes, setDisableTaxes] = useState({});
    // const [partOptions, setPartOptions] = useState<any>([]);
    const [fetchingParts, setFetchingParts] = useState([]);
    const [searchWithName, setSearchWithName] = useState(true);
    const [partsList, setPartsList] = useState([]);
    const isAmountBasedTax = isAmountBasedTaxInServiceEntry();
    const mapped = {
        unitTypes: mappedObjectToOptions(UNIT_TYPES, UNIT_TYPES_LABELS),
        units: (values, index, innerIndex) => mappedObjectToOptions(get(UNIT_TYPE_TO_UNITS_MAP, get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].unitType`), {})),
    };
    const fetchPartsData = debounce(async (input, callback, index, innerIndex) => {
        setFetchingParts(true);
        const params = {
            size: 20,
        };
        let partOptions = [];
        // if (isEdit && get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].part.id`)) {
        //     const wkIdName = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].part`);
        //     partOptions.push({
        //         label: wkIdName.name,
        //         value: wkIdName.id,
        //     });
        // }
        try {
            let result = null;
            const prms = searchWithName ? { ...params, partName: input } : { ...params, codeNumber: input };
            if (input) {
                result = await props.getCompletePartListMaster(props.accesstoken, prms);
            }
            setFetchingParts(false);
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
            else {
                const partEntryList1 = get(result, 'payload.data.content', []);
                setPartsList(get(result, 'payload.data.content', []));
                partOptions = mappedParts(partEntryList1);
            }
        }
        catch (e) {
            setFetchingParts(false);
        }
        callback(partOptions);
    }, 1000);
    const onSearchWith = () => {
        setSearchWithName(!searchWithName);
    };
    const permissions = {
        shouldShowTax2Type: (tax1Type) => {
            return includes(['SGST', 'CGST'], tax1Type);
        },
    };
    const handleTaxSelect = ({ index, innerIndex, tax, part }) => {
        const foundPart = find(partsList, { id: part });
        const IGST = get(foundPart, 'gstRate.IGST', 0);
        const CGST = get(foundPart, 'gstRate.CGST', 0);
        const SGST = get(foundPart, 'gstRate.SGST', 0);
        if (tax === 'IGST') {
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, IGST);
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, 0);
            setDisableTaxes({ ...disableTaxes, [innerIndex]: { tax2: false, tax1: !!IGST } });
        }
        else if (tax === 'SGST') {
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, SGST);
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, CGST);
            setDisableTaxes({
                ...disableTaxes,
                [innerIndex]: { tax1: !!SGST, tax2: !!CGST },
            });
        }
        else if (tax === 'CGST') {
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, CGST);
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, SGST);
            setDisableTaxes({
                ...disableTaxes,
                [innerIndex]: { tax1: !!CGST, tax2: !!SGST },
            });
        }
        else {
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, 0);
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, 0);
            setDisableTaxes({ ...disableTaxes, [innerIndex]: { tax1: false, tax2: false } });
        }
    };
    const calculateTaxPercentageFromAmount = ({ taxAmount, partCost, taxType, count, discountPercent }) => {
        if (!taxAmount || !partCost || !isAmountBasedTax || !taxType)
            return;
        let finalPartCost = partCost;
        if (showUnitPartCostField) {
            finalPartCost = partCost * count;
        }
        if (discountPercent) {
            finalPartCost = finalPartCost - (finalPartCost * discountPercent) / 100;
        }
        const taxPercent = (taxAmount / finalPartCost) * 100;
        if (taxType === 'IGST' || taxType === 'VAT') {
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, taxPercent);
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, 0);
        }
        if (taxType === 'CGST' || taxType === 'SGST') {
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, round(taxPercent / 2, 2));
            setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, round(taxPercent / 2, 2));
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: "px-3 px-3 d-flex align-items-center", children: [_jsxs("h6", { className: "mb-0 whitespace-nowrap mr-2", children: ["Parts ", String.fromCharCode(65 + innerIndex)] }), _jsx("div", { style: {
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#e9ecef',
                        } }), _jsx(Button, { type: "button", color: "link", className: "ml-auto", onClick: () => {
                            innerArrayHelpers.remove(innerIndex);
                        }, children: "Remove" })] }), _jsxs("div", { className: "px-3 row py-3", children: [_jsxs("div", { className: "col-md-4", children: [_jsx(Field, { label: "Part", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].part.id`, placeholder: "Select Part", component: Select, searchWith: true, onSearchWith: onSearchWith, loadAsync: true, defaultOptions: true, callbackLoadOptions: true, promisifyOptions: fetchPartsData, remoteOptions: get(partOptions, `[${index}][${innerIndex}]`, []), value: get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].part.id`), onChange: (value) => {
                                    handleTaxSelect({
                                        index,
                                        innerIndex,
                                        tax: get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`),
                                        part: value,
                                    });
                                } }), _jsx("div", { className: "d-flex justify-content-end", style: { marginTop: '-1rem' }, children: _jsx(Button, { color: "link", className: "pr-0", onClick: () => {
                                        setIsCreatePartModalOpen(true);
                                    }, children: "Add New Part" }) })] }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Unit Type", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].unitType`, options: mapped.unitTypes, placeholder: "Select Unit Type", component: Select }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Unit", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].unit`, placeholder: "Select Part Unit", component: Select, options: mapped.units(values, index, innerIndex) }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Part Count", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].count`, placeholder: "Enter Part Count", component: FxTextFieldStackedLabel, onChange: (value) => {
                                const partCost = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].cost`);
                                const taxAmount = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].taxAmount`);
                                const taxType = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`);
                                const count = value;
                                const discountPercent = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].discountPercent`);
                                calculateTaxPercentageFromAmount({ taxAmount, partCost, taxType, count, discountPercent });
                            } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: showUnitPartCostField ? 'Part Unit Cost' : 'Part Total Cost', name: `serviceLineItems[${index}].serviceParts[${innerIndex}].cost`, placeholder: showUnitPartCostField ? 'Enter Part Unit Cost' : 'Enter Part Total Cost', component: FxTextFieldStackedLabel, onChange: (v) => {
                                const partCost = v;
                                const taxAmount = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].taxAmount`);
                                const taxType = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`);
                                const count = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].count`);
                                const discountPercent = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].discountPercent`);
                                calculateTaxPercentageFromAmount({ taxAmount, partCost, taxType, count, discountPercent });
                            } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Discount Percent", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].discountPercent`, placeholder: "Enter Discount Percent", component: FxTextFieldStackedLabel, onChange: (v) => {
                                const partCost = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].cost`);
                                const taxAmount = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].taxAmount`);
                                const taxType = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`);
                                const count = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].count`);
                                const discountPercent = v;
                                calculateTaxPercentageFromAmount({ taxAmount, partCost, taxType, count, discountPercent });
                            } }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Tax Type", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`, placeholder: "Select Tax 1 Type", component: Select, options: TAX_TYPES_V2, onChange: (value) => {
                                if (value === 'SGST' || value === 'CGST') {
                                    setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Type`, value === 'SGST' ? 'CGST' : 'SGST');
                                }
                                else {
                                    setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Type`, '');
                                    setFieldValue(`serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, 0);
                                }
                                if (isAmountBasedTax) {
                                    const partCost = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].cost`);
                                    const taxAmount = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].taxAmount`);
                                    const taxType = value;
                                    const count = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].count`);
                                    const discountPercent = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].discountPercent`);
                                    calculateTaxPercentageFromAmount({
                                        partCost,
                                        taxAmount,
                                        taxType,
                                        count,
                                        discountPercent,
                                    });
                                }
                                else {
                                    handleTaxSelect({
                                        index,
                                        innerIndex,
                                        tax: value,
                                        part: get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].part.id`),
                                    });
                                }
                            } }) }), !isAmountBasedTax && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Tax Percent", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Percent`, placeholder: "Enter Tax 1 Percent", component: FxTextFieldStackedLabel, disabled: disableTaxes[innerIndex]?.tax1 }) })), isAmountBasedTax && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Tax Amount", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].taxAmount`, placeholder: "Enter Tax Amount", component: FxTextFieldStackedLabel, type: "number", onChange: (v) => {
                                const partCost = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].cost`);
                                const taxAmount = v;
                                const taxType = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`);
                                const count = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].count`);
                                const discountPercent = get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].discountPercent`);
                                calculateTaxPercentageFromAmount({
                                    taxAmount,
                                    partCost,
                                    taxType,
                                    count,
                                    discountPercent,
                                });
                            } }) })), permissions.shouldShowTax2Type(get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`)) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Tax 2 Type", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Type`, placeholder: "Select Tax 2 Type", component: Select, options: TAX_TYPES_V2, disabled: true }) }), !isAmountBasedTax && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { label: "Parts Tax 2 Percent", name: `serviceLineItems[${index}].serviceParts[${innerIndex}].tax2Percent`, placeholder: "Enter Tax 2 Percent", component: FxTextFieldStackedLabel, disabled: disableTaxes[innerIndex]?.tax2 }) }))] })), permissions.shouldShowTax2Type(get(values, `serviceLineItems[${index}].serviceParts[${innerIndex}].tax1Type`)) ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "col" }), _jsx("div", { className: "col" })] })) : (_jsx("div", { className: "col" }))] })] }, innerIndex));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCompletePartListMaster }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        partsList: get(state, 'partsInventory.partList', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceParts);
