import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from 'constant';
export const FETCH_VEHICLE_LOANS_EMI = 'FETCH_VEHICLE_LOANS_EMI';
export function fetchVehicleLoansEmi(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}vehicle-emi/listing`, config);
    return {
        type: FETCH_VEHICLE_LOANS_EMI,
        promise: request,
    };
}
export const FETCH_VEHICLE_LOANS_PAYMENT = 'FETCH_VEHICLE_LOANS_PAYMENT';
export function fetchVehicleLoansPayment(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}emi-settlement/listing`, config);
    return {
        type: FETCH_VEHICLE_LOANS_PAYMENT,
        promise: request,
    };
}
