import { jsxs as _jsxs } from "react/jsx-runtime";
import { get, groupBy, isEmpty, map, orderBy } from 'lodash';
import { TRANSACTION_TYPES_OBJECT } from '../constant';
export function getPreparedTransactionExpenseSummary(expenseTypeDTOS) {
    if (isEmpty(expenseTypeDTOS)) {
        return [];
    }
    const groupedByExpenseType = groupBy(expenseTypeDTOS, 'expenseName');
    return orderBy(map(groupedByExpenseType, (v) => {
        return {
            ...v[0],
            ...v[1],
        };
    }), 'expenseName');
}
export const TRANSACTION_SUMMARY_TAB = {
    SUMMARY: 'summary',
    EXPENSES: 'expenses',
};
export const TRANSACTIONS_TABS = {
    FASTAG: 'fastag',
    HAPPAY: 'happay',
    BPCL: 'bpcl',
    TRANSACTION: 'transaction',
};
export const isAccountIdGreaterThan10K = () => {
    return 10_000 < get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
};
export const showTransactionType = (transactionType) => {
    const showSubLabels = isAccountIdGreaterThan10K();
    return (_jsxs("div", { children: [' ', transactionType === 'CR'
                ? TRANSACTION_TYPES_OBJECT.CR + (showSubLabels ? ' (Expenses)' : '')
                : TRANSACTION_TYPES_OBJECT.DR + (showSubLabels ? ' (Advance)' : '')] }));
};
export const getBudgetedFuel = (job) => {
    const jobKm = get(job, 'scheduledDistance', get(job, 'route.distance', 0));
    const jobMileage = get(job, 'jobBudget.jobMileage', 0);
    const fuel = jobKm * jobMileage;
    return { fuel, jobKm, jobMileage };
};
