import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forEach, get, includes, set, trim } from 'lodash';
import { DASHBOARD_LANDING_PATH, getQueryStringParams, getWhiteLabelLogo, handleError, isEnvLive, isValidEmail, redirectToFreshdesk, ROLE_MAP, startUsingApiProxyUrl, USE_API_PROXY_KEY, } from 'constant';
import { Field, Form, Formik } from 'formik';
import { fetchLogin, fetchLoginByOtp, generateLoginOtp, validateUserAndGenerateFreshdeskSSOHash, } from 'actions/login/login_action';
import { isSafeRedirectUrl } from 'utils/http_utils';
import storage from 'utils/storage_utils';
import { FxButton, FxDivider, FxRadio, FxRadioGroup, FxSpin, FxTextFieldStackedLabelV2, FxTypography, } from '../../sharedV2';
import { FxLoadingIcon } from '../../sharedV2/FxIcons';
import './login.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getWhiteLabelPrettyName } from '../../../white_label_constants';
import { useToken } from '../../theme';
import { LOGIN_WITH } from 'utils/session_utils';
import { SECOND_10 } from 'utils/date_utils';
import { isControlRoomDefaultDirect, isCustomOverviewEnabled } from 'utils/account_utils';
const setTimoutIds = [];
let otpAvailableTimeout = null;
const LoginComponent = (props) => {
    const customLoginPaths = ['/users/holisollogin', '/users/marutilogin'];
    const logoImages = {
        fleetxLogo: '/public/img/logos/fleetx/fleetx-logo-short.svg?v=2',
        fleetxLogoFull: '/public/img/logos/fleetx/fleetx-logo-final-full.svg',
        marutiLogo: '/public/img/logos/maruti/maruti_logo.jpg?v=1',
        holisolLogo: '/public/img/logos/holisol/holisol_full.png',
    };
    const location = useLocation();
    const navigate = useNavigate();
    const { search, pathname } = location;
    const { login } = props;
    const [mounted, setMounted] = useState(false);
    const [showTransporterLogin, setShowTransporterLogin] = useState(false);
    const wallConfig = storage.getItem('wallConfig');
    const [mobileView, setMobileView] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [loginWith, setLoginWith] = useState(LOGIN_WITH.PASSWORD);
    const [sessionId, setSessionId] = useState(null);
    const [generatingOtp, setGeneratingOtp] = useState(false);
    const [otpSentMessage, setOtpSentMessage] = useState('');
    const [otpResendAvailableInSecs, setOtpResendAvailableInSecs] = useState(0);
    const isWithOtp = loginWith === LOGIN_WITH.OTP;
    const isOtpSent = !!sessionId;
    useEffect(() => {
        return () => {
            forEach(setTimoutIds, (toi) => {
                clearTimeout(toi);
            });
            clearTimeout(otpAvailableTimeout);
        };
    }, []);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setMobileView(mediaQuery.matches);
        const handleMediaQueryChange = (event) => {
            setMobileView(event.matches);
        };
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    const onChangeEnv = (value) => {
        storage.setItem('wallConfig', value);
        window.location.reload();
    };
    function getAccessToken() {
        return props.login.switchedUserOriginalAccessToken
            ? props.login.switchedUserOriginalAccessToken
            : props.login.data.access_token;
    }
    function redirectToFreshdeskHandler(accesstoken) {
        props
            .validateUserAndGenerateFreshdeskSSOHash(accesstoken)
            .then((result) => {
            redirectToFreshdesk(get(result, 'payload.data', {}));
        })
            .catch(() => {
            // just a fallback in case something crazy happens at node server so freshdesk will open normal login
            redirectToFreshdesk({});
        });
    }
    function onSubmit(values) {
        setLoginError('');
        if (showTransporterLogin) {
            set(values, 'login_type', 'transporter');
        }
        const p = isWithOtp ? props.fetchLoginByOtp({ ...values, sessionId }) : props.fetchLogin(values);
        return p
            .then((result) => {
            if (!result.error) {
                const parsed = getQueryStringParams();
                const data = result.payload.data;
                if (get(data, 'role') === ROLE_MAP.ROLE_TRANSPORTER.name) {
                    return navigate('/dashboard/accounts');
                }
                if (get(data, 'role') === ROLE_MAP.ROLE_CUSTOMER_EPOD.name) {
                    return navigate('/dashboard/customerswitch');
                }
                if (isCustomOverviewEnabled(get(data, 'accountId'))) {
                    return navigate('/dashboard/overview');
                }
                if (isControlRoomDefaultDirect(get(data, 'accountId'))) {
                    return navigate('/dashboard/controlroom');
                }
                if (!data.modules || data.modules.length === 0) {
                    throw new Error('Please check your login details!');
                }
                if (search === '?host_url=fleetx.freshdesk.com' && !includes(customLoginPaths, pathname)) {
                    // redirect to freshdesk
                    redirectToFreshdeskHandler(data.access_token);
                }
                else {
                    if (isSafeRedirectUrl(parsed.redirect)) {
                        window.location.href = parsed.redirect;
                    }
                    else {
                        navigate(DASHBOARD_LANDING_PATH, { state: { fromLogin: true } });
                    }
                }
            }
            if (result.error) {
                const errorDescription = get(result, 'payload.response.data.error_description', '');
                let errorMsg = 'Please check your login details!';
                if (errorDescription) {
                    errorMsg = errorDescription;
                }
                if (!storage.getItem(USE_API_PROXY_KEY) &&
                    errorDescription === 'Full authentication is required to access this resource') {
                    console.error('LOGIN FAILED: RETRYING USING API PROXY');
                    startUsingApiProxyUrl();
                    if (!includes(customLoginPaths, pathname)) {
                        // this.onSubmit(values);
                    }
                    else {
                        throw new Error('Please try again!');
                    }
                }
                else {
                    throw new Error(errorMsg);
                }
            }
        })
            .catch((err) => {
            setLoginError(handleError(err));
        });
    }
    useEffect(() => {
        if (!mounted) {
            if (get(login, 'data') && !includes(customLoginPaths, pathname)) {
                if (search === '?host_url=fleetx.freshdesk.com') {
                    // redirect to freshdesk
                    redirectToFreshdeskHandler(getAccessToken());
                }
                else {
                    navigate(DASHBOARD_LANDING_PATH, { state: { fromLogin: true } });
                }
            }
        }
        setMounted(true);
    }, []);
    const { fleetxLogo, marutiLogo, holisolLogo, fleetxLogoFull } = logoImages;
    let logoImage = fleetxLogo;
    if (pathname === '/users/marutilogin') {
        logoImage = marutiLogo;
    }
    else if (pathname === '/users/holisollogin') {
        logoImage = holisolLogo;
    }
    else if (mobileView) {
        logoImage = fleetxLogoFull;
    }
    const hostName = window.location.hostname;
    const whiteLabelLogo = getWhiteLabelLogo(hostName);
    const isWhiteLabelUrl = !!whiteLabelLogo;
    // const inputRef = useRef<HTMLInputElement>(null);
    const { token } = useToken();
    const onLoginWithChange = (loginWith) => {
        setLoginWith(loginWith);
    };
    const startCountDown = () => {
        clearTimeout(otpAvailableTimeout);
        otpAvailableTimeout = setTimeout(() => {
            if (otpResendAvailableInSecs > 0) {
                setOtpResendAvailableInSecs((v) => v - 1);
                startCountDown();
            }
            else {
                setOtpResendAvailableInSecs(0);
            }
        }, 1000);
    };
    useEffect(() => {
        if (otpResendAvailableInSecs === 30) {
            startCountDown();
        }
    }, [otpResendAvailableInSecs]);
    const sendOtp = (username) => {
        if (generatingOtp) {
            return;
        }
        setGeneratingOtp(true);
        setLoginError('');
        props
            .generateLoginOtp(username)
            .then((result) => {
            if (result.error) {
                throw new Error(handleError(result.payload.response));
            }
            else {
                setGeneratingOtp(false);
                setSessionId(get(result, 'payload.data.sessionId'));
                setOtpSentMessage('OTP has been sent to registered email id or phone number.');
                setTimoutIds.push(setTimeout(() => {
                    setOtpSentMessage('');
                }, SECOND_10));
                setOtpResendAvailableInSecs(30);
            }
        })
            .catch((err) => {
            setGeneratingOtp(false);
            setLoginError(handleError(err));
            setSessionId(null);
            setOtpSentMessage('');
        });
    };
    const allowOtpRegeneration = otpResendAvailableInSecs <= 0;
    return (_jsxs("div", { className: "login-page-v2", children: [!mobileView && (_jsx("video", { src: "/public/videos/loginBg.mp4", autoPlay: true, loop: true, muted: true, className: "login-page-v2__background-video" })), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-6", children: _jsx("div", { className: "login-page-v2__left", children: !mobileView ? (_jsxs(_Fragment, { children: [_jsx("img", { alt: isWhiteLabelUrl ? undefined : 'Fleetx logo', src: isWhiteLabelUrl ? whiteLabelLogo : fleetxLogoFull, className: "mb-3", style: { maxHeight: '50px' } }), _jsx("br", {}), _jsxs(FxTypography.Text, { className: "login-page-v2__left__subtitle text-center", children: [_jsx("span", { className: "font-weight-bold", children: "An integrated platform" }), _jsx("br", {}), "to improve sustainability,", _jsx("br", {}), "effectiveness, and safety."] })] })) : (_jsx("img", { alt: isWhiteLabelUrl ? undefined : 'Fleetx logo', src: isWhiteLabelUrl ? whiteLabelLogo : logoImage, className: "login-page-v2__right__logo" })) }) }), _jsx("div", { className: "col-md-6 col-sm-12", children: _jsxs("div", { className: "login-page-v2__right", children: [_jsxs("div", { className: "login-page-v2__right__header", children: [!mobileView && (_jsx("img", { alt: isWhiteLabelUrl ? undefined : 'Fleetx logo', src: isWhiteLabelUrl ? whiteLabelLogo : logoImage, className: "login-page-v2__right__logo" })), _jsx(FxTypography.Title, { level: 4, className: "login-page-v2__right__header__name", children: showTransporterLogin ? 'Transporter Sign In' : 'Sign in' }), !showTransporterLogin && (_jsxs(FxTypography.Text, { className: "login-page-v2__right__header__text", children: ["to access ", isWhiteLabelUrl ? getWhiteLabelPrettyName() : 'fleetx', " portal"] }))] }), _jsx(Formik, { initialValues: {
                                        email: '',
                                        password: '',
                                        otp: '',
                                    }, onSubmit: onSubmit, validate: (values) => {
                                        const errors = {};
                                        if (!trim(values.email)) {
                                            errors.email = 'Required';
                                        }
                                        if (trim(values.email) && values.email.match(/@/) && !isValidEmail(values.email)) {
                                            errors.email = 'Invalid email format';
                                        }
                                        if (isWithOtp) {
                                            if (!values.otp || `${values.otp}`.length !== 4 || isNaN(+values.otp)) {
                                                errors.otp = 'Please Enter a 4 digit OTP';
                                            }
                                        }
                                        else {
                                            if (!values.password) {
                                                errors.password = 'Required';
                                            }
                                        }
                                        return errors;
                                    }, children: ({ handleSubmit, validateField, isSubmitting, setFieldValue, values, errors }) => {
                                        const { email } = values;
                                        const isEmailValid = !errors.email;
                                        return (_jsxs(Form, { className: "login-page-v2__right__form", onSubmit: handleSubmit, children: [otpSentMessage && isWithOtp && isOtpSent && (_jsx("div", { className: 'text-success my-2', children: otpSentMessage })), _jsx(Field, { name: "email", type: "text", size: "large", placeholder: "Enter your Email / Username", validate: validateField, component: FxTextFieldStackedLabelV2, autoFocus: true, label: "Email / Username" }), !isWithOtp && (_jsx(Field, { name: "password", placeholder: "Enter your password", validate: validateField, size: "large", label: "Password", type: "password", component: FxTextFieldStackedLabelV2 })), isWithOtp && isOtpSent && isEmailValid && (_jsxs("div", { children: [_jsx(Field, { name: "otp", placeholder: "Enter 4 digit otp", validate: validateField, size: "large", label: "Enter OTP", maxLength: 4, component: FxTextFieldStackedLabelV2, onChange: (v) => {
                                                                setFieldValue('otp', `${v}`.replaceAll(/[^0-9]+/g, ''));
                                                            } }), _jsx("div", { className: 'd-flex justify-content-end', children: _jsxs(FxTypography.Link, { onClick: () => {
                                                                    if (isEmailValid && allowOtpRegeneration) {
                                                                        sendOtp(email);
                                                                    }
                                                                }, style: { position: 'relative', top: '-20px' }, children: ["Resend again", ' ', otpResendAvailableInSecs > 0 && (_jsxs("div", { className: 'd-inline-block', children: ["in", ' ', _jsxs("div", { className: 'd-inline-block', style: { width: '2rem' }, children: [otpResendAvailableInSecs, "s"] })] }))] }) })] })), loginError && (_jsx("div", { className: "d-flex justify-content-center align-items-center w-100", children: _jsx(FxTypography.Text, { className: "text-danger", children: loginError }) })), (!isWithOtp || isOtpSent) && (_jsx(FxButton, { type: "primary", htmlType: "submit", className: "w-100 mb-4 mt-3", size: "large", children: isSubmitting ? (_jsx(FxSpin, { indicator: _jsx(FxLoadingIcon, { style: { color: token.colorWhite } }) })) : isWithOtp ? ('Verify and Log In') : ('Log In') })), !isOtpSent && isWithOtp && (_jsx(FxButton, { type: "primary", htmlType: "button", className: "w-100 mb-4 mt-3", size: "large", onClick: () => {
                                                        if (isEmailValid) {
                                                            sendOtp(email);
                                                        }
                                                    }, children: generatingOtp ? (_jsx(FxSpin, { indicator: _jsx(FxLoadingIcon, { style: { color: token.colorWhite } }) })) : ('Send OTP') })), _jsxs(FxButton, { type: "primary", htmlType: "button", className: "w-100 mb-4", size: "large", ghost: true, onClick: () => {
                                                        onLoginWithChange(isWithOtp ? LOGIN_WITH.PASSWORD : LOGIN_WITH.OTP);
                                                    }, children: ["Login with ", isWithOtp ? 'password' : 'OTP'] }), _jsxs("div", { className: "d-flex justify-content-between align-items-center w-100 mb-4", children: [_jsx(FxTypography.Link, { className: "p-0 color-new-primary", onClick: () => setShowTransporterLogin(!showTransporterLogin), children: showTransporterLogin
                                                                ? 'Switch back to Login'
                                                                : 'Switch to Transporter Login' }), _jsx(Link, { to: "/users/forgotpassword", children: _jsx(FxTypography.Text, { className: "p-0 color-black", children: "Forgot Password?" }) })] }), _jsx(FxDivider, { className: "mb-4", children: _jsx("span", { className: "divider-between-text font-xs", children: "or" }) }), _jsxs("div", { className: `d-flex flex-${mobileView ? 'row justify-content-center' : 'column'} align-items-center w-100 mb-4`, children: [_jsx(FxTypography.Text, { children: "Don't have an account?" }), _jsx("a", { href: "https://www.fleetx.io/demo", className: `ml-${mobileView ? '2' : '0'}`, children: _jsx(FxTypography.Text, { className: "color-new-primary", children: "Request a demo" }) })] }), !isEnvLive() && (_jsxs(FxRadioGroup, { value: wallConfig, onChange: (e) => {
                                                        onChangeEnv(e.target.value);
                                                    }, className: "d-flex justify-content-center w-100", children: [_jsx(FxRadio, { value: "dev", children: "Dev" }), _jsx(FxRadio, { value: "staging", children: "Staging" }), _jsx(FxRadio, { value: "staging2", children: "Preprod dev" }), _jsx(FxRadio, { value: "preprod", children: "Wall" }), _jsx(FxRadio, { value: "prod", children: "Prod" })] }))] }));
                                    } })] }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        validateUserAndGenerateFreshdeskSSOHash,
        fetchLogin,
        fetchLoginByOtp,
        generateLoginOtp,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: get(state, 'login'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
