import {
    find,
    get,
    isEmpty,
    map,
    round,
    toNumber,
    toSafeInteger,
    toLower,
    upperFirst,
    isNaN,
    includes,
    split,
    capitalize,
    reduce,
    parseInt,
} from 'lodash';
import { isAlarmToBeRenderedOnTop, SORT_VEHICLE_DIRECTION, SORT_VEHICLE_TYPE, VEHICLE_STATUS } from './vehicle_utils';
import {
    FLEETX_ACCOUNTS,
    getFlagValueFromAccountConfig,
    idleVehicleDetailedSummaryAvailable,
    idleVehicleGTXAndLTYHours,
    parkedVehicleDetailedSummaryAvailable,
    parkedVehicleGTXAndLTXHours,
    runningVehicleDetailedSummaryAvailable,
    showLargeUiForAccount,
    showReverseAddressColoringForAccount,
    showVideoTelematics,
    useLastStatusTimeForIdleTime,
} from './account_utils';
import { findvehicleStateFromId, VEHICLE_JOB_STATE } from './job_route_utils';
import { getParsedLockData, showBatteryForLockType } from './lock_utils';
import {
    DEVICE_CATEGORY,
    DEVICE_TYPE,
    getBatteryPercent,
    getDeviceTypeFromDeviceId,
    getDeviceTypeFromDeviceTypeString,
    getTotalOdometerTillNow,
    getVehicleHumidity,
    getVehicleTemperature,
    hasCamera,
    image,
    isGT08Device,
    isV5Device,
    stopPropagation,
} from 'constant';
import { getHumanizeTime, getMomentTime, getTimeDiffHours, HOURS_1, MINUTES_1 } from './date_utils';
import { getAlarmDisplayName } from 'utils/alarm_utils';
import { INTERNAL_ISSUE_SINGULAR_LABEL } from './internal_tools/issues_utils';
import { Link } from 'react-router-dom';

import {
    getBatteryPowerLevel,
    getBatteryPowerLevelUnit,
    getBatteryRangeLevel,
    getBatteryRangeLevelForBand,
    isEvBatteryCharged,
    isEvBatteryCharging,
} from './battery_utils';
import { sortListNew } from 'utils/list_utils';
import { Button } from 'reactstrap';
import { isJobModuleEnabled, isViewVideoTelematicsAllowedForUser } from 'components/dashboard/roles/permission_utils';
import moment from 'moment-timezone';
import { FxButton, FxTag } from '../sharedV2/index';
import storage from './storage_utils';
import {
    getEvVehicleBatteryKmRange,
    getEVVehicleBatteryLevelPercentage,
    getEvVehicleGearState,
    getEvVehicleVoltage,
    isElectricVehicle,
    isFastCharging,
} from 'utils/ev_vehicle_utils';
import { showOpenIssuesOnRealtimeCardHandler } from 'utils/accountUtils/realtime';
import { FxPhoneIcon, FxThunderboltOutlined } from 'sharedV2/FxIcons';
import { COLOR_WARNING, COLOR_WHITE } from '../theme/colors';

export const ALL_REALTIME_FILTERS = {
    GROUP_FILTER: 'GROUP_FILTER',
    TAG_FILTER: 'TAG_FILTER',
    TRANSPORTER_FILTER: 'TRANSPORTER_FILTER',
    DEVICE_CATEGORY_FILTER: 'DEVICE_CATEGORY_FILTER',
    SORT_FILTER: 'SORT_FILTER',
    NOT_ON_JOB_FILTER: 'NOT_ON_JOB_FILTER',
    VEHICLE_TOWARD_PLANT_FILTER: 'VEHICLE_TOWARD_PLANT_FILTER',
    FUEL_SENSOR_STATUS_FILTER: 'FUEL_SENSOR_STATUS_FILTER',
    DOOR_STATUS_FILTER: 'DOOR_STATUS_FILTER',
    READING_FILTER: 'READING_FILTER',
    JOB_STATUS_TIME_FILTER: 'JOB_STATUS_TIME_FILTER',
    EXCLUDE_VEHICLE_STATUS_FILTER: 'EXCLUDE_VEHICLE_STATUS_FILTER',
    CURRENT_TRANSPORTER_FILTER: 'CURRENT_TRANSPORTER_FILTER',
    JOB_DELAY_FILTER: 'JOB_DELAY_FILTER',
    CAMERA_FILTER: 'CAMERA_FILTER',
    VEHICLE_DRIVER_ASSIGNMENT_FILTER: 'VEHICLE_DRIVER_ASSIGNMENT_FILTER',
    VEHICLE_WORKORDER_FILTER: 'VEHICLE_WORKORDER_FILTER',
    VEHICLE_SIZE_FILTER: 'VEHICLE_SIZE_FILTER',
    JOB_TIMING_FILTER: 'JOB_TIMING_FILTER',
    CURRENT_ADDRESS_BOOK_FILTER: 'CURRENT_ADDRESS_BOOK_FILTER',
    TEMP_SENSOR_STATUS_FILTER: 'TEMP_SENSOR_STATUS_FILTER',
    ROUTE_ID_FILTER: 'ROUTE_ID_FILTER',
    EV_BATTERY_LEVEL_FILTER: 'EV_BATTERY_LEVEL_FILTER',
    PARKING_STATUS_DURATION_FILTER: 'PARKING_STATUS_DURATION_FILTER',
    SHOW_VEHICLE_WITH_CRITICAL_DTC_FILTER: 'SHOW_VEHICLE_WITH_CRITICAL_DTC_FILTER',
};

export const getClassNameForStatus = (currentStatus, speed, durationEngineOn) => {
    switch (currentStatus) {
        case VEHICLE_STATUS.RUNNING:
            return 'text-success';
        case VEHICLE_STATUS.IDLE:
            if (durationEngineOn < 10 * 60 * 1000) {
                return 'text-primary';
            } else return 'text-warning';
        case VEHICLE_STATUS.PARKED:
            return 'text-parked';
        case VEHICLE_STATUS.PARKED_10_DAYS:
            return 'text-parked';
        case VEHICLE_STATUS.REMOVED:
            return '';
        case VEHICLE_STATUS.DISCONNECTED:
            return 'text-danger';
        case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.UNREACHABLE:
            return 'text-danger';
        case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
            return 'text-danger';
        case VEHICLE_STATUS.WIRING_DEFECT:
            return 'text-danger';
        case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
            return 'text-danger';
        case VEHICLE_STATUS.GPS_DEFECT:
            return 'text-danger';
        case VEHICLE_STATUS.GPS_0:
            return 'text-danger';
        case VEHICLE_STATUS.INSHOP:
            return 'text-danger';
        case VEHICLE_STATUS.STANDBY:
            return 'text-danger';
        case VEHICLE_STATUS.IMMOBILISED:
            return 'text-danger';
        case VEHICLE_STATUS.NO_POWER:
            return 'text-danger';
        case VEHICLE_STATUS.BATTERY_DISCHARGED:
            return 'text-danger';
        default:
            return '';
    }
};

export function getAddressTextColor(forceNormal, loggedInUser) {
    const isLargeUI = showLargeUiForAccount(loggedInUser);
    const isReverseColoring = showReverseAddressColoringForAccount(loggedInUser);
    if (isReverseColoring) {
        if (!forceNormal) {
            return '#555555';
        } else {
            return '#000000';
        }
    }
    if (!forceNormal && isLargeUI) {
        return '#000000';
    } else {
        return '#555555';
    }
}

export function renderJobStatus(toShow, statusKey, loggedInUser) {
    if (!toShow) {
        return null;
    }
    return <span>{findvehicleStateFromId(statusKey, loggedInUser)}</span>;
}

export function renderLiveOdometer(vehicle) {
    const totalOdometer = getTotalOdometerTillNow(vehicle);
    const isFuelTypeElectric = isElectricVehicle(vehicle);
    const totalOdometerFinal = isFuelTypeElectric
        ? get(vehicle, 'otherAttributes.evOdo', totalOdometer)
        : totalOdometer;
    return <span className={`text-muted`}>Odometer: {round(totalOdometerFinal)} KM</span>;
}

export function renderEngineHoursOfOperation(vehicle) {
    const totalEngineHours = get(vehicle, 'otherAttributes.engineHoursOfOperation', null);
    return totalEngineHours ? (
        <span className={`text-muted mr-2`}>Total Engine Hours: {parseEngineHoursOfOperations(totalEngineHours)}</span>
    ) : null;
}

export function parseEngineHoursOfOperations(totalEngineHours) {
    if (totalEngineHours) {
        return round(parseInt(totalEngineHours)) + ' Hrs';
    } else {
        return '';
    }
}

export function renderEngineOilPressure(vehicle) {
    const engineOilPressure = parseFloat(get(vehicle, 'otherAttributes.engineOilPressure', null));
    return engineOilPressure ? (
        <span className={`text-muted mr-2`}>Engine Oil Pressure: {parseEngineOilPressure(engineOilPressure)}</span>
    ) : null;
}

export function parseEngineOilPressure(engineOilPressure) {
    if (engineOilPressure) {
        return round(parseFloat(engineOilPressure)) + ' kPa';
    } else {
        return '';
    }
}

export function renderEngineOilTemperature(vehicle) {
    const engineOilTemperature = get(vehicle, 'otherAttributes.engineOilTemperature', null);
    return engineOilTemperature ? (
        <span className={`text-muted mr-2`}>Engine Oil Temp: {parseEngineOilTemperature(engineOilTemperature)}</span>
    ) : null;
}

export function renderCabinAcStatus(vehicle) {
    const cabinAcStatusString = get(vehicle, 'otherAttributes.cabinAcStatus', null);
    const isCabinAcStatusAvailable = cabinAcStatusString !== null;
    const cabinAcStatus = cabinAcStatusString && cabinAcStatusString === 'true';
    return isCabinAcStatusAvailable ? (
        <div className={`text-muted mr-2`}>
            Cabin AC :
            <span className={`ml-1 font-weight-bold ${cabinAcStatus ? 'text-success' : 'text-danger'}`}>
                {cabinAcStatus ? 'ON' : 'OFF'}
            </span>
        </div>
    ) : null;
}

export function rendeReferAcStatus(vehicle) {
    const referAcStatusString = get(vehicle, 'otherAttributes.referAcStatus', null);
    const isReferAcStatusAvailable = referAcStatusString !== null;
    const referAcStatus = referAcStatusString && referAcStatusString === 'true';
    return isReferAcStatusAvailable ? (
        <div className={`text-muted mr-2`}>
            Reefer AC :
            <span className={`ml-1 font-weight-bold ${referAcStatus ? 'text-success' : 'text-danger'}`}>
                {referAcStatus ? 'ON' : 'OFF'}
            </span>
        </div>
    ) : null;
}

export function renderDoorStatus(vehicle) {
    const doorStatusString = get(vehicle, 'otherAttributes.doorStatus', null);
    const isDoorStatusAvailable = doorStatusString !== null;
    const doorStatus = doorStatusString && doorStatusString === 'true';
    return isDoorStatusAvailable ? (
        <div className={`text-muted mr-2 mt-1`}>
            Door :
            <span className={`ml-1 font-weight-bold ${doorStatus ? 'text-success' : 'text-danger'}`}>
                {doorStatus ? 'CLOSED' : 'OPEN'}
            </span>
        </div>
    ) : null;
}

export function renderExternalMachineStatus(vehicle) {
    const externalMachineStatusString = get(vehicle, 'otherAttributes.externalMachineStatus', null);
    const externalMachineStatusOffDuration = toSafeInteger(
        get(vehicle, 'otherAttributes.externalMachineStatusOffTime', 0)
    );
    const iseExternalMachineStatusAvailable = externalMachineStatusString !== null;
    const externalMachineStatus = externalMachineStatusString && externalMachineStatusString === 'true';
    let offSince = '';
    if (externalMachineStatusOffDuration && !externalMachineStatus) {
        const duration = moment.duration(externalMachineStatusOffDuration).humanize();
        offSince = ` Since ${getHumanizeTime(
            getMomentTime(get(vehicle, 'lastUpdatedAt')).subtract(externalMachineStatusOffDuration)
        )}(${duration})`;
    }

    return iseExternalMachineStatusAvailable ? (
        <span className={`text-muted mr-2`}>
            External Machine Status :
            <span className={`ml-1 font-weight-bold ${externalMachineStatus ? 'text-success' : 'text-danger'}`}>
                {externalMachineStatus ? 'MACHINE ON' : 'MACHINE OFF'} {offSince}
            </span>
        </span>
    ) : null;
}

export function renderVehicleAltitude(altitude) {
    return altitude ? <span className={`text-muted mr-2`}>Altitude: {altitude}</span> : null;
}

export function renderIMEI(imei) {
    return imei ? <span className={`text-muted mr-2`}>IMEI: {imei}</span> : null;
}

export function renderThrottleVoltage(vehicle) {
    let throttleVoltage = get(vehicle, 'otherAttributes.throttleVoltage', null);
    throttleVoltage = toNumber(throttleVoltage);
    return throttleVoltage ? (
        <span className={`text-muted mr-2`}>
            {`Throttle Voltage: `} <b>{`${round(throttleVoltage, 2)}V`}</b>
        </span>
    ) : null;
}

export function renderMotorCurrent(vehicle) {
    let motorCurrent = get(vehicle, 'otherAttributes.motorCurrent', null);
    motorCurrent = toNumber(motorCurrent);
    return motorCurrent ? (
        <span className={`text-muted mr-2`}>
            {`Motor Current: `}
            <b>{`${round(motorCurrent, 2)}A`}</b>
        </span>
    ) : null;
}

export function renderMotorTemp(vehicle) {
    let motorTemp = get(vehicle, 'otherAttributes.motorTemp', null);
    motorTemp = toNumber(motorTemp);
    return motorTemp ? (
        <span className={`text-muted mr-2`}>
            {`Motor Temperature: `}
            <b>{`${round(motorTemp, 2)}°C`}</b>
        </span>
    ) : null;
}

export function renderControllerTemp(vehicle) {
    let controllerTemp = get(vehicle, 'otherAttributes.controllerTemp', null);
    controllerTemp = toNumber(controllerTemp);
    return controllerTemp ? (
        <span className={`text-muted mr-2`}>
            {`Controller Temperature: `}
            <b>{`${round(controllerTemp, 2)}°C`}</b>
        </span>
    ) : null;
}

export function renderControllerCommandMessage(vehicle) {
    const controllerCommandMessage = get(vehicle, 'otherAttributes.controllerCommandMessage', null);

    return controllerCommandMessage ? (
        <span className={`text-muted mr-2`}>
            {`Controller Message: `}
            <b>{`${controllerCommandMessage}`}</b>
        </span>
    ) : null;
}

export function renderControllerFeedbackMessage(vehicle) {
    const controllerFeedbackMessage = get(vehicle, 'otherAttributes.controllerFeedbackMessage', null);

    return controllerFeedbackMessage ? (
        <span className={`text-muted mr-2`}>
            {`Controller Feedback: `}
            <b>{`${controllerFeedbackMessage}`}</b>
        </span>
    ) : null;
}

const getDrumStatusColor = (drumStatus) => {
    if (includes(['LOADED', 'LOADING'])) {
        return 'green';
    } else {
        return 'red';
    }
};

export function renderVehicleDrumStatus(vehicle) {
    const drumStatus = get(vehicle, 'otherAttributes.drumStatus', null);
    const drumDirection = get(vehicle, 'otherAttributes.drumDirection', null);
    const vehicleStatus = get(vehicle, 'currentStatus', null);
    if (
        includes(
            [VEHICLE_STATUS.BATTERY_DISCHARGED, VEHICLE_STATUS.DISCONNECTED, VEHICLE_STATUS.UNREACHABLE],
            vehicleStatus
        )
    ) {
        return null;
    }
    return drumStatus ? (
        <div
            style={{
                display: 'inline-block',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
                borderRadius: 3,
                padding: '0 4px',
            }}
        >
            <span
                style={{
                    color: '#5D7092',
                    width: 'auto',
                    textAlign: 'center',
                    fontWeight: 700,
                }}
            >
                Drum -
            </span>
            <span
                style={{
                    marginLeft: 2,
                    color: getDrumStatusColor(drumStatus),
                }}
            >
                {drumStatus}
                {drumDirection ? ` (${drumDirection})` : ''}
            </span>
        </div>
    ) : null;
}

export function parseEngineOilTemperature(engineOilTemperature) {
    if (engineOilTemperature) {
        return round(parseFloat(engineOilTemperature), 1) + ' °C';
    } else {
        return '';
    }
}

export function renderVehicleTemperature(vehicle, index = 1) {
    const temp = getVehicleTemperature(vehicle, index);
    const doorStatusString = get(vehicle, 'otherAttributes.doorStatus', null);
    const isDoorStatusAvailable = doorStatusString !== null;
    const doorStatus = doorStatusString && doorStatusString === 'true';
    const lastTempShownTime = index === 1 ? toSafeInteger(get(vehicle, 'otherAttributes.lastTempShownTime', 0)) : 0;
    const referAcStatusString = get(vehicle, 'otherAttributes.referAcStatus', null);
    const isReferAcStatusAvailable = referAcStatusString !== null;
    const referAcStatus = referAcStatusString && referAcStatusString === 'true';
    let errorString = '';

    if (!temp && !lastTempShownTime && !doorStatus && !isReferAcStatusAvailable) {
        return <span />;
    }

    if (!temp && lastTempShownTime) {
        const duration = moment.duration(getMomentTime().diff(lastTempShownTime)).humanize();
        errorString += `Sensor Disconnected Since ${getHumanizeTime(lastTempShownTime)}(${duration})`;
    }

    return (
        <div
            style={{
                display: 'inline-block',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
                borderRadius: 3,
                marginTop: 3,
                paddingTop: '2px',
            }}
        >
            <span className={`text-temperature-blue ${temp ? 'mr-2' : ''}`} title="Temperature">
                {index === 1 && (
                    <>
                        <span
                            className={`font-weight-bold`}
                            style={{
                                color: '#000000',
                                width: 'auto',
                                padding: 5,
                            }}
                        >
                            Temperature -
                        </span>
                        {!!temp && (
                            <span style={{ color: '#333333' }} className="ml-1">
                                {temp} °C
                            </span>
                        )}
                    </>
                )}
                {index === 2 && !!temp && (
                    <>
                        <span
                            className={`font-weight-bold`}
                            style={{
                                color: '#000000',
                                width: 'auto',
                                padding: 5,
                            }}
                        >
                            Temperature 2 -
                        </span>
                        <span style={{ color: '#333333' }} className="ml-1">
                            {temp} °C
                        </span>
                    </>
                )}
                {isReferAcStatusAvailable ? (
                    <>
                        {!!temp && getSep()}
                        <span className={`text-muted ${temp ? 'mr-2' : ''}`}>
                            Reefer AC :
                            <span className={`ml-1 font-weight-bold ${referAcStatus ? 'text-success' : 'text-danger'}`}>
                                {referAcStatus ? 'ON' : 'OFF'}
                            </span>
                        </span>
                    </>
                ) : null}
                {!!errorString && (
                    <>
                        {!!isReferAcStatusAvailable && getSep()}
                        <span className="text-danger">{errorString}</span>
                    </>
                )}
            </span>
            {isDoorStatusAvailable && !!temp && getSep()}
            {isDoorStatusAvailable ? (
                <span className={`text-muted ml-2 mr-2 mt-1`}>
                    Door :
                    <span className={`ml-1 font-weight-bold ${doorStatus ? 'text-success' : 'text-danger'}`}>
                        {doorStatus ? 'CLOSED' : 'OPEN'}
                    </span>
                </span>
            ) : null}
        </div>
    );
}

export function renderVehicleTelematics(vehicle, index = 1, props, openVoiceCall) {
    const {
        deviceId,
        vehicleId,
        vehicleMake,
        groupId,
        vehicleModel,
        vehicleYear,
        currentStatus,
        speed,
        lastUpdatedAt,
        lastStatusTime,
        vehicleName,
        durationEngineOn,
        vehicleNumber,
        secondDriverNumber,
        address,
        latitude,
        longitude,
        accountId,
        distanceFromLocation,
        etaFromLocation,
        tagIds: attachedTags = [],
        yesterdayOdo,
        otherAttributes: {
            course,
            tripOdometer,
            idleDuration,
            rpm,
            dtcs,
            fuel,
            fuelSensorStatus,
            fuel_2,
            fuel_3,
            fuel_4,
            temp,
            temp2,
            temp3,
            temp4,
            humidity,
            ai2,
            lastStopAddressBookId,
            fuelTankCapacity,
            fuelTankCapacity_2,
            fuelTankCapacity_3,
            fuelTankCapacity_4,
            simTrackingStartDate,
            simTrackingExpiryDate,
            altitude,
            oaDeviceType = null,
            fuelReading,
            fuelReading2,
            fuelReading3,
            fuelReading4,
            todaysOdo,
            assets,
            deviceType,
            evBatteryLevelBand,
            power,
            evRangeForBand,
            range,
            evGearState,
            evOdo,
            imei,
            fuelPercent,
            lastFuelSensorDisconnectTime,
            odometer,
        } = {},
        vehicleTypeValue,
        fuelType,
    } = vehicle;

    const lockData = getParsedLockData(assets);
    const cameraData = lockData;
    const supplier = get(lockData, 'length', 0) ? get(lockData[0], 'supplier', '') : '';
    const assetIdForCamera = get(lockData, 'length', 0) ? get(lockData[0], 'assetId', '') : '';
    if (!isViewVideoTelematicsAllowedForUser(get(props, 'login.scope')) || !showVideoTelematics()) {
        return null;
    }
    const showLiveIcon =
        supplier === 'HOWEN'
            ? (VEHICLE_STATUS.IDLE == currentStatus ||
                  VEHICLE_STATUS.PARKED == currentStatus ||
                  VEHICLE_STATUS.RUNNING == currentStatus) &&
              !props.isSharedRealtimeVehicle
            : supplier == 'LOTIM'
            ? true
            : !props.isSharedRealtimeVehicle &&
              hasCamera(cameraData) &&
              (VEHICLE_STATUS.RUNNING == currentStatus || VEHICLE_STATUS.IDLE == currentStatus) &&
              getMomentTime().diff(getMomentTime(lastUpdatedAt), 'minutes') <= 3;
    return (
        <div className="mt-1">
            {showLiveIcon && (
                <div
                    style={{
                        display: 'inline-block',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: 'auto',
                        height: 24,
                        backgroundColor: '#F5F5F5',
                        padding: '1px 4px 0px 4px',
                        borderRadius: 3,
                    }}
                    onClick={stopPropagation}
                >
                    <div>
                        <div className="d-flex">
                            <span
                                className={`font-weight-bold`}
                                style={{
                                    color: '#000000',
                                }}
                            >
                                Camera:
                            </span>
                            <div
                                onClick={(v) => {
                                    props.openLiveRequest(v);
                                }}
                                data-vehicleid={vehicleId}
                                data-vehiclenumber={vehicleNumber}
                                data-deviceId={deviceId}
                                data-assetIdCamera={assetIdForCamera}
                                data-isHowenSupplier={supplier === 'HOWEN' ? true : false}
                                data-isLotimSupplier={supplier == 'LOTIM' ? true : false}
                                data-csbox="true"
                                data-gacategory="Realtime"
                                data-gaaction="Click"
                                data-galabel={`realtime-row-live-request`}
                                className="ml-1"
                            >
                                <img
                                    alt={`live-${vehicleNumber}`}
                                    // width="32"
                                    // height="16"
                                    // className="mb-1 ml-1"
                                    src={'/public/img/icons/vt-live-icon.svg'}
                                    title={`Live`}
                                ></img>
                                <span
                                    style={{
                                        color: '#D60000',
                                        textDecoration: 'underline',
                                        fontSize: '14px',
                                    }}
                                    className="ml-1"
                                >
                                    Live Video
                                </span>
                            </div>
                            <div
                                className="call-driver-container ml-2"
                                onClick={() => openVoiceCall(assetIdForCamera, vehicleNumber)}
                            >
                                <img
                                    alt={`call-${vehicleNumber}`}
                                    // width="32"
                                    // height="16"
                                    // className="mb-1 ml-1"
                                    src={'/public/img/icons/vt-call-icon.svg'}
                                    title={`Live`}
                                ></img>
                                <span
                                    style={{
                                        color: '#333333',
                                        textDecoration: 'underline',
                                        fontSize: '14px',
                                    }}
                                    className="call-driver-text ml-1"
                                >
                                    Call Driver
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export function renderAnalogVoltage2(vehicle) {
    const ai2 = round(get(vehicle, 'otherAttributes.ai2', 0), 3);
    if (ai2 < 1) {
        return <span />;
    }
    return (
        <span className=" mr-2" title="AnalogVoltage 2">
            <span className="font-weight-bold ml-1">{ai2} V</span>
        </span>
    );
}

export function renderVehicleHumidity(vehicle) {
    const humidity = getVehicleHumidity(vehicle);
    if (!humidity) {
        return <span />;
    }
    return (
        <span className="text-humidity-color mr-2" title="Humidity">
            <span>
                <i className=" fa fa-tint mr-1" />
            </span>
            <span className="font-weight-bold">{humidity} %</span>
        </span>
    );
}

export function renderBatteryPowerLevel(
    vehicle,
    isFuelTypeElectric = false,
    batteryLevelBand,
    batteryKmRange,
    evGearState,
    evOdo,
    odometer
) {
    const powerLevel = getBatteryPowerLevel(vehicle);
    const range = getBatteryRangeLevel(vehicle);
    const evRangeForBand = getBatteryRangeLevelForBand(vehicle);
    const isV5 = isV5Device(vehicle);
    const isGTO8 = isGT08Device(vehicle);
    const hasPowerOrBattery = !!(
        get(vehicle, 'otherAttributes.evVoltage') || get(vehicle, 'otherAttributes.evBatterySOC')
    );
    if (isFuelTypeElectric && isGTO8) {
        return null;
    }
    if (!powerLevel && !isFuelTypeElectric) {
        return null;
    }
    if (isV5 && !isFuelTypeElectric) {
        return null;
    }
    const isCharging = isEvBatteryCharging(vehicle);
    const isBatteryCharged = isEvBatteryCharged(vehicle);
    if (!hasPowerOrBattery) {
        return null;
    }
    const evOdoReading = round(evOdo);
    const totalOdoReading = round(odometer);

    const showElectricDiv = isFuelTypeElectric && !isV5;
    return (
        <div
            className="d-flex flex-wrap"
            style={{
                width: 'auto',
                height: 36,
            }}
        >
            {showElectricDiv && (
                <div>
                    {renderBatteryLevel(
                        {
                            ...vehicle,
                            isFuelTypeElectric,
                            batteryKmRange,
                        },
                        powerLevel,
                        isCharging
                    )}
                </div>
            )}

            {/*{!!range && !evRangeForBand && (*/}
            {/*    <>*/}
            {/*        <div>*/}
            {/*            <span className="text-muted  mr-1">Range Left:</span>*/}
            {/*            <span>{range} Km</span>*/}
            {/*        </div>*/}
            {/*        <span>{getSep()} </span>*/}
            {/*    </>*/}
            {/*)}*/}

            {(powerLevel || isCharging || isBatteryCharged || (showElectricDiv && batteryKmRange)) && (
                <>
                    {!showElectricDiv && (
                        <span className="mr-1">
                            {renderBatteryLevelBand(batteryLevelBand, powerLevel, batteryKmRange)}
                        </span>
                    )}
                    {!!batteryKmRange && (
                        <>
                            <span className="mr-1">{renderBatteryRange(powerLevel, batteryKmRange)}</span>
                        </>
                    )}

                    <span title="Battery Power">
                        {!!powerLevel && typeof powerLevel == 'number' && (
                            <>
                                <span>
                                    <i className="fa fa-bolt mr-1" aria-hidden="true" />
                                    {powerLevel}
                                    {getBatteryPowerLevelUnit(vehicle)}
                                </span>
                            </>
                        )}
                        {/* {isCharging && <span className="mr-1 text-success">Charging...</span>}
                        {isBatteryCharged && <span className="mr-1 text-success">Charged</span>} */}
                    </span>
                </>
            )}
            {(evGearState || evOdo || !!totalOdoReading) && (
                <>
                    <div>
                        {evGearState && (
                            <>
                                <span className="text-muted  mr-1">Gear State:</span>
                                <span className="mr-1">{upperFirst(toLower(evGearState))}</span>
                            </>
                        )}
                        {evGearState && evOdo && getSep()}
                        {!!evOdoReading && (
                            <>
                                <span className="text-muted  mr-1">Odometer:</span>
                                <span className="mr-1">{`${evOdoReading} km`}</span>
                            </>
                        )}
                        {(evOdoReading === 0 || !!!evOdoReading) && totalOdoReading && (
                            <>
                                {!evGearState && getSep()}
                                <span className="text-muted  mr-1">Odometer:</span>
                                <span className="mr-1">{`${totalOdoReading} km`}</span>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export function getBatteryIconAndText(batteryPercent) {
    let batteryIcon = '';
    let textColor = '';
    switch (true) {
        case +batteryPercent >= 0 && +batteryPercent <= 5:
            batteryIcon = image('/public/battery-icons/Battery_Critical.svg');
            textColor = '#D60000';
            break;
        case +batteryPercent > 5 && +batteryPercent <= 20:
            batteryIcon = image('/public/battery-icons/SOC_5_20.svg');
            textColor = '#D60000';
            break;

        case +batteryPercent > 20 && +batteryPercent <= 40:
            batteryIcon = image('/public/battery-icons/SOC_21_40.svg');
            textColor = '#FA6714';
            break;
        case +batteryPercent > 40 && +batteryPercent <= 60:
            batteryIcon = image('/public/battery-icons/SOC_41_60.svg');
            textColor = '#FAAD14';
            break;
        case +batteryPercent > 60 && +batteryPercent <= 80:
            batteryIcon = image('/public/battery-icons/SOC_61_80.svg');
            textColor = '#107C10';
            break;
        case +batteryPercent > 80:
            batteryIcon = image('/public/battery-icons/SOC_100.svg');
            textColor = '#107C10';
            break;
        default:
    }
    return { batteryIcon, textColor };
}

export function getBatteryChargingIconAndText(batteryPercent) {
    let batteryIcon = '';
    let textColor = '';
    switch (true) {
        case +batteryPercent >= 0 && +batteryPercent <= 5:
            batteryIcon = image('/public/battery-icons/Batter_Critical.svg');
            textColor = '#D60000';
            break;
        case +batteryPercent > 5 && +batteryPercent <= 20:
            batteryIcon = image('/public/battery-icons/Charging_SOC_5_20.svg');
            textColor = '#D60000';
            break;

        case +batteryPercent > 20 && +batteryPercent <= 40:
            batteryIcon = image('/public/battery-icons/Charging_SOC_21_40.svg');
            textColor = '#FA6714';
            break;
        case +batteryPercent > 40 && +batteryPercent <= 60:
            batteryIcon = image('/public/battery-icons/Charging_SOC_41_60.svg');
            textColor = '#FAAD14';
            break;
        case +batteryPercent > 60 && +batteryPercent <= 80:
            batteryIcon = image('/public/battery-icons/Charging_SOC_61_80.svg');
            textColor = '#107C10';
            break;
        case +batteryPercent > 80:
            batteryIcon = image('/public/battery-icons/Charging_SOC_100.svg');
            textColor = '#107C10';
            break;
        default:
    }
    return { batteryIcon, textColor };
}

export function getBatteryIconWithBand(batteryLevelBand) {
    let batteryIcon = '';
    let textColor = '';
    switch (batteryLevelBand) {
        case '100':
            batteryIcon = image('/public/battery-icons/Battery_band_100.svg');
            textColor = '#107C10';
            break;
        case '80':
            batteryIcon = image('/public/battery-icons/Battery_band_61_80.svg');
            textColor = '#107C10';
            break;
        case '60':
            batteryIcon = image('/public/battery-icons/Battery_band_41_60.svg');
            textColor = '#FAAD14';
            break;
        case '40':
            batteryIcon = image('/public/battery-icons/Battery_band_21_40.svg');
            textColor = '#FA6714';
            break;
        case '20':
            batteryIcon = image('/public/battery-icons/Battery_band_5_20.svg');
            textColor = '#D60000';
            break;
        case '5':
            batteryIcon = image('/public/battery-icons/Battery_band_5_20.svg');
            textColor = '#D60000';
            break;
        default:
    }
    return { batteryIcon, textColor };
}

export function renderJammingStatus(vehicle, toggleEvJamModal, setSelectedJamVehicle, currentStatus) {
    let evWheelJamStatus = get(vehicle, 'otherAttributes.evWheelJamStatus', null);
    if (evWheelJamStatus === null) {
        return null;
    }
    // const { batteryIcon, textColor } = getBatteryIconAndText(adBluePercent);
    const jamStatus = evWheelJamStatus === 'true' ? 'DISARM' : 'ARM';
    const jamTextToShow = evWheelJamStatus === 'true' ? 'ARMED' : 'DISARMED';
    let wheelJamSetTimeObject = {};
    if (window && window.localStorage) {
        const prevLocalStorage = storage.getItem('wheelJamStatus', '');
        wheelJamSetTimeObject = JSON.parse(prevLocalStorage);
    }
    const vehicelId = get(vehicle, 'vehicleId', null);
    const setTimeObjectValue = get(wheelJamSetTimeObject, vehicelId, null);
    let operationType = null;
    let isTimeLessThan3Min = false;
    let operationTypeSame = null;
    let disabledButton = false;
    if (setTimeObjectValue) {
        isTimeLessThan3Min = getMomentTime().valueOf() - get(setTimeObjectValue, 'setTime', 0) <= MINUTES_1 * 3;
        operationType = get(setTimeObjectValue, 'status');
        // if(jamTextToShow == )
        operationTypeSame =
            (operationType && evWheelJamStatus === 'true') || (!operationType && evWheelJamStatus === 'false');
        disabledButton = isTimeLessThan3Min ? !operationTypeSame : false;
    }

    return (
        <>
            {evWheelJamStatus !== null ? (
                <span>
                    <span className={`font-weight-bold mr-1 text-color-adblue`}>Wheel Jam status</span>|
                    {evWheelJamStatus && <span> {jamTextToShow} | </span>}{' '}
                    <span>
                        <FxButton
                            onClick={(e) => {
                                e.stopPropagation();
                                // if (evWheelJamStatus) {
                                toggleEvJamModal(jamStatus, e);
                                setSelectedJamVehicle(vehicle, e);
                                // }
                            }}
                            disabled={disabledButton || currentStatus === VEHICLE_STATUS.UNREACHABLE}
                            title={
                                disabledButton
                                    ? 'This may take few Min to complete the operation'
                                    : currentStatus === VEHICLE_STATUS.UNREACHABLE
                                    ? 'Not Allow Operation In unreachable status'
                                    : ''
                            }
                        >
                            {disabledButton ? (operationType === true ? 'Arming' : 'Disarming') : jamStatus}
                        </FxButton>
                    </span>
                    {/* <i className={`${batteryIcon} mr-1`} aria-hidden="true" /> */}
                    {/* {liters ? (
                <span className={`font-weight-bold mr-1 ${textColor}`}>{liters + ' / ' + capacity + 'L'}</span>
            ) : (
                <span className={`font-weight-bold mr-1 ${textColor}`}>{round(adBluePercent)}%</span>
            )} */}
                </span>
            ) : (
                ''
            )}
        </>
    );
}

export function renderAbBlueLevel(vehicle) {
    const deviceType = get(vehicle, 'otherAttributes.deviceType', '');
    const isDeviceTypeOBD = deviceType.slice(0, 3) === '213' || includes(['TFT100', 'TMD102'], deviceType);
    let adBluePercent = get(vehicle, 'otherAttributes.dieselExhaustFluid', null);
    let liters = get(vehicle, 'otherAttributes.adblueInLitres', null);
    let capacity = get(vehicle, 'otherAttributes.adblueTankCapacity', null);
    let fuel = get(vehicle, 'otherAttributes.fuel', null);
    let fuelCapacity = get(vehicle, 'otherAttributes.fuelTankCapacity', null);
    const fuelPercent = get(vehicle, 'otherAttributes.fuelPercent', null);
    const isSourceTpApi = get(vehicle, 'otherAttributes.source') === 'API';
    const isFuelTypeCNG = get(vehicle, 'fuelType') === 'CNG';

    liters && (liters = liters.replace(/L$/, ''));
    capacity && (capacity = capacity.replace(/L$/, ''));
    fuel && (fuel = fuel.replace(/L$/, ''));
    fuelCapacity && (fuelCapacity = fuelCapacity.replace(/L$/, ''));

    const fuelPerCalculated = fuel && fuelCapacity ? (parseInt(fuel) / parseInt(fuelCapacity)) * 100 : 0;

    if (liters === null && !isDeviceTypeOBD) {
        return null;
    }

    adBluePercent = adBluePercent && parseInt(adBluePercent);
    const { textColor: adBlueTextColor } = getBatteryIconAndText(adBluePercent);
    const { textColor: fuelTextColor } = getBatteryIconAndText(parseInt(fuelPercent));
    return (
        <div
            style={{
                display: 'inline-block',
                alignItems: 'center',
                textAlign: 'center',
                border: '1px solid #DADADA',
                width: 'auto',
                height: 24,
                backgroundColor: '#F5F5F5',
                borderRadius: 3,
                marginTop: '0.25rem',
            }}
        >
            <span
                className={`font-weight-bold text-color-adblue`}
                style={{
                    color: '#000000',
                    width: 'auto',
                    padding: 5,
                }}
            >
                {isDeviceTypeOBD ? 'OBD' : ''}{' '}
                {(adBluePercent || fuel || fuelPercent || liters) && isDeviceTypeOBD && <span>-</span>}
                {isSourceTpApi ? 'TP API:' : ''}{' '}
            </span>
            {(liters !== null || adBluePercent !== null) && !isFuelTypeCNG && (
                <>
                    <span className="mr-1" style={{ color: '#333333' }}>
                        DEF:{' '}
                    </span>
                    {liters && (
                        <span className={`font-weight-bold mr-1`} style={{ color: '#000000' }}>
                            {liters + 'L' + (capacity ? ' / ' + parseInt(capacity) + 'L' : '')}
                            {`${adBluePercent ? ',' : ''}`}
                        </span>
                    )}
                    {adBluePercent && (
                        <span className={`font-weight-bold mr-1`} style={{ color: '#000000' }}>
                            {`${adBluePercent} %`}
                        </span>
                    )}
                </>
            )}
            {(fuel || fuelPercent) && !isFuelTypeCNG && (
                <>
                    {(liters !== null || adBluePercent !== null) && getSep()}
                    <span className="mr-1" style={{ color: '#333333' }}>
                        Fuel:{' '}
                    </span>
                    <img width="18" height="18" className="mb-1" src={'/public/img/icons/fuelTank-icon.svg'} />
                    {!!fuel && (
                        <span className={`font-weight-bold mr-1`} style={{ color: fuelTextColor }}>
                            {fuel + 'L' + (fuelCapacity ? ' / ' + parseInt(fuelCapacity) + 'L' : '')}
                            {`${fuelPercent || fuelPerCalculated ? ',' : ''}`}
                        </span>
                    )}

                    {!!(fuel && fuelCapacity) ? (
                        <span className={`font-weight-bold mr-1`} style={{ color: fuelTextColor }}>
                            {parseInt(fuelPerCalculated)}%
                        </span>
                    ) : (
                        fuelPercent && (
                            <span className={`font-weight-bold mr-1`} style={{ color: fuelTextColor }}>
                                {parseInt(fuelPercent)}%
                            </span>
                        )
                    )}
                </>
            )}
            {isFuelTypeCNG && fuelPercent && <span className={` mr-1`}>CNG - {`${fuelPercent}`}</span>}
        </div>
    );
}

export function renderBatteryLevel(vehicle, powerLevel, isCharging = false) {
    const lockBattery = get(vehicle, 'otherAttributes.lockBattery', null);
    const evBatterySOC = get(vehicle, 'otherAttributes.evBatterySOC');
    const isFuelTypeElectric = get(vehicle, 'isFuelTypeElectric');
    let batteryPercent =
        lockBattery !== null
            ? lockBattery
            : getBatteryPercent(
                  vehicle.deviceId,
                  evBatterySOC ? evBatterySOC : get(vehicle, 'otherAttributes.battery', 0),
                  get(vehicle, 'otherAttributes.oaDeviceType', get(vehicle, 'otherAttributes.deviceType')),
                  get(vehicle, 'otherAttributes.PDBattery')
              );
    if (evBatterySOC && lockBattery === null) {
        batteryPercent = evBatterySOC;
    }
    if (!batteryPercent) {
        return '';
    }
    const { batteryIcon, textColor } = isCharging
        ? getBatteryChargingIconAndText(batteryPercent)
        : getBatteryIconAndText(batteryPercent);
    const batteryKmRange = get(vehicle, 'batteryKmRange');
    return (
        <div>
            {isFuelTypeElectric && (
                <span
                    className={`font-weight-bold mr-1`}
                    style={{
                        display: 'inline-block',
                        border: 'none',
                        borderRadius: 10,
                        backgroundColor: '#F3F8F3',
                        color: '#107C10',
                        width: 35,
                        textAlign: 'center',
                    }}
                >
                    EV
                </span>
            )}
            <img className={`mr-1`} src={batteryIcon} aria-hidden="true" alt="" />
            <span className={`font-weight-bold mr-1`} style={{ color: textColor }}>
                {round(batteryPercent)}%
            </span>
            {/* {charge == 'true' && <span className="text-success mr-2">Charging...</span>} */}
            {/* {isEmpty(powerLevel) && !batteryKmRange && <span>{getSep()} </span>}             */}
        </div>
    );
}

export function renderBatteryLevelBand(batteryLevelBand, power, batteryKmRange) {
    if (!batteryLevelBand || +power < 10) {
        return '';
    }
    const { batteryIcon, textColor } = getBatteryIconWithBand(batteryLevelBand);

    return (
        <div>
            <span
                className={`font-weight-bold mr-1`}
                style={{
                    display: 'inline-block',
                    border: 'none',
                    borderRadius: 10,
                    backgroundColor: '#F3F8F3',
                    color: '#107C10',
                    width: 30,
                    textAlign: 'center',
                }}
            >
                EV
            </span>
            <img className={`mr-1`} src={batteryIcon} aria-hidden="true" alt="" />
            <span className={`font-weight-bold mr-1`} style={{ color: textColor }}>{`${batteryLevelBand} ${
                isNaN(batteryLevelBand) ? '' : '%'
            }`}</span>
            {!batteryKmRange && <span>{getSep()} </span>}
        </div>
    );
}

export function renderBatteryRange(power, batteryKmRange) {
    if (!batteryKmRange) {
        return '';
    }

    return (
        <>
            <span>{getSepSlant()} </span>
            <span className={`font-weight-bold`}>{round(batteryKmRange, 2)}</span>
            <span className={`font-weight-bold`}>{`km`}</span>
            <span>{getSep()} </span>
        </>
    );
}

export function renderLastAlarm(vehicle, isListView = false) {
    if (isAlarmToBeRenderedOnTop(vehicle)) {
        const alarm = JSON.parse(get(vehicle, 'otherAttributes.alarm'));
        const lastAlarmTriggered = get(vehicle, 'otherAttributes.lastAlarmTriggered');
        return (
            <div className={`text-danger ${isListView ? '' : 'mt-1'} font-weight-bold`}>
                {getAlarmDisplayName(alarm[0].type)} triggered{' '}
                {lastAlarmTriggered ? ` on ${getHumanizeTime(toSafeInteger(lastAlarmTriggered))}` : ''}
            </div>
        );
    } else {
        return <div />;
    }
}

export const renderDtcCriticality = (vehicle, visibleVehicleDtcsMap) => {
    const currVehicleId = get(vehicle, 'vehicleId');
    const vehicelDtcArr = visibleVehicleDtcsMap[currVehicleId];
    const vehicleDTCObj = get(vehicelDtcArr, '[0]');
    const startDate = getMomentTime().subtract(24, 'hour').startOf('hour').valueOf();
    const endDate = getMomentTime().valueOf();
    const dtcCode = get(vehicleDTCObj, 'dtcCode.code');
    return (
        <>
            {!!dtcCode ? (
                <a
                    className="font-weight-bold"
                    onClick={stopPropagation}
                    key={currVehicleId}
                    href={`/dashboard/dtc#vehicleId=${currVehicleId}&startDate=${startDate}&endDate=${endDate}`}
                    target="_blank"
                    style={{
                        display: 'inline-block',
                        border: 'none',
                        borderRadius: 3,
                        marginTop: '0.25rem',
                        padding: 3,
                        backgroundColor: '#fcf2f3',
                        color: '#fa232a',
                        textAlign: 'center',
                    }}
                >
                    Critical DTC detected: {dtcCode}
                </a>
            ) : null}
        </>
    );
};

export function renderLockDetails(
    lockId,
    lockName,
    lockStatus,
    lockBattery,
    locktype,
    isListView = false,
    deviceType,
    accesstoken,
    unlockLock,
    assetId,
    fetchAnalyticsLive,
    toggleUnlockModal,
    setSelectedVehicle,
    vehicle,
    shouldGenerateDhanukaLock = false
) {
    const batteryPercent = getBatteryPercent(lockId, lockBattery, deviceType);
    const lockIcon = lockStatus && lockStatus.match(/^locked/i) ? 'fa-lock' : 'fa-unlock-alt';
    const { batteryIcon, textColor } = getBatteryIconAndText(batteryPercent);
    const showBattery = showBatteryForLockType(locktype);

    return (
        <div key={lockId} className={`${isListView ? '' : 'mt-2'} d-flex`}>
            <span
                style={{
                    display: 'inline-block',
                    border: '1px solid #005A9E',
                    borderRadius: 8,
                    backgroundColor: '#EFF6FC',
                    color: '#005A9E',
                    width: 'auto',
                    paddingLeft: 4,
                    paddingRight: 4,
                    textAlign: 'center',
                    height: 25,
                    fontWeight: 500,
                }}
            >
                E-Lock
            </span>
            <div>
                <span style={{ marginLeft: '4px' }} title="Lock Name">
                    {lockName}
                </span>{' '}
                {getSep()}
                <span title="Lock Status">{lockStatus}</span>
                {!!showBattery && <span>{getSep()} </span>}
                {!!showBattery && (
                    <span title="Lock Battery Level">
                        <i className={`${batteryIcon}`} />
                        <span className={`${textColor}`}>
                            {+batteryPercent >= 0 ? ' ' + round(+batteryPercent) + '%' : 'NA'}
                        </span>
                    </span>
                )}
                {lockStatus && lockStatus.match(/^locked/i) && <span>{getSep()}</span>}
                {lockStatus && lockStatus.match(/^locked/i) && (
                    <Button
                        className="btn-sm text-success"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (shouldGenerateDhanukaLock) {
                                toggleUnlockModal();
                                setSelectedVehicle(vehicle);
                            } else {
                                if (!confirm('Are sure you want to unlock this lock?')) {
                                    return;
                                }
                                unlockLock(accesstoken, assetId).then(() => {
                                    alert('Lock unlocked successfully.');
                                    fetchAnalyticsLive(accesstoken);
                                });
                            }
                        }}
                    >
                        Unlock
                    </Button>
                )}
            </div>
        </div>
    );
}

export function getSep() {
    return <span className="mx-1 text-muted">|</span>;
}

export function getSepSlant() {
    return <span className=" text-muted">/</span>;
}

export function renderRealtimeOpenIssuesCount(moreInfo) {
    const { vehicleOpenIssuesCount = 0, onCLickOpenIssues } = moreInfo || {};
    return (
        <span>
            <span className={`font-weight-bold mr-1 text-warning`}>
                {vehicleOpenIssuesCount} {window.t('open')} {window.t('issue')}
                {vehicleOpenIssuesCount > 1 ? 's' : ''}
            </span>
            <FxButton type={'link'} className={'px-0'} onClick={() => onCLickOpenIssues && onCLickOpenIssues(moreInfo)}>
                {window.t('details')}
            </FxButton>
        </span>
    );
}

export function renderVehicleInternalIssuesCount(vehicleInternalIssuesCount, moreInfo = {}) {
    const { vehicleOpenIssuesCount = 0, vehicleId } = moreInfo || {};
    const isInternalIssueAvailable = vehicleInternalIssuesCount > 0;
    const isOpenIssuesAvailable = vehicleOpenIssuesCount > 0 && showOpenIssuesOnRealtimeCardHandler();
    if (!isInternalIssueAvailable && !isOpenIssuesAvailable) {
        return null;
    }
    const to = `/dashboard/internalissues#vehicleId=${vehicleId}&status=OPEN`;
    return (
        <div onClick={stopPropagation}>
            <i className={`fa fa-ticket mr-2`} aria-hidden="true" style={{ color: '#555' }} />
            {isInternalIssueAvailable && (
                <Link to={to} style={{ textDecoration: 'none' }}>
                    <span className={`font-weight-bold text-danger`}>
                        {vehicleInternalIssuesCount} Open {INTERNAL_ISSUE_SINGULAR_LABEL}
                        {vehicleInternalIssuesCount > 1 ? 's' : ''}
                    </span>
                </Link>
            )}
            {isInternalIssueAvailable && isOpenIssuesAvailable && getSep()}
            {isOpenIssuesAvailable && renderRealtimeOpenIssuesCount(moreInfo)}
        </div>
    );
}

export function renderVehicleAlertIssuesCount(openIssuesCount, vehicleId) {
    if (!openIssuesCount) {
        return null;
    }
    return (
        <div onClick={stopPropagation} className="d-flex align-items-center mt-1">
            <i className={`fa fa-warning mr-2`} aria-hidden="true" style={{ color: '#555' }} />
            {map(openIssuesCount, (issue, index) => (
                <Link
                    to={`/dashboard/issues#vehicleId=${vehicleId}&type=ALARM&subType=${
                        issue.subType
                    }&status=OPEN&currentPage=1&sortOrder=desc&sortName=reportedAt&startDate=${getMomentTime()
                        .subtract(30, 'days')
                        .valueOf()}&endDate=${getMomentTime().valueOf()}`}
                    style={{ textDecoration: 'none' }}
                >
                    <span className={`font-weight-bold mr-2 text-danger`}>
                        {`${issue.value} ${issue.subType} Issue${
                            index === get(openIssuesCount, 'length') - 1 ? '' : ','
                        }`}
                        {issue.value > 1 ? 's' : ''}
                    </span>
                </Link>
            ))}
        </div>
    );
}

export function getLabelForRunningGreaterLess(text = '') {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const value = runningVehicleDetailedSummaryAvailable(loggedInUser).value;
    text.replace('Running', window.t('running'));
    return text.replace('X', value);
}

export function getLabelForParkedGreaterLess(text = '') {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const value = parkedVehicleDetailedSummaryAvailable(loggedInUser).value;

    let valueInDays;
    let finaleLabel = text;
    finaleLabel.replace('Parked', window.t('parked'));

    switch (accountId) {
        case FLEETX_ACCOUNTS.ZYNGO:
        case FLEETX_ACCOUNTS.KAPOOR_DIESELS:
            valueInDays = value / (24 * 60);
            finaleLabel = text.replace('X min', `${round(valueInDays)} Days`);
            finaleLabel.replace('Days', window.t('days'));
            break;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            valueInDays = value / 60;
            finaleLabel = text.replace('X min', `${valueInDays} Hr`);
            finaleLabel.replace('Hr', window.t('hr'));
            break;
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            valueInDays = value / 60;
            finaleLabel = text.replace('X min', `${round(valueInDays)} Hr`);
            finaleLabel.replace('Hr', window.t('hr'));
            break;
        default:
            finaleLabel = text.replace('X', `${round(value)}`);
            finaleLabel.replace('min', window.t('min'));
    }
    return finaleLabel;
}

export function getLabelForIdleGreaterLess(text = '') {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const value = idleVehicleDetailedSummaryAvailable().value;

    switch (accountId) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            const valueInDays2 = value / 60;
            return text.replace('X min', `${round(valueInDays2)} Hr`);
        default:
            return text.replace('X', value);
    }
}

export function getLabelForParkedBetweenXHours(text) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const { greaterThan, lessThan } = parkedVehicleGTXAndLTXHours();

    let greaterThanValue;
    let lessThanValue;
    let unit1 = '';
    let unit2 = 'hr';
    switch (accountId) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            unit1 = 'min';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan / 60; // hr;
            break;
        case FLEETX_ACCOUNTS.BISLERI:
            // unit1 = 'min';
            unit2 = 'min';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan; // hr;
            break;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            unit1 = 'min';
            unit2 = 'Hr';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan / 60; // hr;
            break;
        default:
            greaterThanValue = greaterThan / 60; // hr ;
            lessThanValue = lessThan / 60; // hr;
    }
    return `${text}`
        .replace('X1', `${round(greaterThanValue)}${unit1}`)
        .replace('X2 hr', `${round(lessThanValue)} ${unit2}`);
}

export function getLabelForIdleBetweenXHours(text) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const { greaterThan, lessThan } = idleVehicleGTXAndLTYHours();

    let greaterThanValue;
    let lessThanValue;
    let unit1 = '';
    let unit2 = 'hr';
    switch (accountId) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            unit1 = 'min';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan / 60; // hr;
            break;
        default:
            greaterThanValue = greaterThan / 60; // hr ;
            lessThanValue = lessThan / 60; // hr;
    }
    return `${text}`
        .replace('X1', `${round(greaterThanValue)}${unit1}`)
        .replace('X2 hr', `${round(lessThanValue)} ${unit2}`);
}

export const REALTIME_LIST_MAP_BUTTONS = {
    LIST: {
        name: 'List',
        order: 1,
        key: 'LIST',
    },
    MAP: {
        name: 'Map',
        order: 2,
        key: 'MAP',
    },
};

export const REALTIME_MAP_TYPE = {
    OSM: {
        name: 'Osm',
        order: 1,
        key: 'OSM',
    },
    GOOGLE: {
        name: 'Google',
        order: 2,
        key: 'GOOGLE',
    },
};

export const SEARCH_BY_BUTTONS = {
    VEHICLES: {
        name: 'vehicles.tabs.general',
        order: 1,
        key: 'VEHICLES',
    },
    LOCATION: {
        name: 'location',
        order: 2,
        key: 'LOCATION',
    },
};
export const getSearchByButtonByLabel = (name) => {
    return window.t(name);
};

export function orderVehicleBasedOnTagHistory(filteredRealtimeVehicleList, vehicleTagHistory, tagFilter) {
    if (!tagFilter || isEmpty(filteredRealtimeVehicleList) || isEmpty(vehicleTagHistory)) {
        return filteredRealtimeVehicleList;
    }
    return sortListNew(
        map(filteredRealtimeVehicleList, (v) => {
            const foundHistory = find(vehicleTagHistory, { licencePlate: v.vehicleNumber });
            const tagAttachedDateMs = getMomentTime(get(foundHistory, 'attachDate')).valueOf();
            return {
                ...v,
                tagAttachedDateMs,
            };
        }),
        'asc',
        'tagAttachedDateMs'
    );
}

export const NO_NEXT_ROUTE_POINT_CATEGORY = 'NO_NEXT_ROUTE_POINT_CATEGORY';
export const NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY = 'NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY';

export function getNextRoutePointCategoryTypeLabel(nextAddressBookCategory) {
    if (nextAddressBookCategory === NO_NEXT_ROUTE_POINT_CATEGORY) {
        return 'Towards No Category';
    }
    return `Towards ${nextAddressBookCategory}`;
}

export function getCurrentRoutePointCategoryTypeLabel(currentAddressBookCategory) {
    return `At ${currentAddressBookCategory}`;
}

export function getScheduleToRoutePointCategoryTypeLabel(currentAddressBookCategory) {
    if (currentAddressBookCategory === NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY) {
        return 'Scheduled To No Category';
    }
    return `Scheduled to ${currentAddressBookCategory}`;
}

export function showSearchVehicleByProjectInfo() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.RMC], accountId);
}

export class WorkerInterval {
    worker = null;
    workerScript = null;

    constructor(callback, interval) {
        const blob = new Blob([`setInterval(() => postMessage(0), ${interval});`]);
        this.workerScript = URL.createObjectURL(blob);
        this.worker = new Worker(this.workerScript);
        this.worker.onmessage = callback;
    }

    stop() {
        this.worker.terminate();
        window.URL.revokeObjectURL(this.workerScript);
    }
}

export function renderVehicleTrailerDetails(details) {
    return (
        <div className="d-flex align-items-center mt-">
            <img
                title="Attached Trailer"
                src={'/public/img/vehicle-trailer-realtime-icon.svg'}
                className="mr-1"
                alt={'Trailer is attached'}
            />
            <div className="d-flex">
                {details.map((item, index) => (
                    <div className="d-flex" key={index}>
                        <div>{item.name}</div>
                        {index + 1 !== details.length && <div className="ml-1 mr-1">|</div>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export function getSortOptionsRealtime(isJobModuleEnabledFlag = false) {
    const finalIsJobModuleEnabled =
        isJobModuleEnabledFlag ||
        isJobModuleEnabled(get(window.FLEETX_LOGGED_IN_DATA, 'modules'), get(window.FLEETX_LOGGED_IN_DATA, 'scope'));

    return [
        {
            newValue: 'SPEED_SLOWEST',
            value: 'SPEED::UP',
            label: 'Speed (Slowest)',
            sortVehicleFilter: SORT_VEHICLE_TYPE.SPEED,
            sortVehicleDirection: SORT_VEHICLE_DIRECTION.UP,
        },
        {
            newValue: 'SPEED_FASTEST',
            value: 'SPEED::DOWN',
            label: 'Speed (Fastest)',
            sortVehicleFilter: SORT_VEHICLE_TYPE.SPEED,
            sortVehicleDirection: SORT_VEHICLE_DIRECTION.DOWN,
        },
        {
            newValue: 'STATUS_TIME_SHORTEST',
            value: 'STATUS_TIME::UP',
            label: 'Status Time (Shortest)',
            sortVehicleFilter: SORT_VEHICLE_TYPE.STATUS_TIME,
            sortVehicleDirection: SORT_VEHICLE_DIRECTION.UP,
        },
        {
            newValue: 'STATUS_TIME_LONGEST',
            value: 'STATUS_TIME::DOWN',
            label: 'Status Time (Longest)',
            sortVehicleFilter: SORT_VEHICLE_TYPE.STATUS_TIME,
            sortVehicleDirection: SORT_VEHICLE_DIRECTION.DOWN,
        },
        {
            newValue: 'VEHICLE_NAME_ASC',
            value: 'NAME',
            label: 'Vehicle Name',
            sortVehicleFilter: SORT_VEHICLE_TYPE.NAME,
            sortVehicleDirection: SORT_VEHICLE_DIRECTION.DOWN,
        },
        ...(finalIsJobModuleEnabled
            ? [
                  {
                      newValue: 'JOB_STATUS_TIME_SHORTEST',
                      value: 'JOB_STATUS_TIME::UP',
                      label: 'Job Status Time (Shortest)',
                      sortVehicleFilter: SORT_VEHICLE_TYPE.JOB_STATUS_TIME,
                      sortVehicleDirection: SORT_VEHICLE_DIRECTION.UP,
                  },
                  {
                      newValue: 'JOB_STATUS_TIME_LONGEST',
                      value: 'JOB_STATUS_TIME::DOWN',
                      label: 'Job Status Time (Longest)',
                      sortVehicleFilter: SORT_VEHICLE_TYPE.JOB_STATUS_TIME,
                      sortVehicleDirection: SORT_VEHICLE_DIRECTION.DOWN,
                  },
                  {
                      newValue: 'JOB_DELAY_SHORTEST',
                      value: 'JOB_DELAY_TIME::DOWN',
                      label: 'Job Delay (Longest)',
                      sortVehicleFilter: SORT_VEHICLE_TYPE.JOB_DELAY_TIME,
                      sortVehicleDirection: SORT_VEHICLE_DIRECTION.DOWN,
                  },
                  {
                      newValue: 'JOB_DELAY_LONGEST',
                      value: 'JOB_DELAY_TIME::UP',
                      label: 'Job Delay (Shortest)',
                      sortVehicleFilter: SORT_VEHICLE_TYPE.JOB_DELAY_TIME,
                      sortVehicleDirection: SORT_VEHICLE_DIRECTION.UP,
                  },
              ]
            : []),
    ];
}

export function getIdleTimeInMs(vehicle) {
    const { durationEngineOn, lastStatusTime } = vehicle || {};
    let idleTime = durationEngineOn;
    const useLastStatusTimeForIdle = useLastStatusTimeForIdleTime();
    if (useLastStatusTimeForIdle && lastStatusTime) {
        idleTime = getMomentTime().diff(getMomentTime(lastStatusTime));
    }
    return idleTime;
}

export function jobStatusTimeFilterOptions(isNewApi = false) {
    if (isNewApi) {
        return [
            {
                value: 'ZERO_TO_12',
                label: `0-12 ${window.t('hours')}`,
            },
            {
                value: 'TWELVE_TO_TWENTY_FOUR',
                label: `12-24 ${window.t('hours')}`,
            },
            {
                value: 'TWENTY_FOUR_TO_FORTY_EIGHT',
                label: `24-48 ${window.t('hours')}`,
            },
            {
                value: 'GREATER_THAN_FORTY_EIGHT',
                label: `>48 ${window.t('hours')}`,
            },
        ];
    }
    return [
        {
            value: `BW:0-${12 * HOURS_1}`, //ms
            label: `0-12 ${window.t('hours')}`,
        },
        {
            value: `BW:${12 * HOURS_1}-${24 * HOURS_1}`, //ms
            label: `12-24 ${window.t('hours')}`,
        },
        {
            value: `BW:${24 * HOURS_1}-${48 * HOURS_1}`, //ms
            label: `24-48 ${window.t('hours')}`,
        },
        {
            value: `GT:${48 * HOURS_1}`, //ms
            label: `>48 ${window.t('hours')}`,
        },
    ];
}

export function getJobStatusFilterFromValue(value) {
    if (!value) {
        return null;
    }
    const split1 = split(value, ':');
    const filterType = split1[0];
    let v1;
    let v2;
    if (filterType === 'BW') {
        const split2 = split(split1[1], '-');
        v1 = toSafeInteger(split2[0]);
        v2 = toSafeInteger(split2[1]);
    } else if (filterType === 'GT' || filterType === 'LT') {
        v1 = toSafeInteger(split1[1]);
    }
    return {
        filterType,
        v1,
        v2,
    };
}

export function showJobStatusTimeFilterForJobStatus(vehicleJobStateFilter) {
    return includes(
        [VEHICLE_JOB_STATE.LOADING, VEHICLE_JOB_STATE.LOADED, VEHICLE_JOB_STATE.UNLOADING],
        vehicleJobStateFilter
    );
}

export function shouldIncludeInFilteredListOnJobStatusTimeFilter({
    vehicleJobStateFilter,
    lastJobStatusTime,
    jobStatusTimeFilter,
    toFilter = false,
}) {
    if (vehicleJobStateFilter && jobStatusTimeFilter && showJobStatusTimeFilterForJobStatus(vehicleJobStateFilter)) {
        if (!lastJobStatusTime) {
            return false;
        }

        const timeDiffInMs = getMomentTime().valueOf() - lastJobStatusTime;
        const selectedJobTimeFilter = getJobStatusFilterFromValue(jobStatusTimeFilter);
        const { filterType, v1, v2 } = selectedJobTimeFilter || {};

        switch (filterType) {
            case 'BW':
                return v1 <= timeDiffInMs && timeDiffInMs <= v2;
            case 'GT':
                return timeDiffInMs >= v1;
            case 'LT':
                return timeDiffInMs <= v1;
            default:
                return false;
        }
    }
    return toFilter;
}

export function realtimeSummaryFieldsConfig() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId');
    return {
        [VEHICLE_JOB_STATE.WAITING_IN_GARAGE]: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
        [VEHICLE_JOB_STATE.IN_GARAGE]: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
        [VEHICLE_JOB_STATE.UNLOADED]: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
    };
}

export function isRealtimeSummaryItemShouldShown(key, config) {
    return get(config, `${key}.show`, true);
}

export function showAddressBookMarkersByDefaultOnRealtime() {
    return includes([11553, 13806, 12985], get(window.FLEETX_LOGGED_IN_USER, 'accountId'));
}

export const VEHICLE_DRIVER_FILTER_OPTION = {
    WITH_DRIVER: 'WITH_DRIVER',
    WITHOUT_DRIVER: 'WITHOUT_DRIVER',
    BOTH: 'BOTH',
};

export function getVehicleDriverFilterOptions(isNewApi = false) {
    if (isNewApi) {
        return [
            {
                value: VEHICLE_DRIVER_FILTER_OPTION.WITH_DRIVER,
                label: window.t('withDriver'),
            },
            {
                value: VEHICLE_DRIVER_FILTER_OPTION.WITHOUT_DRIVER,
                label: window.t('withoutDriver'),
            },
        ];
    }
    return [
        {
            value: VEHICLE_DRIVER_FILTER_OPTION.WITH_DRIVER,
            label: window.t('withDriver'),
        },
        {
            value: VEHICLE_DRIVER_FILTER_OPTION.WITHOUT_DRIVER,
            label: window.t('withoutDriver'),
        },
        {
            value: VEHICLE_DRIVER_FILTER_OPTION.BOTH,
            label: window.t('both'),
        },
    ];
}

export const VEHICLE_WORKORDER_FILTER_OPTION = {
    ON_WORKORDER: 'ON_WORKORDER',
    NOT_ON_WORKORDER: 'NOT_ON_WORKORDER',
    BOTH: 'BOTH',
};

export function getVehicleWorkOrderFilterOptions(isNewApi = false) {
    if (isNewApi) {
        return [
            {
                value: VEHICLE_WORKORDER_FILTER_OPTION.ON_WORKORDER,
                label: window.t('onWorkorder'),
            },
            {
                value: VEHICLE_WORKORDER_FILTER_OPTION.NOT_ON_WORKORDER,
                label: window.t('notOnWorkorder'),
            },
        ];
    }
    return [
        {
            value: VEHICLE_WORKORDER_FILTER_OPTION.ON_WORKORDER,
            label: window.t('onWorkorder'),
        },
        {
            value: VEHICLE_WORKORDER_FILTER_OPTION.NOT_ON_WORKORDER,
            label: window.t('notOnWorkorder'),
        },
        {
            value: VEHICLE_WORKORDER_FILTER_OPTION.BOTH,
            label: window.t('both'),
        },
    ];
}

export const renderChipsColor = (value) => {
    let textColor = '';
    if (get(value, 'overdue')) {
        textColor = 'red';
    } else if (get(value, 'dueSoon')) {
        textColor = 'yellow';
    } else {
        textColor = 'green';
    }
    return textColor;
};

export const renderChips = (value) => {
    return (
        <a
            onClick={stopPropagation}
            key={value.id}
            href={`/dashboard/reminders/vehiclerenewals#vehicleId=${value.vehicleId}`}
            target="_blank"
        >
            <FxTag
                style={{
                    fontSize: '10px',
                    borderRadius: '20px',
                    marginLeft: '5px',
                    marginRight: '0px',
                }}
                color={renderChipsColor(value)}
            >
                {capitalize(get(value, 'vehicleRenewalType.name'))}
            </FxTag>
        </a>
    );
};

export const mappedVehicleReminderDropDown = (value) => {
    return map(value, (el, index) => {
        return {
            key: index,
            label: renderChips(el),
        };
    });
};

export function renderEvVehicleRealtimeCardDetails(liveVehicle, moreConfig = {}) {
    if (!isElectricVehicle(liveVehicle, moreConfig)) {
        return null;
    }
    const batteryPercent = getEVVehicleBatteryLevelPercentage(liveVehicle);
    const isCharging = isEvBatteryCharging(liveVehicle);
    const { batteryIcon, textColor } = isCharging
        ? getBatteryChargingIconAndText(batteryPercent)
        : getBatteryIconAndText(batteryPercent);
    const batteryKmRange = getEvVehicleBatteryKmRange(liveVehicle, moreConfig);
    const evVoltage = getEvVehicleVoltage(liveVehicle, moreConfig);
    const evGearState = getEvVehicleGearState(liveVehicle, moreConfig);

    const isBatteryPercAvailable = batteryPercent !== null;
    const isEvVoltageAvailable = evVoltage != null;
    const vehicleCurrStatus = get(liveVehicle, 'currentStatus');
    const showFastCharging = isFastCharging(liveVehicle);
    const chargingTimeStamp = get(liveVehicle, 'otherAttributes.timeToFullCharge');
    const timeToFullCharge = getTimeDiffHours(parseInt(chargingTimeStamp), false);

    return (
        <div
            style={{
                borderRadius: 4,
                backgroundColor: '#F3F8F3',
                padding: 2,
                display: 'inline-block',
                position: 'relative',
            }}
        >
            {showFastCharging && (
                <span
                    title={window.t('fastCharging')}
                    style={{
                        borderTopLeftRadius: 3,
                        borderBottomLeftRadius: 3,
                        backgroundColor: COLOR_WARNING,
                        display: 'inline-flex',
                        width: '1.4rem',
                        textAlign: 'center',
                        marginRight: 5,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <FxThunderboltOutlined style={{ color: COLOR_WHITE }} />
                </span>
            )}
            <span
                className={`font-weight-bold mr-1`}
                style={{
                    color: '#107C10',
                    marginLeft: showFastCharging ? '1.6rem' : undefined,
                }}
            >
                EV
            </span>
            {includes(['PARKED', 'RUNNING', 'IDLE'], vehicleCurrStatus) && (
                <span className={`mr-1`} style={{ fontWeight: 500 }}>
                    {isBatteryPercAvailable && (
                        <img style={{ marginBottom: 3 }} src={batteryIcon} aria-hidden="true" alt="" />
                    )}

                    {isBatteryPercAvailable && <span> {round(batteryPercent)}% </span>}

                    {isBatteryPercAvailable && !!round(batteryKmRange, 2) && (
                        <span>
                            {getSepSlant()} <span> {round(batteryKmRange, 2)}km </span>
                        </span>
                    )}
                    {chargingTimeStamp > 0 && (
                        <span>
                            {getSep()} <span> Time to Charge: {timeToFullCharge} </span>
                        </span>
                    )}

                    {isEvVoltageAvailable && (
                        <span>
                            {' '}
                            {isBatteryPercAvailable && getSep()} <i className="fa fa-bolt" aria-hidden="true" />{' '}
                            {evVoltage}V
                        </span>
                    )}

                    {evGearState !== null && (
                        <span>
                            {(isEvVoltageAvailable || isBatteryPercAvailable) && getSep()}{' '}
                            <span className={'text-muted font-weight-normal'}>{window.t('gearState')}:</span>{' '}
                            {evGearState}
                        </span>
                    )}
                </span>
            )}
        </div>
    );
}

export function getParkingStatusDurationOptions(isNewApi = false) {
    const minText = window.t('min');
    if (isNewApi) {
        return [
            {
                value: 'ZERO_TO_TEN',
                label: `0-10 ${minText}`,
            },
            {
                value: 'TEN_TO_THIRTY',
                label: `10-30 ${minText}`,
            },
            {
                value: 'THIRTY_TO_SIXTY',
                label: `30-60 ${minText}`,
            },
            {
                value: 'SIXTY_TO_HUNDRED_TWENTY',
                label: `60-120 ${minText}`,
            },
            {
                value: 'GREATER_THAN_HUNDRED_TWENTY',
                label: `>120 ${minText}`,
            },
        ];
    }
    return [
        {
            value: '0-10',
            label: `0-10 ${minText}`,
        },
        {
            value: '10-30',
            label: `10-30 ${minText}`,
        },
        {
            value: '30-60',
            label: `30-60 ${minText}`,
        },
        {
            value: '60-120',
            label: `60-120 ${minText}`,
        },
        {
            value: '120-null',
            label: `>120 ${minText}`,
        },
    ];
}

export function isVehicleInSelectedVehicleParkedDurationRange(
    parkingStatusDurationFilter,
    liveVehicle,
    moreConfig = {}
) {
    const { currentStatus } = liveVehicle;
    if (currentStatus !== VEHICLE_STATUS.PARKED) {
        return false;
    }
    const { lastStatusTimeAsMinutes } = moreConfig;
    const filterRangeArr = split(parkingStatusDurationFilter, '-');
    const r1 = toSafeInteger(filterRangeArr[0]);
    const r2 = toSafeInteger(filterRangeArr[1]);
    if (filterRangeArr[1] === 'null') {
        return lastStatusTimeAsMinutes >= r1;
    }
    if (filterRangeArr[0] === 'null') {
        return lastStatusTimeAsMinutes <= r2;
    }
    return lastStatusTimeAsMinutes >= r1 && lastStatusTimeAsMinutes <= r2;
}

export function getOpenIssuesCountRealtime(openIssuesCount) {
    return reduce(
        openIssuesCount,
        (acc, item) => {
            return acc + item.value;
        },
        0
    );
}

export const getDeviceIconMapping = (deviceId, deviceType) => {
    switch (getDeviceTypeFromDeviceId(deviceId, deviceType)?.type) {
        case DEVICE_CATEGORY.OBD.id:
        case DEVICE_CATEGORY.ASSET_TRACKER.id:
        case DEVICE_CATEGORY.AIS140.id:
            return { url: '/public/img/devices/obd.svg', label: 'OBD' };
        case DEVICE_CATEGORY.FLEETX_INSTALLED.id:
            return { url: '/public/img/devices/wiredIcon.svg', label: 'Wired' };
        case DEVICE_TYPE.SIM.type:
            return { url: '/public/img/devices/simIcon.svg', label: 'SIM' };
        case DEVICE_CATEGORY.API.name:
            return { url: '/public/img/devices/apiIcon.svg', label: 'API' };
        case DEVICE_CATEGORY.APP.name:
            return { url: '/public/img/devices/app.svg', label: 'APP' };
        default:
            return '';
    }
};

export function isDeviceTypeIconVisibleOnRealTimeCard() {
    return getFlagValueFromAccountConfig('showDeviceTypeIconsOnRealTimeCard');
}

export function usePaginationInLiveAPIAtRealtime() {
    return getFlagValueFromAccountConfig('usePaginatedLiveAPIAtRealTime');
}

export const getInitialLoadedStateFilter = () => {
    if ([12985, 13806].includes(get(window.FLEETX_LOGGED_IN_USER, 'accountId'))) {
        return 'ONJOB';
    }
};

export function hideTagCountInRealtime() {
    return getFlagValueFromAccountConfig('hideVehicleCountInTagFilterInRealtime');
}

export function isRealtimeVehicleRowClickable() {
    const roleId = get(window, 'FLEETX_LOGGED_IN_USER.role.id', '');
    return true;
}

export function isRealtimeVehicleShareEnabled() {
    const roleId = get(window, 'FLEETX_LOGGED_IN_USER.role.id', '');
    return !includes([111689], roleId);
}

export function showRealtimeVehicleRunningKm() {
    const roleId = get(window, 'FLEETX_LOGGED_IN_USER.role.id', '');
    return !includes([111689], roleId);
}
