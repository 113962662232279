import LoginReducer from './login/reducer_login';
import SettingsReducer from './dashboard/reducer_settings';
import VehicleReducer from './dashboard/reducer_vehicles';
import RealtimeReducer from './dashboard/reducer_realtime';
import { reducer as formReducer } from 'redux-form';
import UserReducer from './dashboard/reducer_users';
import AlertReducer from './dashboard/reducer_alerts';
import TripReducer from './dashboard/reducer_trips';
import OverviewReducer from './dashboard/reducer_overview';
import DtcsReducer from './dashboard/reducer_dtcs';
import GeofenceReducer from './dashboard/reducer_geofence';
import TimefenceReducer from './dashboard/reducer_timefence';
import GroupReducer from './dashboard/reducer_group';
import LocalReducer from './dashboard/reducer_local';
import AdminReducer from './dashboard/reducer_admin';
import AccountsReducer from './dashboard/reducer_accounts';
import AlertsSettingsReducer from './dashboard/reducer_alerts_settings';
import { FETCH_LOGIN, FORGOT_PASSWORD, LOGOUT_USER, RESET_PASSWORD } from '../actions/login/login_action';
import { get, map, truncate, set, isEmpty, includes, split, last } from 'lodash';
import { sendAppAlertToSlack } from '../utils/analytics_utils';
import { FETCH_LATEST_TRIP_BY_VEHICLE } from '../actions/dashboard/trips_action';
import ServiceTasksReducer from './dashboard/easy/reducer_service_tasks';
import EntityRenewalRemindersReducer from './dashboard/easy/reducer_entity_renewal_reminders';
import VehicleRenewalTypeReducer from './dashboard/easy/reducer_vehicle_renewal_types';
import ContactRenewalTypeReducer from './dashboard/easy/reducer_contact_renewal_types';
import ServiceReminderReducer from './dashboard/easy/reducer_service_reminders';
import VehicleRenewalReminderReducer from './dashboard/easy/reducer_vehicle_renewal_reminder';
import ContactRenewalReminderReducer from './dashboard/easy/reducer_contact_renewal_reminder';
import UserImagesReducer from './dashboard/reducer_user_images';
import VehicleImagesReducer from './dashboard/reducer_vehicle_images';
import VehicleCloneReducer from './dashboard/reducer_vehicle_clone';
import DeviceReducer from './dashboard/reducer_device';
import VendorReducer from './dashboard/reducer_vendors';
import TransporterReducer from './dashboard/reducer_transporters';
import FuelEntriesReducer from './dashboard/easy/reducer_fuel_entries';
import AddressBookReducer from './dashboard/route_dispatch/reducer_address_book';
import FeatureReducer from './dashboard/reducer_feature';
import StationaryReducer from './dashboard/stationary/reducer';
import BranchReducer from './dashboard/branch/reducer';
import MaterialStockReducer from './dashboard/material_stock/reducer';
import VehicleDocumentsReducer from './dashboard/reducer_vehicle_documents';
import JobsReducer from './dashboard/route_dispatch/reducer_jobs';
import CustomFieldsReducer from './dashboard/reducer_custom_fields';
import RoutesReducer from './dashboard/route_dispatch/reducer_routes';
import IssuesReducer from './dashboard/reducer_issues';
import ServiceEntriesReducer from './dashboard/easy/reducer_service_entry';
import CustomersReducer from './dashboard/finances/reducer_customers';
import DutiesReducers from './dashboard/finances/reducer_duties';
import InvoicesReducers from './dashboard/finances/reducer_invoices';
import { SWITCH_EXIT } from '../actions/dashboard/admin_actions';
import CommentsReducer from './dashboard/reducer_comments';
import TripSheetReducer from './dashboard/finances/reducer_trip_sheet';
import TransactionReducer from './dashboard/finances/reducer_transaction';
import JobBookingReducer from './dashboard/route_dispatch/reducer_job_booking';
import PaymentsReducer from './dashboard/finances/reducer_payments';
import TagsReducer from './dashboard/reducer_tags';
import RolesReducer from './dashboard/reducer_roles';
import ModulesReducer from './dashboard/reducer_modules';
import ConfigReducer from './dashboard/reducer_config';
import UserTypesReducer from './dashboard/reducer_user_types';
import IncomingTransportersReducer from './dashboard/reducer_transporters_incoming';
import EwayBillReducer from './dashboard/reducerEwayBill';
import GSTRateReducer from './dashboard/reducerGSTRate';
import notificationSummaryReducer from './dashboard/reducer_notification';

import {
    FETCH_ANALYTICS_LIVE,
    FETCH_ANALYTICS_SHARE_VEHICLE,
    FETCH_HISTORICAL_VEHICLE_LOCATION,
    FETCH_LATEST_TRIP_VEHICLE_NUMBER_OM_AUTH,
} from '../actions/dashboard/realtime_action';
import OperationsReducer from './dashboard/internal_tools/reducer_operations';
import { ADD_NEW_VEHICLE, DELETE_VEHICLE, REMOVE_DRIVER_ASSIGNMENT } from '../actions/dashboard/vehicles_action';
import { UPDATE_PASSWORD } from '../actions/dashboard/setting_action';
import { VALIDATE_STATIONARY } from '../actions/dashboard/stationary/actions';
import TyresReducer from './dashboard/reducer_tyres';
import BillsReducer from './dashboard/reducer_bills';
import { CREATE_TAG, DELETE_TAG } from '../actions/dashboard/tags_action';
import { UPDATE_JOB, FETCH_VEHICLES_ON_JOB } from '../actions/dashboard/route_dispatch/jobs_action';
import { FETCH_INVOICES } from '../actions/dashboard/finances/invoice_action';
import { CREATE_ACCOUNT_V2, FETCH_ALL_ACCOUNTS_FOR_USER } from '../actions/dashboard/accounts_actions';
import { ADD_USER, DELETE_USER } from '../actions/dashboard/users_action';
import storage from 'utils/storage_utils';
import { CREATE_JOB, END_JOB, FETCH_JOB_DEVIATIONS } from '../actions/dashboard/route_dispatch/jobs_action';
import { ADD_TRIP_SHEET } from 'actions/dashboard/finances/trip_sheet_action';
import { isEnvLive, getShellSignoutUrl } from '../constant';
import { FLEETX_ACCOUNTS } from 'utils/account_utils';
import { CREATE_ADDRESS, DELETE_ADDRESS } from 'actions/dashboard/route_dispatch/address_book_action';
import { CREATE_GROUP } from 'actions/dashboard/group_action';
import { CREATE_TECHNICIAN_DUTIES } from 'actions/dashboard/internal_tools/operations_action';
import { UPDATE_USER } from 'actions/dashboard/users_action';
import { ADD_SIM_ASSET_FOR_TRACKING } from 'actions/dashboard/device_action';
import { DELETE_ROUTE, UPDATE_ROUTE } from '../actions/dashboard/route_dispatch/routes_action';
import {
    prepareAppAlertDataAndSaveInBigquery,
    prepareAppAlertEmptyResponseDataAndSaveInBigquery,
    logSwitchedUserAPICalls,
} from '../utils/logging_utils';

import CommodityReducer from './dashboard/reducer_comodity';
import MaterialReducer from './dashboard/reducer_material';
import StoreReducer from './dashboard/reducer_store';
import IndentReducer from './dashboard/reducer_indent';

import IssuesInternalReducer from './dashboard/internal_tools/reducer_issues';
import CommentsInternalReducer from './dashboard/internal_tools/reducer_comments';
import TagsInternalReducer from './dashboard/internal_tools/reducer_tags';
import { FETCH_INTERNAL_ISSUE_BY_ID } from '../actions/dashboard/internal_tools/issues_actions';
import { getFormDataAsSimpleString as getParsedData } from '../utils/data_parser_utils';
import PartsInventoryReducer from './dashboard/reducer_parts_inventory';
import ProductsReducer from './dashboard/reducer_product';

import RateChartsReducer from './dashboard/reducerRateCharts';
import ConsignmentsReducer from './dashboard/reducerConsignments';
import ShiftwiseDriver from './dashboard/reducerShiftwiseDriver';
import TMSAnalyticsReducer from './dashboard/reducerTMSAnalytics';
import TMSConsignmentsReducer from './dashboard/reducerTmsConsignments';
import TMSUnitsReducer from './dashboard/reducerTmsUnits';
import ContractsReducer from './dashboard/reducerContracts';
import ReportTemplatesReducer from './dashboard/reducerReportTemplates';
import UtilsReducer from './dashboard/reducer_utils';

import LoansReducer from './dashboard/reducer_vehicle_loans_emi';

import { CREATE_SERVICE_TASK } from 'actions/dashboard/easy/service_tasks_action';
import { isShellAccount } from '../../white_label_constants';
import { getMomentTime } from 'utils/date_utils';
import { SET_LOCAL_GEOCODED_LOCATIONS } from '../actions/dashboard/local_action';
import {
    partStockReducer,
    completePartInventoryReducer,
    fetchPartListReducer,
    completePartInventoryByIdReducer,
    partStoreHistoryByIdReducer,
    partIssuanceListReducer,
} from './dashboard/part_stock_inventory';
import { logUnauthorisedStoppedCallDetails } from 'utils/permissionUtils/utils';

export function getLoggingDetails(action, state, includeDetails, trimKey = []) {
    const error = get(action, 'payload.response.data.error', get(action, 'payload.response.data.data'));
    const error_description = get(
        action,
        'payload.response.data.error_description',
        get(action, 'payload.response.data.message')
    );
    let s = '';
    s += 'type              : ' + get(action, 'type') + '\n';
    s += 'method            : ' + get(action, 'payload.config.method') + '\n';
    s += 'url               : ' + get(action, 'payload.config.url') + '\n';
    s += 'params            : ' + JSON.stringify(get(action, 'payload.config.params')) + '\n';
    s += 'error             : ' + error + '\n';
    s += 'error_description : ' + error_description + '\n';
    s += 'status            : ' + get(action, 'payload.response.status') + '\n';
    s += 'statusText        : ' + get(action, 'payload.response.statusText') + '\n';
    s += 'requestId        : ' + get(action, 'payload.response.headers.requestid', '') + '\n';
    s += 'url               : ' + get(window, 'location.href') + '\n';
    s +=
        'Account           : ' +
        get(state, 'settings.loggedInUser.email') +
        ' (accountId: ' +
        get(state, 'settings.loggedInUser.accountId') +
        ') (userId: ' +
        get(state, 'settings.loggedInUser.id') +
        ') (switchedUser: ' +
        get(state, 'login.isSwitchedUser') +
        ' ' +
        get(state, 'login.switchedUserOriginalEmailId') +
        ')\n';
    if (includeDetails) {
        s += 'data              : ' + getParsedData(get(action, 'payload.config.data'), trimKey) + '\n';
    }
    return s;
}

function getCombinedState(state = {}, action) {
    return {
        login: LoginReducer(state.login, action, state),
        settings: SettingsReducer(state.settings, action, state),
        users: UserReducer(state.users, action, state),
        form: formReducer(state.form, action, state),
        vehicles: VehicleReducer(state.vehicles, action, state),
        realtime: RealtimeReducer(state.realtime, action, state),
        alerts: AlertReducer(state.alerts, action, state),
        trips: TripReducer(state.trips, action, state),
        dtcs: DtcsReducer(state.dtcs, action, state),
        accountOverview: OverviewReducer(state.accountOverview, action, state),
        geofences: GeofenceReducer(state.geofences, action, state),
        timefences: TimefenceReducer(state.timefences, action, state),
        groups: GroupReducer(state.groups, action, state),
        alertSettings: AlertsSettingsReducer(state.alertSettings, action, state),
        local: LocalReducer(state.local, action, state),
        servicetasks: ServiceTasksReducer(state.servicetasks, action, state),
        entityRenewalReminders: EntityRenewalRemindersReducer(state.entityRenewalReminders, action, state),
        vehicleRenewalTypes: VehicleRenewalTypeReducer(state.vehicleRenewalTypes, action, state),
        contactRenewalTypes: ContactRenewalTypeReducer(state.contactRenewalTypes, action, state),
        serviceReminders: ServiceReminderReducer(state.serviceReminders, action, state),
        vehicleRenewalReminders: VehicleRenewalReminderReducer(state.vehicleRenewalReminders, action, state),
        contactRenewalReminders: ContactRenewalReminderReducer(state.contactRenewalReminders, action, state),
        admin: AdminReducer(state.admin, action, state),
        accounts: AccountsReducer(state.accounts, action, state),
        userImages: UserImagesReducer(state.userImages, action, state),
        vehicleImages: VehicleImagesReducer(state.vehicleImages, action, state),
        clone: VehicleCloneReducer(state.clones, action, state),
        devices: DeviceReducer(state.devices, action, state),
        vendors: VendorReducer(state.vendors, action, state),
        transporters: TransporterReducer(state.transporters, action, state),
        fuelEntries: FuelEntriesReducer(state.fuelEntries, action, state),
        addressBook: AddressBookReducer(state.addressBook, action, state),
        feature: FeatureReducer(state.feature, action),
        stationary: StationaryReducer(state.stationary, action, state),
        branch: BranchReducer(state.branch, action, state),
        materialStock: MaterialStockReducer(state.materialStock, action, state),
        vehicleDocuments: VehicleDocumentsReducer(state.vehicleDocuments, action, state),
        jobs: JobsReducer(state.jobs, action, state),
        customFields: CustomFieldsReducer(state.customFields, action, state),
        routes: RoutesReducer(state.routes, action, state),
        issues: IssuesReducer(state.issues, action, state),
        serviceEntries: ServiceEntriesReducer(state.serviceEntries, action, state),
        customers: CustomersReducer(state.customers, action, state),
        duties: DutiesReducers(state.duties, action, state),
        invoices: InvoicesReducers(state.invoices, action, state),
        comments: CommentsReducer(state.comments, action, state),
        tripSheet: TripSheetReducer(state.tripSheet, action, state),
        transaction: TransactionReducer(state.transaction, action, state),
        jobBooking: JobBookingReducer(state.jobBooking, action, state),
        operations: OperationsReducer(state.operations, action, state),
        payments: PaymentsReducer(state.payments, action, state),
        tyres: TyresReducer(state.tyres, action, state),
        bills: BillsReducer(state.bills, action, state),
        tags: TagsReducer(state.tags, action, state),
        roles: RolesReducer(state.roles, action, state),
        modules: ModulesReducer(state.modules, action, state),
        config: ConfigReducer(state.config, action, state),
        incomingTransporterVehicles: IncomingTransportersReducer(state.incomingTransporterVehicles, action, state),
        commodity: CommodityReducer(state.commodity, action, state),
        material: MaterialReducer(state.material, action, state),
        userTypesList: UserTypesReducer(state.userTypesList, action, state),
        indentsList: IndentReducer(state.indentsList, action, state),
        issuesInternal: IssuesInternalReducer(state.issuesInternal, action, state),
        commentsInternal: CommentsInternalReducer(state.commentsInternal, action, state),
        tagsInternal: TagsInternalReducer(state.tagsInternal, action, state),
        partsInventory: PartsInventoryReducer(state.partsInventory, action, state),
        partStockSummary: partStockReducer(state.partStockSummary, action, state),
        completePartInventory: completePartInventoryReducer(state.completePartInventory, action, state),
        partIssuanceList: partIssuanceListReducer(state.partIssuanceList, action, state),
        partListData: fetchPartListReducer(state.partListData, action, state),
        partEntriesList: completePartInventoryByIdReducer(state.partEntriesList, action, state),
        partStoreHistoryById: partStoreHistoryByIdReducer(state.partStoreHistoryById, action, state),
        products: ProductsReducer(state.products, action, state),
        rateCharts: RateChartsReducer(state.rateCharts, action, state),
        consignments: ConsignmentsReducer(state.consignments, action, state),
        shiftwiseDriver: ShiftwiseDriver(state.shiftwiseDriver, action, state),
        tmsAnalytics: TMSAnalyticsReducer(state.tmsAnalytics, action, state),
        tmsConsignments: TMSConsignmentsReducer(state.tmsConsignments, action, state),
        tmsUnits: TMSUnitsReducer(state.tmsUnits, action, state),
        contracts: ContractsReducer(state.contracts, action, state),
        ewayBill: EwayBillReducer(state.ewayBill, action, state),
        gstRate: GSTRateReducer(state.gstRate, action, state),
        reportTemplates: ReportTemplatesReducer(state.reportTemplates, action, state),
        utils: UtilsReducer(state.utils, action, state),
        store: StoreReducer(state.store, action, state),
        notificationData: notificationSummaryReducer(state.notificationData, action, state),
        loans: LoansReducer(state.loans, action, state),
    };
}

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        state = undefined;
    }
    const switchedUserOriginalEmailId = get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalEmailId');
    const isSwitchedFleetxUser =
        switchedUserOriginalEmailId && last(split(switchedUserOriginalEmailId, '@')) === 'fleetx.io';
    if (
        !isSwitchedFleetxUser &&
        LOGOUT_USER !== action.type &&
        isShellAccount() &&
        window.SESSION_EXPIRE_TIME < getMomentTime().valueOf()
    ) {
        state = undefined;
        window.open(getShellSignoutUrl(), '_self');
    }
    if (
        isShellAccount() &&
        !includes([FETCH_ANALYTICS_LIVE, FETCH_VEHICLES_ON_JOB, SET_LOCAL_GEOCODED_LOCATIONS], action.type)
    ) {
        window.SESSION_EXPIRE_TIME = getMomentTime().add(10, 'm').valueOf();
    }
    // if (
    //     action.error &&
    //     !get(action.payload, 'response.status') &&
    //     get(action.payload, 'message') &&
    //     get(action.payload, 'stack')
    // ) {
    //     sendAppAlertToSlack(
    //         'ERR_FAILED: EMPTY RESPONSE \n```' +
    //             'Message: ' +
    //             get(action.payload, 'message') +
    //             `\n` +
    //             'Stack: ' +
    //             get(action.payload, 'stack') +
    //             `\n` +
    //             getLoggingDetails(action, state) +
    //             '```',
    //         '#empty-responses'
    //     );
    //     prepareAppAlertEmptyResponseDataAndSaveInBigquery(action, state);
    // }
    const statusCode = get(action.payload, 'response.status', 200);
    const detailsChannel = isEnvLive() ? '#alerts-web-details' : '#alerts-development';
    const internalChannel =
        get(state, 'settings.loggedInUser.accountId') == FLEETX_ACCOUNTS.FLEETX ? '#alerts-web-internal' : null;
    const error_description = get(
        action,
        'payload.response.data.error_description',
        get(action, 'payload.response.data.message', '')
    );
    const isFleetxDummyActionResponse = get(action, 'payload.isFleetxDummyActionResponse', false);

    switch (true) {
        case statusCode === 400 &&
            (action.type === UPDATE_PASSWORD || action.type === CREATE_ACCOUNT_V2 || action.type === RESET_PASSWORD) &&
            (!!error_description.match('Password must contain at') ||
                !!error_description.match('There is an existing account with that') ||
                !!error_description.match('incorrect current password')):
            //DO nothing
            break;
        case statusCode === 400 &&
            (!!error_description.match(/exist/gi) ||
                !!error_description.match(/delete/gi) ||
                !!error_description.match(/please/gi) ||
                !!error_description.match(/duplicate/gi) ||
                (action.type === UPDATE_JOB &&
                    !!error_description.match(
                        'The expected arrival and departure time for all consecutive route points should be non decreasing'
                    )) ||
                action.type === VALIDATE_STATIONARY):
            //DO nothing
            break;
        case statusCode === 400 &&
            action.type !== FETCH_LOGIN &&
            !(
                action.type === FETCH_LATEST_TRIP_VEHICLE_NUMBER_OM_AUTH &&
                !!get(action, 'payload.config.url', '').match(/google/)
            ):
            sendAppAlertToSlack('400 HTTP STATUS CODE LOGGED \n```' + getLoggingDetails(action, state) + '```');
            sendAppAlertToSlack(
                '400 HTTP STATUS CODE LOGGED \n```' + getLoggingDetails(action, state, true) + '```',
                detailsChannel
            );
            prepareAppAlertDataAndSaveInBigquery(action, state);
            break;
        case statusCode === 401 && action.type === SWITCH_EXIT:
            storage.clear();
            return {};
        case statusCode === 401 && action.type === FETCH_LOGIN:
            sendAppAlertToSlack(
                '401 HTTP STATUS CODE LOGGED ' + '\n```' + getLoggingDetails(action, state) + '```',
                '#alerts-logout'
            );
            break;
        case statusCode === 401 && action.type !== FETCH_LOGIN:
            sendAppAlertToSlack(
                'LOGGING OUT USER...\n401 HTTP STATUS CODE LOGGED ' +
                    '\n```' +
                    getLoggingDetails(action, state) +
                    '```',
                '#alerts-logout'
            );
            storage.clear();
            window.location = '/';
            return {};
        case statusCode === 403 && action.type === FETCH_ALL_ACCOUNTS_FOR_USER:
            //DO nothing
            break;
        case statusCode === 403 && (action.type === CREATE_TAG || action.type === FETCH_INVOICES):
            //action.type === FETCH_TAGS || action.type === FETCH_INVOICES // only call on crud ops.
            sendAppAlertToSlack(
                '403 HTTP STATUS CODE LOGGED ' + '\n```' + getLoggingDetails(action, state) + '```',
                '#alerts-logout'
            );
            // FORCED LOGOUT
            storage.clear();
            window.location = '/';
            return {};
        case statusCode === 403:
            sendAppAlertToSlack(
                statusCode +
                    ' HTTP STATUS CODE LOGGED\n```' +
                    getLoggingDetails(action, state) +
                    '\n' +
                    'accesstoken            : ' +
                    get(state, 'login.data.access_token', '-') +
                    '\n' +
                    'roleName            : ' +
                    get(state, 'settings.loggedInUser.role.name', '-') +
                    '\n' +
                    '```',
                internalChannel
            );
            sendAppAlertToSlack(
                statusCode +
                    ' HTTP STATUS CODE LOGGED\n```' +
                    getLoggingDetails(action, state) +
                    '\n' +
                    JSON.stringify(get(state, 'login.data.scope', '-')) +
                    '\n' +
                    'accesstoken            : ' +
                    get(state, 'login.data.access_token', '-') +
                    '\n' +
                    'roleName            : ' +
                    get(state, 'settings.loggedInUser.role.name', '-') +
                    '\n' +
                    '```',
                internalChannel ? internalChannel : detailsChannel
            );
            prepareAppAlertDataAndSaveInBigquery(action, state);
            break;
        case (statusCode === 404 &&
            ((action.type === FETCH_LATEST_TRIP_BY_VEHICLE && error_description === 'no latest data exists') ||
                (action.type === FORGOT_PASSWORD && error_description === 'User Not Found') ||
                (action.type === ADD_SIM_ASSET_FOR_TRACKING &&
                    !!error_description.match('Device not found with current operator')) ||
                (action.type === FETCH_HISTORICAL_VEHICLE_LOCATION &&
                    error_description === 'no historical location found'))) ||
            (action.type === FETCH_INTERNAL_ISSUE_BY_ID && error_description === 'Requested Entity Not Found'):
            //DO nothing
            break;
        case statusCode === 404 &&
            action.type === FETCH_ANALYTICS_SHARE_VEHICLE &&
            (error_description === 'This link is either inactive or not valid in this date range' ||
                !!error_description.match('This link will be active')):
            //DO nothing
            break;

        case statusCode === 404 && action.type === REMOVE_DRIVER_ASSIGNMENT:
            //DO nothing
            break;

        case statusCode === 406:
            sendAppAlertToSlack(
                statusCode +
                    ' HTTP STATUS CODE LOGGED\n```' +
                    getLoggingDetails(action, state) +
                    '\n' +
                    JSON.stringify(action) +
                    '```',
                '#alerts-406'
            );
            break;
        case statusCode === 409 &&
            ((action.type === ADD_USER && error_description === 'There is an account with that email or username') ||
                (action.type === UPDATE_USER &&
                    !!error_description.match('There is an existing account with that email or username')) ||
                (action.type === DELETE_USER && !!error_description.match('user is associated with job')) ||
                (action.type === CREATE_GROUP && !!error_description.match('Duplicate entry')) ||
                (action.type === CREATE_SERVICE_TASK &&
                    !!error_description.match('There is alreay a service task exist')) ||
                (action.type === CREATE_JOB && !!error_description.match('Duplicate entry')) ||
                (action.type === FETCH_JOB_DEVIATIONS && !!error_description.match('Given job id is invalid')) ||
                (action.type === DELETE_ROUTE && !!error_description.match('routes are associated')) ||
                (action.type === DELETE_ADDRESS && !!error_description.match('address book is associated')) ||
                (action.type === CREATE_ADDRESS && !!error_description.match('There is a address exist with name'))):
            //DO nothing
            break;
        case statusCode === 429:
            sendAppAlertToSlack(
                '429 HTTP STATUS CODE LOGGED ' +
                    '\n```' +
                    getLoggingDetails(action, state) +
                    'accesstoken            : ' +
                    get(state, 'login.data.access_token') +
                    '\n' +
                    '```'
            );
            prepareAppAlertDataAndSaveInBigquery(action, state);
            break;
        case statusCode === 500 &&
            ((action.type === ADD_NEW_VEHICLE && !!error_description.match(/vehicle exist/)) ||
                (action.type === CREATE_TAG && !!error_description.match(/there is an existing job running/))):
            //DO nothing
            break;
        case statusCode === 500 && action.type === END_JOB:
            sendAppAlertToSlack(
                '500 HTTP STATUS CODE LOGGED \n```' + getLoggingDetails(action, state, true) + '```',
                detailsChannel
            );

        case statusCode > 401:
            if (get(state, 'settings.loggedInUser.accountId') == FLEETX_ACCOUNTS.FLEETX) {
                sendAppAlertToSlack(
                    statusCode + ' HTTP STATUS CODE LOGGED\n```' + getLoggingDetails(action, state) + '```',
                    '#alerts-web-internal'
                );
            } else {
                sendAppAlertToSlack(
                    statusCode + ' HTTP STATUS CODE LOGGED\n```' + getLoggingDetails(action, state) + '```'
                );
                sendAppAlertToSlack(
                    statusCode + ' HTTP STATUS CODE LOGGED\n```' + getLoggingDetails(action, state, true) + '```',
                    detailsChannel
                );
                prepareAppAlertDataAndSaveInBigquery(action, state);
            }

            break;
        case isFleetxDummyActionResponse === true:
            logUnauthorisedStoppedCallDetails(action, state);
            break;
        default:
            break;
    }

    logSwitchedUserAPICalls(action, state);

    // const combinedState = appReducer(state, action);
    const combinedState = getCombinedState(state, action);
    return combinedState;
};

export default rootReducer;
