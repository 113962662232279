import axios from 'axios';
import { ROOT_API_URL, parseQueryParams, ROOT_NODE_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../../constant';
import { queryString } from '../../../utils/string_utils';
import { isViewRoutesAllowedForUser } from '../../../components/dashboard/roles/permission_utils';
import { getMomentTime } from 'utils/date_utils';

export const CREATE_ROUTE = 'create_route';
export const CREATE_ROUTE_SHARE = 'create_route_share';
export const FETCH_ROUTES = 'fetch_routes';
export const FETCH_ROUTE = 'fetch_route';
export const FETCH_ROUTE_NAME = 'fetch_route_name';
export const ROUTE_REPORT = 'route_report';
export const UPDATE_ROUTE = 'update_route';
export const DELETE_ROUTE = 'delete_route';
export const FETCH_ROUTE_ANALYTICS = 'fetch_route_analytics';
export const FETCH_ROUTE_TOLLS = 'fetch_route_tolls';
export const SEARCH_ROUTES_BY_ADDRESS_BOOK = 'search_routes_by_address_book';
export const ROUTE_DETAILS_BY_SHARE_ID = 'route_details_by_share_id';
export const UPDATE_RISK_ADDRESS_REPORT = 'update_risk_address_report';
export const SEARCH_ROUTES = 'SEARCH_ROUTES';
export const FETCH_ROUTES_MINI_LIST = 'FETCH_ROUTES_MINI_LIST';
export const FETCH_ROUTES_BY_MOVEMENT_TYPE = 'FETCH_ROUTES_BY_MOVEMENT_TYPE';
export const FETCH_ROUTE_BUDGET_REPORT = 'FETCH_ROUTE_BUDGET_REPORT';

const CancelToken = axios.CancelToken;

export function createRoute(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}routes/`, values, config);
    return {
        type: CREATE_ROUTE,
        promise: request,
    };
}

export function updateRoute(accesstoken, values, updateRunningJob = false, updateRunningJobIds = null) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            updateRunningJob,
            updateRunningJobIds,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}routes/`, values, config);

    return {
        type: UPDATE_ROUTE,
        promise: request,
    };
}

export function fetchRoutes(
    accesstoken,
    groupId,
    branchPresentAtStartAndEndRoutePoint,
    originBranchId,
    destinationBranchId,
    branchPresentAtAllPoints,
    lane
) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewRoutesAllowedForUser(scope)) {
        return {
            type: FETCH_ROUTES,
            promise: Promise.resolve({}),
        };
    }
    // const groupQuery = groupId ? `?groupId=${groupId}` : '';

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            branchPresentAtStartAndEndRoutePoint,
            originBranchId,
            destinationBranchId,
            branchPresentAtAllPoints,
            lane,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}routes/`, config);

    return {
        type: FETCH_ROUTES,
        promise: request,
    };
}

export function fetchRoutesMiniList(
    accesstoken,
    groupId,
    branchPresentAtStartAndEndRoutePoint,
    originBranchId,
    destinationBranchId,
    branchPresentAtAllPoints,
    moreOptions = {}
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetchRoutes';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewRoutesAllowedForUser(scope)) {
        return {
            type: FETCH_ROUTES,
            promise: Promise.resolve({}),
        };
    }
    const { name } = moreOptions || {};
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            branchPresentAtStartAndEndRoutePoint,
            originBranchId,
            destinationBranchId,
            branchPresentAtAllPoints,
            name,
        }),
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}routes/autocomplete`, config);

    return {
        type: FETCH_ROUTES_MINI_LIST,
        promise: request,
    };
}

export function searchRoutes(accesstoken, params = {}) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};

    if (!isViewRoutesAllowedForUser(scope)) {
        return {
            type: FETCH_ROUTES,
            promise: Promise.resolve({}),
        };
    }
    const { groupId, vehicleId, addressBookId, routeId, size, page = 1, routeIds } = params;

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            vehicleIds: vehicleId ? `$${vehicleId}` : null,
            routeId,
            size,
            page: page - 1,
            addressBookId,
            routeIds,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}routes/search`, config);

    return {
        type: SEARCH_ROUTES,
        promise: request,
    };
}

export function fetchRoute(accesstoken, id, wayPoints = false) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            wayPoints,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}routes/${id}`, config);

    return {
        type: FETCH_ROUTE,
        promise: request,
    };
}

export function fetchRoutesByMovementType(accesstoken, sourceAddressId, isRoundTrip) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            sourceAddressId,
            isRoundTrip,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}routes/search-by-origin`, config);

    return {
        type: FETCH_ROUTES_BY_MOVEMENT_TYPE,
        promise: request,
    };
}

export function fetchRouteName(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}routes/name`, config);

    return {
        type: FETCH_ROUTE_NAME,
        promise: request,
    };
}

export function deleteRoute(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}routes/${id}`, config);
    return {
        type: DELETE_ROUTE,
        promise: request,
    };
}

export function fetchRouteAnalytics(
    accesstoken,
    groupId,
    from,
    to,
    onlyPoints = false,
    vehicleId,
    routeId,
    customerId,
    type
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_route_action_fetchRouteAnalytics';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    if (accesstoken) {
        const config = {
            headers: { 'Authorization': `Bearer ${accesstoken}` },
            cancelToken: new CancelToken(function executor(c) {
                window[cancelCallbackKey] = c;
            }),
        };
        const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
        let toQuery = to ? `&to=${getMomentTime(to).valueOf()}` : '';
        let onlyPointsQuery = onlyPoints ? `&onlyPoints=true` : '';
        const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
        const routeQuery = routeId ? `&routeId=${routeId}` : '';
        const customerQuery = customerId ? `&consignerId=${customerId}` : '';
        const typeQuery = type ? `&type=${type}` : '';

        const request = axios.get(
            `${ROOT_API_URL}dispatch/analytics?from=${getMomentTime(
                from
            ).valueOf()}${toQuery}${groupQuery}${onlyPointsQuery}${vehicleQuery}${routeQuery}${customerQuery}${typeQuery}`,
            config
        );

        return {
            type: FETCH_ROUTE_ANALYTICS,
            promise: request,
        };
    }
}

export function fetchRouteTolls(accesstoken, polyline, includeNearbyTolls = false) {
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const values = {
        polyline,
        includeNearbyTolls,
    };
    const request = axios.post(`${ROOT_API_URL}routes/tolls`, queryString.stringify(values), config);

    return {
        type: FETCH_ROUTE_TOLLS,
        promise: request,
    };
}

export function searchRoutesByAddressBooks(accesstoken, addressBookId1, addressBookId2) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_search_route_by_address_book';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const addressBookQuery = `addressBookId1=${addressBookId1}&addressBookId2=${addressBookId2}`;

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}routes/searchByAddressBook?${addressBookQuery}`, config);

    return {
        type: SEARCH_ROUTES_BY_ADDRESS_BOOK,
        promise: request,
    };
}

export function triggerRouteReport(accesstoken, options, downloadRouteReport) {
    downloadRouteReport(true);
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerRouteReport`, data, config);

    return {
        type: ROUTE_REPORT,
        promise: request,
    };
}

export function createRouteShare(accesstoken, routeId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };

    const data = {
        routeId,
    };

    const request = axios.post(`${ROOT_API_URL}routes/share`, data, config);

    return {
        type: CREATE_ROUTE_SHARE,
        promise: request,
    };
}

export function routeDetailsByShareId(shareId) {
    const request = axios.get(`${ROOT_API_URL}routes/share/${shareId}`);

    return {
        type: ROUTE_DETAILS_BY_SHARE_ID,
        promise: request,
    };
}

export function updateRiskAddressReport(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };

    const request = axios.put(`${ROOT_API_URL}routes/risky-address`, null, config);
    return {
        type: UPDATE_RISK_ADDRESS_REPORT,
        promise: request,
    };
}
export const fetchRouteBudgetReport = (accesstoken, params) => {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/job-budget`, config);

    return {
        type: FETCH_ROUTE_BUDGET_REPORT,
        promise: request,
    };
};
