import { handle } from 'redux-pack';
import {
    FETCH_ALERTS,
    FETCH_ALERTS_GRAPH,
    FETCH_ALERTS_GRAPH_BY_VEHICLE,
    FETCH_ALERTS_GRAPH_COUNT,
    FETCH_ALERTS_GRAPH_FREQUENCY,
    FETCH_ALERTS_REPORT,
    FETCH_ALERTS_SUMMARY,
    FETCH_FUEL_LEVEL_SUMMARY,
} from 'actions/dashboard/alerts_action';
import { get, map } from 'lodash';
import { FREQUENCY_GRAPH_TYPES } from 'components/dashboard/alerts/analytics/Utils';
import { FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME } from 'actions/dashboard/transporter_analytics_action';

const initialState = {
    isLoading: false,
    error: null,
    aggregations: [],
    isFinished: false,
    graphData: [],
    graphDataCount: {
        alarmCountGraph: [],
        heatMap: [],
        districtVsAlarmCountMap: {},
    },
    graphDataFrequency: {
        alarmFrequencyGraphTypeListMap: {
            [FREQUENCY_GRAPH_TYPES.VEHICLES]: [],
            [FREQUENCY_GRAPH_TYPES.DRIVERS]: [],
            [FREQUENCY_GRAPH_TYPES.GROUPS]: [],
            [FREQUENCY_GRAPH_TYPES.TRANSPORTERS]: [],
        },
    },
    graphDataByVehicle: [],
    isAlertsGraphLoading: false,
    isAlertsGraphByVehicleLoading: false,
    isAlertsLoading: false,
    groupedData: {
        aggregations: [],
        alarms: [],
        severityMap: new Map(),
    },
    isAlertsGroupedDataLoading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALERTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAlertsLoading: true,
                    alarms: [],
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isFinished: true }),
                failure: (prevState) => ({ ...prevState, error: payload, isAlertsLoading: false }),
                success: (prevState) => ({
                    ...prevState,
                    aggregations: get(payload, 'data.aggregations'),
                    alarms: get(payload, 'data.alarms'),
                    isAlertsLoading: false,
                }),
            });
        case FETCH_ALERTS_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAlertsGroupedDataLoading: true,
                    groupedDataStatus: 'pending',
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isFinished: true,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    error: payload,
                    isAlertsGroupedDataLoading: false,
                    groupedDataStatus: 'error',
                }),
                success: (prevState) => ({
                    ...prevState,
                    isAlertsGroupedDataLoading: false,
                    groupedDataStatus: 'success',
                    groupedData: get(payload, 'data'),
                }),
            });
        case FETCH_ALERTS_GRAPH:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAlertsGraphLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isAlertsGraphLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    graphData: payload.data,
                }),
            });
        case FETCH_FUEL_LEVEL_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoadingFuelLevelSummary: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoadingFuelLevelSummary: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    fuelLevelSummary: payload.data,
                }),
            });
        case FETCH_ALERTS_GRAPH_BY_VEHICLE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isAlertsGraphByVehicleLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isAlertsGraphByVehicleLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    graphDataByVehicle: payload.data,
                }),
            });
        case FETCH_ALERTS_REPORT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_ALERTS_GRAPH_COUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    graphCountStatus: 'pending',
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, graphCountStatus: 'error' }),
                success: (prevState) => ({
                    ...prevState,
                    graphDataCount: payload.data,
                    graphCountStatus: 'success',
                }),
            });
        case FETCH_ALERTS_GRAPH_FREQUENCY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    graphFrequencyStatus: 'pending',
                    error: null,
                }),
                finish: (prevState) => prevState,
                failure: (prevState) => ({ ...prevState, error: payload, graphFrequencyStatus: 'error' }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        graphDataFrequency: payload.data,
                        graphFrequencyStatus: 'success',
                    };
                },
            });
        case FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    districtDataStatus: 'pending',
                    districtData: null,
                    error: null,
                }),
                finish: (prevState) => prevState,
                failure: (prevState) => ({ ...prevState, error: payload, districtDataStatus: 'error' }),
                success: (prevState) => ({
                    ...prevState,
                    districtData: payload.data,
                    districtDataAsMap: new Map(map(payload.data, (item) => [item.districtName, item])),
                    districtDataStatus: 'success',
                }),
            });
        default:
            return state;
    }
}
