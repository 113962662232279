import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import routeAddressDispatchRoutes from './address';
import routeJobDispatchRoutes from './job';
import routeDispatchRoutes from './route';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const TimeOnSiteWrapperRoute = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-timeonsite" */ 'components/dashboard/route_dispatch/timeonsite/TimeOnSiteWrapperRoute'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleOnSiteComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-vehicleonsite" */ 'components/dashboard/route_dispatch/vehicleonsite/vehicle_on_site_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DetentionAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-detentionAnalytics" */ 'components/dashboard/route_dispatch/detentionAnalytics/detentionAnalyticsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DeviationAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-deviationAnalytics" */ 'components/dashboard/deviationAnalytics/DeviationAnalyticsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const SecondaryJobAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-secondaryJobAnalyticsComponent" */ 'components/dashboard/route_dispatch/secondaryJobAnalytics/SecondaryJobAnalyticsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddressBookAnalytics = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-addressBookAnalyticsComponent" */ 'components/dashboard/route_dispatch/addressBookAnalytics/AddressBookAnalyticsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const routeRoutes = [
    {
        path: 'route',
        children: routeDispatchRoutes,
    },
    {
        path: 'addressbook',
        children: routeAddressDispatchRoutes,
    },
    {
        path: 'jobs',
        children: routeJobDispatchRoutes,
    },
    {
        path: 'timeonsite',
        lazy: TimeOnSiteWrapperRoute,
    },
    {
        path: 'vehicleonsite',
        lazy: VehicleOnSiteComponent,
    },
    {
        path: 'detentionAnalytics',
        lazy: DetentionAnalyticsComponent,
    },
    {
        path: 'secondaryJobAnalytics',
        lazy: SecondaryJobAnalyticsComponent,
    },
    {
        path: 'addressBookAnalytics',
        lazy: AddressBookAnalytics,
    },
    {
        path: 'deviationAnalytics',
        lazy: DeviationAnalyticsComponent,
    },
    {
        path: '*',
        element: _jsx(Navigate, { to: "/dashboard/routes/jobs", replace: true }),
    },
];
export default routeRoutes;
