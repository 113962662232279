import { get, map } from 'lodash';
import { handle } from 'redux-pack';
import { FETCH_GST_RATES } from 'actions/dashboard/consignments/gstRatesActions';
import { FETCH_CHARGE_HEADS } from 'actions/dashboard/consignments/chargeheadsActions';
import { FETCH_LEDGERS } from 'actions/dashboard/consignments/ledgersActions';
import { FETCH_LEDGERS_GROUPS } from 'actions/dashboard/consignments/ledgerGroupActions';
import { FETCH_DIVISIONS } from 'actions/dashboard/consignments/divisionActions';
import { FETCH_CONSIGNMENT_IDS, FETCH_CONSIGNMENTS, FETCH_CONSIGNMENTS_PAGINATED, FETCH_CONSIGNMENTS_PAGINATED_DRAFTS, } from 'actions/dashboard/consignments/consignmentsActions';
const initialState = {
    isGstRatesLoading: false,
    gstRates: [],
    chargeHeads: [],
    isChargeHeadsLoading: false,
    ledgers: [],
    isLedgersLoading: false,
    isConsignmentsLoading: false,
    consignments: [],
    isDivisionsLoading: false,
    divisions: [],
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        // tax rates  types
        case FETCH_GST_RATES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isGstRatesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isGstRatesLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    gstRates: payload.data,
                }),
            });
        // charge heads  types
        case FETCH_CHARGE_HEADS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isChargeHeadsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isChargeHeadsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    chargeHeads: payload.data,
                }),
            });
        // ledgers  types
        case FETCH_LEDGERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isLedgersLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isLedgersLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    ledgers: payload.data,
                }),
            });
        case FETCH_LEDGERS_GROUPS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isAccountGroupsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isAccountGroupsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    accountGroups: payload.data,
                }),
            });
        case FETCH_DIVISIONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isDivisionsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isDivisionsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    divisions: payload.data,
                }),
            });
        // consignments
        case FETCH_CONSIGNMENTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isConsignmentsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isConsignmentsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    consignments: payload.data,
                }),
            });
        case FETCH_CONSIGNMENTS_PAGINATED:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isConsignmentsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isConsignmentsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    consignmentsPaginated: payload.data.content,
                }),
            });
        case FETCH_CONSIGNMENT_IDS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    consignments: payload.data.content,
                    consignmentsAsMap: new Map(map(get(payload, 'data.content', []), (item) => [get(item, 'id'), item])),
                }),
            });
        case FETCH_CONSIGNMENTS_PAGINATED_DRAFTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isConsignmentsLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isConsignmentsLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    consignmentsPaginated: payload.data.content,
                }),
            });
        default:
            return state;
    }
}
