import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FxButton, FxModifiedTimeline, FxCard } from '../../../../sharedV2';
const ServiceEntryTimeLineComponent = (props) => {
    const { accesstoken, approvalData, showButton, acceptanceLevel, loggedInUser, onApproveOrReject } = props;
    const { t } = useTranslation();
    const rejectedApproval = () => {
        if (acceptanceLevel) {
            const body = {
                id: get(approvalData[acceptanceLevel - 1], `id`),
                accountId: get(loggedInUser, 'accountId'),
                approvalEntity: 'SERVICE_ENTRY',
                approvalEntityId: get(approvalData[acceptanceLevel - 1], `approvalEntityId`),
                approvalLevelId: get(approvalData[acceptanceLevel - 1], `approvalLevelId`),
                approvalLevelStatus: 'REJECTED',
                approvedBy: get(loggedInUser, 'id'),
            };
            onApproveOrReject(body);
        }
    };
    const acceptedApproval = () => {
        if (acceptanceLevel) {
            const body = {
                id: get(approvalData[acceptanceLevel - 1], `id`),
                accountId: get(loggedInUser, 'accountId'),
                approvalEntity: 'SERVICE_ENTRY',
                approvalEntityId: get(approvalData[acceptanceLevel - 1], `approvalEntityId`),
                approvalLevelId: get(approvalData[acceptanceLevel - 1], `approvalLevelId`),
                approvalLevelStatus: 'APPROVED',
                approvedBy: get(loggedInUser, 'id'),
            };
            onApproveOrReject(body);
        }
    };
    return (_jsxs(FxCard, { className: "mt-4", children: [_jsx(FxModifiedTimeline, { timlineTitle: 'History', ModifiedItems: approvalData }), _jsxs("div", { className: "d-flex justify-content-end", children: [_jsx(FxButton, { className: "rounded", type: "default", disabled: !showButton, onClick: () => rejectedApproval(), children: "Reject" }), _jsxs(FxButton, { className: "rounded ml-2", type: "primary", disabled: !showButton, onClick: () => acceptedApproval(), children: ["Approve Level ", acceptanceLevel ? acceptanceLevel : ''] })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: state.settings.loggedInUser,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceEntryTimeLineComponent);
