import { useState } from 'react';
// import SingleFileDropzoneComponent from '../components/utils/single_file_dropzone_component';
import { get } from 'lodash';
import FxSingleFileDropzoneComponent from 'sharedV2/fileUpload/FxSingleFileDropzoneComponent';
import * as React from 'react';

const _SingleFileDropzoneFormik = (props) => {
    const [fileUploadError, setFileUploadError] = useState(null);
    const {
        label,
        isRequired,
        field,
        form,
        outerDivStyle,
        labelDivStyle = '',
        style = {
            width: '100%',
            height: 66,
        },
    } = props;

    const error = get(form.errors, field.name, '');

    return (
        <div className={outerDivStyle}>
            {label && (
                <div className={error ? 'form-control-label text-danger' : `${labelDivStyle}`}>
                    <label>{label}</label>
                    {isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}

            <FxSingleFileDropzoneComponent
                {...props}
                uploadedUrl={field.value}
                fileErrorCallback={(error) => {
                    setFileUploadError(error);
                }}
                fileSuccessCallback={(url) => {
                    setFileUploadError(null);
                    form.setFieldValue(field.name, url);
                }}
                fileDeleteCallback={() => {
                    setFileUploadError(null);
                    form.setFieldValue(field.name, null);
                }}
            />
            {/*<SingleFileDropzoneComponent*/}
            {/*    {...props}*/}
            {/*    uploadedUrl={field.value}*/}
            {/*    fileErrorCallback={(error) => {*/}
            {/*        setFileUploadError(error);*/}
            {/*    }}*/}
            {/*    fileSuccessCallback={(url) => {*/}
            {/*        setFileUploadError(null);*/}
            {/*        form.setFieldValue(field.name, url);*/}
            {/*    }}*/}
            {/*    fileDeleteCallback={() => {*/}
            {/*        setFileUploadError(null);*/}
            {/*        form.setFieldValue(field.name, null);*/}
            {/*    }}*/}
            {/*    style={style}*/}
            {/*/>*/}
            {/*{(fileUploadError || error) && (*/}
            {/*    <div className="text-help text-danger">*/}
            {/*        {fileUploadError}*/}
            {/*        {fileUploadError ? '-' : ''}*/}
            {/*        {error}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default _SingleFileDropzoneFormik;
