import { handle } from 'redux-pack';
import { FETCH_VEHICLE_LOANS_EMI, FETCH_VEHICLE_LOANS_PAYMENT } from 'actions/dashboard/vehicleLoansEmi';
const initialState = {
    isLoading: false,
    loans: [],
    totalElements: 0,
    error: null,
    loansPaymentData: null,
};
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_VEHICLE_LOANS_EMI:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loans: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        case FETCH_VEHICLE_LOANS_PAYMENT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    loansPaymentData: payload.data.content,
                    totalElements: payload.data.totalElements,
                }),
            });
        default:
            return state;
    }
};
