import { get, includes, map, split, toLower, zipObject } from 'lodash';
import { ROLE_MAP } from '../../../constant';
import {
    checkTechnicianAccountRole,
    FLEETX_ACCOUNTS,
    isDemoAccount,
    isExpressRoadwaysAccount,
    isFleetxAccount,
    isHellaInfraAccount,
    isjobBookingOpenToAccount,
    isShivaniAccount,
    isSuzukiMotorsGujaratAccount,
    restrictAddressBookAndRouteCreationForExpressRoadwaysUser,
    showAlarmsForAccount,
    showCommentHistoryInVehicleDetailForAccount,
    showDashboardForAccount,
    showDtcsForAccount,
    showFinanceForAccount,
    showFuelEntriesForAccount,
    showIdlinginSiteAnalytics,
    showJobsForAccount,
    showLastTripDetailsinRealtimeReport,
    showRemindersForAccount,
    showServiceEntriesForAccount,
    showTimeOnSiteForAccount,
    showTripsForAccount,
    showVehiclesForAccount,
} from '../../../utils/account_utils';
import { isModuleEnabledForUser, isWithinScope } from 'utils/permissionUtils/utils';
import { isDeviceAttachDetachAllowed } from '../../../utils/internal_tools/operations_utils';
import { showIot } from 'components/dashboard/settings/alerts/alert_utils';

const validEmailsWithAccessToConfig = [
    'tabish@fleetx.io',
    'abhimanyu.rathore@fleetx.io',
    'mani@fleetx.io',
    'bhanu.arya@fleetx.io',
    'anshu.pm@fleetx.io',
    'shantanu@fleetx.io',
    'arun.sardana+1@fleetx.io',
    'abhay@fleetx.io',
    'udbhav@fleetx.io',
    'vishal.rana@fleetx.io',
    'alok.singh@fleetx.io',
    'shantanu.atri@fleetx.io',
    'dharneesh.gupta@fleetx.io',
    'sumit.patel@fleetx.io',
    'abhigyan.kole@fleetx.io',
    'purva.rai@fleetx.io',
    'ashutosh.jain@fleetx.io',
    'rahul.mahida@fleetx.io',
    'abhishek.anand@fleetx.io',
    'rupam.sharma@fleetx.io',
    'nimesh.sharma@fleetx.io',
    'sagar.chawla+5@fleetx.io',
    'devesh.sharma@fleetx.io',
    'satish@fleetx.io',
    'arun.sardana@fleetx.io',
    'prahlad.singh@fleetx.io',
    'jayesh.vankar@fleetx.io',
    'dev@fleetx.io',
    'prahlad.singh+1@fleetx.io',
    'jayesh.vankar@fleetx.io',
    'manish.jangra@fleetx.io',
];

export const MODULE = {
    SETTINGS: 'Settings',
    REMINDERS: 'Reminders',
    FUEL_ENTRIES: 'Fuel Entries',
    REAL_TIME: 'Realtime',
    TRIPS: 'Trips',
    JOBS: 'Jobs & Routes',
    ISSUES: 'Issues',
    FINANCES: 'Finances',
    SERVICE_HISTORY: 'Service History',
    TYRE_MANAGEMENT: 'Tyre Management',
    SIM_TRACKING: 'Sim Tracking',
    LOCKS: 'Locks',
    VENDOR_COLLABORATION: 'Vendor Collaboration',
    INTERNAL_ISSUES: 'Internal Issues',
    PARTS: 'Parts',
    WORK_ORDERS: 'Work Orders',
    OPERATIONS_ERP: 'Operations ERP',
    ACCOUNTING_MANAGEMENT: 'ACCOUNTING MANAGEMENT',
    QUOTATION: 'QUOTATION',
    VIDEO_TELEMATICS: 'VideoTelematics',
};

export function getScopesArr(scopes = []) {
    if (typeof scopes === 'string') {
        return split(scopes, ' ');
    } else {
        return scopes;
    }
}

export function isDashboardVisible(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_OVERVIEW') && showDashboardForAccount(loggedInUser);
}

export function isVehicleAnalyticsVisible(scopes) {
    return isWithinScope('VIEW_VEHICLE_ANALYTICS', getScopesArr(scopes));
}

export function isManageUsers(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_USERS') || includes(getScopesArr(scopes), 'UPDATE_USER');
}

export function isManageAddressBook(scopes) {
    return (
        includes(getScopesArr(scopes), 'MANAGE_ADDRESS_BOOK') &&
        !restrictAddressBookAndRouteCreationForExpressRoadwaysUser(window.FLEETX_LOGGED_IN_USER)
    );
}

export function isManageServiceTasks(scopes) {
    return isWithinScope('MANAGE_SERVICE_TASKS', getScopesArr(scopes));
}

export function isManageVendor(scopes) {
    return isWithinScope('MANAGE_VENDORS', getScopesArr(scopes));
}

export function isManageVehicleRenewalTypes(scopes) {
    return isWithinScope('MANAGE_VEHICLE_RENEWAL_TYPE', getScopesArr(scopes));
}

export function isManageContactRenewalTypes(scopes) {
    return isWithinScope('MANAGE_CONTACT_RENEWAL_TYPE', getScopesArr(scopes));
}

export function isManageServiceReminders(scopes) {
    return isWithinScope('MANAGE_SERVICE_REMINDERS', getScopesArr(scopes));
}

export function isManageVehicleRenewalReminders(scopes) {
    return isWithinScope('MANAGE_VEHICLE_RENEWAL_REMINDERS', getScopesArr(scopes));
}

export function isManageContactRenewalReminders(scopes) {
    return isWithinScope('MANAGE_CONTACT_RENEWAL_REMINDERS', getScopesArr(scopes));
}

export function isManageTyre(scopes) {
    return isWithinScope('MANAGE_TYRES', getScopesArr(scopes));
}

export function isTyreAttachAllowed(scopes) {
    return isWithinScope('TYRE_ATTACH', getScopesArr(scopes));
}

export function isTyreDetachAllowed(scopes) {
    return isWithinScope('TYRE_DETACH', getScopesArr(scopes));
}

export function isViewServiceReminders(scopes) {
    return isWithinScope('VIEW_SERVICE_REMINDERS', getScopesArr(scopes));
}

export function isViewVehicleRenewalReminders(scopes) {
    return isWithinScope('VIEW_VEHICLE_RENEWAL_REMINDERS', getScopesArr(scopes));
}

export function isViewContactRenewalReminders(scopes) {
    return isWithinScope('VIEW_CONTACT_RENEWAL_REMINDERS', getScopesArr(scopes));
}

export function isViewVehicleSizes(scopes) {
    return isWithinScope('VIEW_VEHICLE_SIZES', getScopesArr(scopes));
}

export function isRealtimeVisible(scopes) {
    return isWithinScope('VIEW_REAL_TIME', getScopesArr(scopes));
}

export function isAccountSettingsVisible(scopes) {
    return isWithinScope('MANAGE_ACCOUNT_SETTINGS', getScopesArr(scopes));
}

export function isTripsModuleEnabled(modules, loggedInUser) {
    return isModuleEnabled(modules, MODULE.TRIPS) && showTripsForAccount(loggedInUser);
}

export function isOperationsERPEnabled(modules = []) {
    return isModuleEnabledForUser(MODULE.OPERATIONS_ERP, modules);
}

export function isVideoTelematicsModuleEnabled() {
    const modules = get(window, 'FLEETX_LOGGED_IN_DATA.modules', []);
    return isModuleEnabled(modules, MODULE.VIDEO_TELEMATICS);
}

export function isRealtimeModuleEnabled(modules, scopes) {
    return isRealtimeVisible(scopes);
}

export function isSimTrackingModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.SIM_TRACKING);
}

export function isSettingModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.SETTINGS);
}

export function isJobModuleEnabled(modules, scopes, loggedInUser) {
    return showJobsForAccount(loggedInUser) && isJobsVisible(scopes);
}

export function isQuotationModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.QUOTATION);
}

export function showJobsforEmail() {
    const email = get(window.FLEETX_LOGGED_IN_USER, 'email', null);
    return !includes(['krishna.kumar@varuna.net'], email);
}

export function isShowIdlinginSiteAnalyticsEnabled(loggedInUser) {
    return showIdlinginSiteAnalytics(loggedInUser);
}

export function isShowLastTripDetailsInRealTimeReportEnabled(loggedInUser) {
    return showLastTripDetailsinRealtimeReport(loggedInUser);
}

export function isVendorCollaborationModuleEnabled(modules, scopes, loggedInUser) {
    return isModuleEnabled(modules, MODULE.VENDOR_COLLABORATION);
}

export function isRemindersModuleEnabled(modules, scopes, loggedInUser) {
    return (
        showRemindersForAccount(loggedInUser) &&
        isViewServiceReminders(scopes) &&
        isViewVehicleRenewalReminders(scopes) &&
        isViewContactRenewalReminders(scopes)
    );
}

export function isFuelEntriesModuleEnabled(modules, scope, loggedInUser) {
    return isViewFuelEntriesAllowedForUser(scope) && showFuelEntriesForAccount(loggedInUser);
}

export function isServiceEntriesModuleEnabled(modules, scope, loggedInUser) {
    return isViewServiceEntries(scope) && showServiceEntriesForAccount(loggedInUser);
}

export function isTyreManagementModuleEnabled(modules, scope, loggedInUser) {
    //const isEnabled = loggedInUser.accountId === 113;
    //return isEnabled;
    return isViewTyre(scope);
}

export function isBatteryManagementModuleEnabled(scope) {
    return isViewBatteriesAllowedForUser(scope);
}

export function isIssuesModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.ISSUES);
}

export function isInternalIssuesModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.INTERNAL_ISSUES);
}

export function isFinancesModuleEnabled(modules, loggedInUser) {
    return isModuleEnabled(modules, MODULE.FINANCES) && showFinanceForAccount(loggedInUser);
}

export function isControlRoomModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.JOBS);
}

export function isAccountingManagementEnabled(modules) {
    return isModuleEnabled(modules, MODULE.ACCOUNTING_MANAGEMENT);
}

export function isUserVisible(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_USERS') || includes(getScopesArr(scopes), 'VIEW_USERS');
}

export function isVendorVisible(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_VENDORS') || includes(getScopesArr(scopes), 'VIEW_VENDORS');
}

export function isTransporterVisible(scopes) {
    // If user has access to MANAGE_JOBS he has access to transports, No scope like MANAGE_TRANSPORTERS
    return includes(getScopesArr(scopes), 'MANAGE_JOBS') || includes(getScopesArr(scopes), 'VIEW_JOBS');
}

export function isDtcVisible(scopes, loggedInUser) {
    const roleId = get(loggedInUser, 'role.id', '');
    return (
        includes(getScopesArr(scopes), 'VIEW_DTC') && showDtcsForAccount(loggedInUser) && !includes([111689], roleId)
    );
}

export function isUpdateDTC(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_DTC');
}

export function isAlarmVisible(scopes, loggedInUser) {
    const roleId = get(loggedInUser, 'role.id', '');
    return (
        (includes(getScopesArr(scopes), 'MANAGE_ALARMS') || includes(getScopesArr(scopes), 'VIEW_ALARMS')) &&
        showAlarmsForAccount(loggedInUser) &&
        !includes([111689], roleId)
    );
}

export function isManageAlarms(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_ALARMS');
}

export function isManageIssues(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_ISSUES');
}

export function isViewIssues(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ISSUES');
}

export function manageAccountSettings(scopesArr) {
    return includes(scopesArr, 'MANAGE_ACCOUNT_SETTINGS');
}

export function isVehicleVisible(scopes, loggedInUser) {
    return (
        (includes(getScopesArr(scopes), 'VIEW_VEHICLES') || includes(getScopesArr(scopes), 'MANAGE_VEHICLES')) &&
        showVehiclesForAccount(loggedInUser)
    );
}

export function isDocumentCostVisible(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_DOCUMENTATION');
}

export function isViewVehicleDocumentsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_DOCUMENTATION');
}

export function isCreateVehicleDocumentsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_DOCUMENTATION');
}

export function isTripsVisible(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRIPS') && showTripsForAccount(window.FLEETX_LOGGED_IN_USER);
}

export function isManageVehicles(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_VEHICLES');
}

export function isContactTracingVisible(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONTACT_TRACINGS');
}

export function manageImmobiliser(scopesArr) {
    return includes(scopesArr, 'MANAGE_IMMOBILISER');
}

export function isSwitchVehicles(scopes) {
    return includes(getScopesArr(scopes), 'SWITCH_USER');
}

export function isSwitchVehiclesClient(scopes) {
    return includes(getScopesArr(scopes), 'SWITCH_USER_CLIENT');
}

export function isManageFuelEntries(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_FUEL_ENTRIES');
}

export function isManageTags(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_TAGS');
}

export function isViewFuelEntries(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FUEL_ENTRIES');
}

export function isManageCustomers(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_CUSTOMERS');
}

export function isManageTransporters(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_JOBS');
}

export function isViewCustomers(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CUSTOMERS');
}

export function isManageDuty(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_DUTY');
}

export function isViewDuty(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DUTY');
}

export function isManageInvoice(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_INVOICE');
}

export function isViewInvoice(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_INVOICE');
}

export function isManageRoutes(scopes) {
    return (
        includes(getScopesArr(scopes), 'MANAGE_ROUTES') &&
        !restrictAddressBookAndRouteCreationForExpressRoadwaysUser(window.FLEETX_LOGGED_IN_USER)
    );
}

export function isManageJobs(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_JOBS');
}

export function isJobsVisible(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_JOBS');
}

export function isTransferJobsVisible(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRANSFER_JOB');
}

export function isSanctionPayment(scopes) {
    return includes(getScopesArr(scopes), 'SANCTION_PAYMENT');
}

export function isManageServiceEntries(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_SERVICE_ENTRIES');
}

export function isManageDevices(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_DEVICES');
}

export function isManageSimDevices(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_SIM_TRACKING');
}

export function isViewServiceEntries(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SERVICE_ENTRIES');
}

export function isViewTyre(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TYRES');
}

export function isCreateAccount(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ACCOUNT');
}

export function isEditAccount(scopes) {
    return includes(getScopesArr(scopes), 'EDIT_ACCOUNTS');
}

export function isViewAccountPrices(scopes) {
    return includes(getScopesArr(scopes), 'EDIT_ACCOUNTS');
}

export function isViewAccounts(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ACCOUNTS');
}

export function isViewAccountsClients(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ACCOUNTS_CLIENT');
}

export function isFuelVisible(loggedInUser) {
    return true;
}

export function isCostVisible(loggedInUser) {
    return true;
    // Hiding fuel cost for viewer roles
    // if (get(loggedInUser, 'role.id') === ROLE_MAP.ROLE_VIEWER.id) {
    //     return false;
    // }
    // return true;
}

function checkRole(loggedInUser, role) {
    return (
        get(loggedInUser, 'role.name', get(loggedInUser, 'role', loggedInUser)) === role.name ||
        get(loggedInUser, 'role.id', loggedInUser) === role.id
    );
}

export function isRoleSubMaster(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_SUB_MASTER);
}

export function isRoleGPSTrackingDriverInfoVehicleInfo(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_GPS_TRACKING_DRIVER_INFO_VEHICLE_INFO);
}

export function isRoleOwner(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_OWNER);
}

export function isRoleAdmin(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_ADMIN);
}

export function isRoleMaster(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_MASTER);
}

export function isRoleEPODCustomerEpod(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_CUSTOMER_EPOD);
}

export function isRoleDataMaster(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_DATA_MASTER);
}

export function isRoleManager(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_MANAGER);
}

export function isRoleDataMasterEdit(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_DATA_MASTER_WITH_EDIT);
}

export function isRoleViewerRealtimeHistory(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_REALTIME_HISTORY);
}

export function isRoleTechnicianMaster(loggedInUser) {
    return checkRole(loggedInUser, ROLE_MAP.ROLE_TECHNICIAN_MASTER);
}

function isModuleEnabled(modules, moduleKey) {
    if (!modules) {
        return false;
    }

    return getModules(modules)[moduleKey];
}

function getModules(modules) {
    //use this.props.login.modules
    //find(this.props.login.modules, (o) => (o === 'Settings'));
    return zipObject(
        modules,
        map(modules, () => true)
    );
}

export function showDeviceSettings(scopes, loggedInUser) {
    return isSwitchVehicles(scopes) && isViewAccounts(scopes) && !isRoleSubMaster(loggedInUser);
}

export function showAssignDuties(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showSendShipment(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showTransportersIncoming(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showAssignDevices(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showAssignAssets(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseInstalls(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseSettingsLog(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseLockConfigurationLog(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseDeviceWithoutVehicle(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseDuties(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseIotSupport(scopes, loggedInUser) {
    if (isRoleMaster(loggedInUser)) {
        return true;
    }
    return showIot();
}

export function showMetabaseInventory(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseDeviceAuditHistorySearch(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseIssuedToListing(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseSimInventory(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showMetabaseDeviceSearchBy(scopes, loggedInUser) {
    return isRoleDataMaster(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showKillDeviceSettings(loggedInUser) {
    return isRoleMaster(loggedInUser);
}

export function showTagsFeature(scopes) {
    return !isSwitchVehicles(scopes);
}

export function allowActionOnBillToClient(loggedInUser) {
    return !isRoleTechnicianMaster(loggedInUser);
}

export function showBikeIconsForGroupIdForSmartGreen(id) {
    return includes([876, 1440], id);
}

export function allowAutoJobConfig(loggedInUser, switchedUserOriginalRoleId) {
    const role = { role: { id: switchedUserOriginalRoleId } };
    if (isRoleMaster(role) || isRoleSubMaster(role) || isRoleDataMaster(role)) {
        return true;
    }
    const userId = get(loggedInUser, 'id');
    return includes(
        [
            'tabish@fleetx.io',
            'abhimanyu.rathore@fleetx.io',
            'mani@fleetx.io',
            'bhanu.arya@fleetx.io',
            'anshu.pm@fleetx.io',
            'shantanu@fleetx.io',
            'arun.sardana+1@fleetx.io',
            'abhay@fleetx.io',
            'udbhav@fleetx.io',
            'chandramohan.yadav@fleetx.io',
            'vishal.rana@fleetx.io',
            'ritwik.chakradhar@fleetx.io',
            'rhythm.sharma@fleetx.io',
            'alok.singh@fleetx.io',
            'shantanu.atri@fleetx.io',
            'dharneesh.gupta@fleetx.io',
            'sumit.patel@fleetx.io',
            'abhigyan.kole@fleetx.io',
            'purva.rai@fleetx.io',
            'ashutosh.jain@fleetx.io',
            'rahul.mahida@fleetx.io',
            'abhishek.anand@fleetx.io',
            'rupam.sharma@fleetx.io',
            'nimesh.sharma@fleetx.io',
            'sagar.chawla+5@fleetx.io',
            'devesh.sharma@fleetx.io',
            'prahlad.singh@fleetx.io',
        ],
        validEmailsWithAccessToConfig,
        toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId'))
    );
}

export function allowRiskReportConfig(loggedInUser) {
    return includes(
        validEmailsWithAccessToConfig,
        toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId'))
    );
}

export function allowUserToControllTransactionStatus(loggedInUser, scopes) {
    switch (get(loggedInUser, 'accountId', null)) {
        case FLEETX_ACCOUNTS.BVC:
            return (
                isAprooveAndRejectTransactionAllowedForUser(scopes) ||
                get(loggedInUser, 'role.name', loggedInUser) === 'Branch' ||
                get(loggedInUser, 'role.id', loggedInUser) === 29185 ||
                get(loggedInUser, 'role.id', loggedInUser) === 93141
            );
        case FLEETX_ACCOUNTS.DOT_TRUCKERS:
            return (
                isAprooveAndRejectTransactionAllowedForUser(scopes) ||
                get(loggedInUser, 'role.id', loggedInUser) === 94185
            );
        default:
            return isAprooveAndRejectTransactionAllowedForUser(scopes);
    }
}

export function allowUserToControllJobBookingStatus(loggedInUser) {
    return isRoleOwner(loggedInUser) || isRoleAdmin(loggedInUser);
}

export function avoidSetBoundsForAccounts(loggedInUser) {
    //because causing some problem if address ard very very far from each other
    return isFleetxAccount(loggedInUser);
}

export function showClientFeedbackLinkCreation(loggedInUser) {
    return (
        isSuzukiMotorsGujaratAccount(loggedInUser) ||
        isDemoAccount(loggedInUser) ||
        isFleetxAccount(loggedInUser) ||
        isHellaInfraAccount(loggedInUser)
    );
}

export function showCommentHistoryForAccount(loggedInUser) {
    if (isExpressRoadwaysAccount(loggedInUser) || isShivaniAccount(loggedInUser) || isFleetxAccount(loggedInUser)) {
        return true;
    }
    return showCommentHistoryInVehicleDetailForAccount();
}

export function isAttachLocksAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_LOCKS');
}

export function isDetachLocksAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_LOCKS');
}

export function isViewLocksAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_LOCKS');
}

export function isUnlockLockAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_LOCKS');
}

export function isLocksModuleEnabledForAccount(modules, scope, loggedInUser) {
    return isModuleEnabled(modules, MODULE.LOCKS) && isViewLocksAllowed(scope);
}

export function isCreateVehicleSizeEnabled(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_SIZE');
}

export function isUpdateVehicleSizeEnabled(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE_SIZE');
}

export function isDeleteVehicleSizeEnabled(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VEHICLE_SIZE');
}

export function isAllowedToCreateAuction(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_AUCTION');
}

export function isViewVehicleSizeEnabled(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_SIZES');
}

export function isCreateVehicleStatusEnabled(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_STATUS');
}

export function isUpdateVehicleStatusEnabled(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE_STATUS');
}

export function isDeleteVehicleStatusEnabled(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VEHICLE_STATUS');
}

export function isDriverAssignmentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_ASSIGNMENT');
}

export function isCreateVehicleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE');
}

export function isViewVehicleStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_STATUSES');
}

export function isUpdateVehicleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE');
}

export function isDeleteVehicleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VEHICLE');
}

export function isDownloadVehicleReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_VEHICLE_REPORTS');
}

export function isViewVehicleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VEIW_VEHICLE_TYPES');
}

export function isDeleteServiceEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_SERVICE_ENTRY');
}

export function isUpdateServiceEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_SERVICE_ENTRY');
}

export function isCreateServiceEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_SERVICE_ENTRY');
}

export function isViewServiceEntriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SERVICE_ENTRIES');
}

export function isDownloadServiceEntriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_SERVICE_ENTRIES');
}

export function isUpdateServiceReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_SERVICE_REMINDER');
}

export function isViewServiceRemindersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SERVICE_REMINDERS');
}

export function isDeleteServiceReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_SERVICE_REMINDER');
}

export function isDownloadServiceRemindersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_SERVICE_REMINDERS');
}

export function isCreateServiceReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_SERVICE_REMINDER');
}

export function isDeleteServiceTaskAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_SERVICE_TASK');
}

export function isUpdateServiceTaskAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_SERVICE_TASK');
}

export function isCreateServiceTaskAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_SERVICE_TASK');
}

export function isViewServiceTasksAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SERVICE_TASKS');
}

export function isViewFuelRatesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FUEL_RATES');
}

export function isDownloadFuelLevelReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_FUEL_LEVEL_REPORTS');
}

export function isDownloadTemperatureAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_TEMPERATURE_REPORT');
}

export function isViewTemperatureAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TEMPERATURE_DATA');
}

export function isViewFuelLevelAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FUEL_LEVEL');
}

export function isShareRealtimeVehicleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'SHARE_REAL_TIME');
}

export function isDownloadRealtimeVehicleReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_REAL_TIME_REPORTS');
}

export function isDownloadAlarmsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_ALARMS');
}

export function isUpdateAlertSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_ALARMS');
}

export function isCreateAlertSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ALERT_SETTINGS');
}

export function isViewAlertSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ALERT_SETTINGS');
}

export function isViewDispatchPlannerSettingsForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DISPATCH_PLAN_CONFIG');
}

export function isViewApprovalConfigSettingsAllowed(scopes) {
    return (
        includes(getScopesArr(scopes), 'VIEW_APPROVAL_CONFIG') ||
        includes(getScopesArr(scopes), 'CREATE_APPROVAL_CONFIG')
    );
}

export function isCreateApprovalConfigSettingsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_APPROVAL_CONFIG');
}

export function isViewApprovalDataAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_APPROVAL');
}

export function isDeleteAlertSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ALERT_SETTINGS');
}

export function isViewMisuseAlertsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TIMEFENCES');
}

export function isCreateMisuseAlertSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TIMEFENCE');
}

export function isUpdateMisuseAlertSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TIMEFENCE');
}

export function isDeleteMisuseAlertAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TIMEFENCE');
}

export function isViewSpeedViolationsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SPEED_VIOLATIONS');
}

export function isViewStoppagesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_STOPPAGES');
}

export function isViewHistoricalLocationAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_HISTORICAL_LOCATION');
}

export function isViewTimelineAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TIMELINE');
}

export function isViewTripsBtwnSitesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRIPS_BW_SITES');
}

export function isDownloadTripsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_TRIP_REPORTS');
}

export function isViewMovementAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_MOVEMENT');
}

export function isViewTimeOnSiteAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TIMEONSITE') && showTimeOnSiteForAccount();
}

export function isViewVendorListAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_JOB_VENDOR');
}

export function isViewVideoTelematicsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DVR');
}

export function isCreateVideoTelematicsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_DVR');
}

export function isStopVideoTelematicsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'STOP_DVR');
}

export function isViewUsersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_USERS');
}

export function isAprooveAndRejectTransactionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'APPROVE_TRANSACTIONS');
}

export function isViewTransporterUsersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRANSPORTER_USER');
}

export function isCreateUserAllowedForUser(scopes) {
    let isAllowed = includes(getScopesArr(scopes), 'CREATE_USER');
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const { switchedUserOriginalId, switchedUserOriginalRoleId } = window.SWITCHED_USER_ORIGINAL_DATA || {};
    let isEnabledForRole = checkTechnicianAccountRole(
        loggedInUser,
        !!switchedUserOriginalId,
        switchedUserOriginalRoleId
    );
    if (includes([113, 1180], accountId) && switchedUserOriginalId) {
        isAllowed =
            isAllowed && (includes([57032, 76147, 121489, 6008, 529938], switchedUserOriginalId) || isEnabledForRole);
    }
    return isAllowed;
}

export function isUpdateUserAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_USER');
}

export function isDeleteUserAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_USER');
}

export function isViewArrivalAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ARRIVAL_FORM');
}

export function isViewOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ORDER');
}

export function isCreateOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ORDER');
}

export function isCreateArrivalAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ARRIVAL_FORM');
}

export function isUpdateArrivalAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ARRIVAL_FORM');
}

export function isDeleteArrivalAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ARRIVAL_FORM');
}

export function isViewGeofencesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_GEOFENCES');
}

export function isCreateGeofenceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_GEOFENCE');
}

export function isUpdateGeofenceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_GEOFENCE');
}

export function isDeleteGeofenceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_GEOFENCE');
}

export function isViewFuelEntriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FUEL_ENTRIES');
}

export function isCreateFuelEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_FUEL_ENTRY');
}

export function isUpdateFuelEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_FUEL_ENTRY');
}

export function isDeleteFuelEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_FUEL_ENTRY');
}

export function isDownloadFuelEntryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_TRIP_REPORTS');
}

export function isCreateAddressBookAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ADDRESS_BOOK');
}

export function isViewAddressBookAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_ADDRESS_BOOK');
}

export function isViewAddressBookSummaryAllowedForUser(scopes, loggedInUser) {
    return (
        includes(getScopesArr(scopes), 'VIEW_ADDRESS_BOOK_SUMMARY') ||
        includes(getScopesArr(scopes), 'VIEW_ADDRESS_BOOK')
    );
}

export function isDeleteAddressBookAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ADDRESS_BOOK');
}

export function isUpdateAddressBookAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ADDRESS_BOOK');
}

export function isDownloadAddressBookAllowedForUser(scopes) {
    const accountId = get(window, 'FLEETX_LOGGED_IN_USER.accountId');
    return accountId !== 13278 && includes(getScopesArr(scopes), 'DOWNLOAD_ADDRESS_BOOKS');
}

export function isCreateJobAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_JOB');
}

export function isViewJobsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_JOBS');
}

export function isViewLRAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_LR');
}

export function isViewJobAnalyticsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_JOB_ANALYTICS');
}

export function isDeleteJobAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_JOB');
}

export function isUpdateJobAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_JOB');
}

export function isDownloadJobReportsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_JOB_REPORTS');
}

export function isCreateIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ISSUE');
}

export function isViewIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ISSUES');
}

export function isDeleteIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ISSUE');
}

export function isUpdateIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ISSUE');
}

export function isCreateTyresAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TYRE');
}

export function isCreateBatteriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_BATTERY');
}

export function isViewTyresAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TYRES');
}

export function isViewBatteriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_BATTERIES');
}

export function isDeleteTyresAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TYRE');
}

export function isDeleteBAtteriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_BATTERY');
}

export function isUpdateTyresAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TYRE');
}

export function isUpdateBatteriesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_BATTERY');
}

export function isCreateSimTrackingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_SIM_TRACKING');
}

export function isViewSimTrackingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SIM_TRACKINGS');
}

export function isVehicleTrailerAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_TRAILERS');
}

export function isVehicleChallanAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_CHALLAN');
}

export function isVehicleChallanUpdateAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE_CHALLAN');
}

export function isDeleteSimTrackingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_SIM_TRACKING');
}

export function isUpdateSimTrackingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_SIM_TRACKING');
}

export function isCreateJobBookingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_JOB_BOOKING');
}

export function isViewJobBookingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_JOB_BOOKINGS') && isjobBookingOpenToAccount();
}

export function isDeleteJobBookingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_JOB_BOOKING');
}

export function isUpdateJobBookingAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_JOB_BOOKING');
}

export function isCreateRoutesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ROUTE');
}

export function isViewRoutesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ROUTES');
}

export function isUpdateIndentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_INDENT');
}

export function isDeleteRoutesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ROUTE');
}

export function isUpdateRoutesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ROUTE');
}

export function isCreateCustomerAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CUSTOMER');
}

export function isViewCustomerAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CUSTOMERS');
}

export function isDeleteCustomerAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'DELETE_CUSTOMER');
}

export function isUpdateCustomerAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'UPDATE_CUSTOMER');
}

export function isCreateVehicleRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_RENEWAL_TYPE');
}

export function isViewVehicleRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_RENEWAL_TYPE');
}

export function isDeleteVehicleRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VEHICLE_RENEWAL_TYPE');
}

export function isUpdateVehicleRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE_RENEWAL_TYPE');
}

export function isCreateVehicleRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_RENEWAL_REMINDER');
}

export function isViewVehicleRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_RENEWAL_REMINDERS');
}

//VIEW_VEHICLE_ATTENDANCE
export function isViewVehicleAttendanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_ATTENDANCE');
}

export function isCreateVehicleAttendanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VEHICLE_ATTENDANCE');
}

export function isDeleteVehicleAttendanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VEHICLE_ATTENDANCE');
}

export function isUpdateVehicleAttendanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE_ATTENDANCE');
}

export function isDeleteVehicleRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VEHICLE_RENEWAL_REMINDER');
}

export function isUpdateVehicleRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VEHICLE_RENEWAL_REMINDER');
}

export function isDownloadVehicleReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_VEHICLE_RENEWAL_REMINDERS');
}

export function isCreateContactRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CONTACT_RENEWAL_TYPE');
}

export function isViewContactRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONTACT_RENEWAL_TYPE');
}

export function isDeleteContactRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CONTACT_RENEWAL_TYPE');
}

export function isUpdateContactRenewalTypeAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CONTACT_RENEWAL_TYPE');
}

export function isCreateContactRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CONTACT_RENEWAL_REMINDER');
}

export function isViewContactRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONTACT_RENEWAL_REMINDERS');
}

export function isDeleteContactRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CONTACT_RENEWAL_REMINDER');
}

export function isUpdateContactRenewalReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CONTACT_RENEWAL_REMINDER');
}

export function isDownloadContactReminderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_CONTACT_RENEWAL_REMINDERS');
}

export function isCreateDutySlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_DUTY_SLIP');
}

export function isViewDutySlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_DUTY_SLIP');
}

export function isDeleteDutySlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_DUTY_SLIP');
}

export function isUpdateDutySlipsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DUTY_SLIPS');
}

export function isCreateInvoiceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_INVOICE');
}

export function isViewInvoiceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_INVOICE');
}

export function isDeleteInvoiceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_INVOICE');
}

export function isUpdateInvoiceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_INVOICE');
}

export function isDownloadInvoicesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_INVOICES');
}

export function isCreateTransactionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TRANSACTION');
}

export function isViewTransactionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRANSACTIONS');
}

export function isDeleteTransactionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TRANSACTION');
}

export function isUpdateTransactionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TRANSACTION');
}

export function isDownloadTransactionsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DOWNLOAD_TRANSACTIONS');
}

export function isApproveTransactionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'APPROVE_TRANSACTIONS');
}

export function isViewVendorsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VENDORS');
}

export function isDeleteVendorAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VENDOR');
}

export function isUpdateVendorAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VENDOR');
}

export function isCreateVendorAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VENDOR');
}

export function isViewFirmsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FIRMS');
}

export function isDeleteFirmAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_FIRM');
}

export function isUpdateFirmAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_FIRM');
}

export function isCreateFirmAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_FIRM');
}

export function isViewTransportersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRANSPORTERS');
}

export function isDeleteTransporterAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TRANSPORTER');
}

export function isUpdateTransporterAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TRANSPORTER');
}

export function isCreateTransporterAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TRANSPORTER');
}

export function isViewProductsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_JOB_BOOKINGS');
}

export function isDeleteProductAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_JOB_BOOKING');
}

export function isUpdateProductAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_JOB_BOOKING');
}

export function isCreateProductAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_JOB_BOOKING');
}

export function isViewTagsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TAGS');
}

export function isViewTagsSummaryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TAGS_SUMMARY') || includes(getScopesArr(scopes), 'VIEW_TAGS');
}

export function isDeleteTagAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TAG');
}

export function isUpdateTagAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TAG');
}

export function isCreateTagAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TAG');
}

export const isAssignTagAllowedForUser = (scopes) => {
    return includes(getScopesArr(scopes), 'ASSIGN_TAG');
};

export const isRemoveTagAllowedForUser = (scopes) => {
    return includes(getScopesArr(scopes), 'REMOVE_TAG');
};

export function isViewGroupsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_GROUPS');
}

export function isViewGroupsSummaryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_GROUPS_SUMMARY') || includes(getScopesArr(scopes), 'VIEW_GROUPS');
}

export function isDeleteGroupAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_GROUP');
}

export function isUpdateGroupAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_GROUP');
}

export function isCreateGroupAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_GROUP');
}

export function isViewCommentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_COMMENTS');
}

export function isUpdateCommentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_COMMENT');
}

export function isDeleteCommentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_COMMENT');
}

export function isCreateCommentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_COMMENT');
}

export function isCreatePaymentReceiptAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'CREATE_PAYMENT_RECEIPT');
}

export function isViewPaymentReceiptsAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_PAYMENT_RECEIPTS');
}

export function isDeletePaymentReceiptAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'UPDATE_PAYMENT_RECEIPT');
}

export function isUpdatePaymentReceiptAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'DELETE_PAYMENT_RECEIPT');
}

export function isCreateAppTrackingAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'CREATE_APP_TRACKING');
}

export function isViewAppTrackingAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_APP_TRACKING');
}

export function isViewPaymentAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_PAYMENT');
}

export function isViewPaymentBillAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_PAYMENT_BILL');
}

export function isDeleteAppTrackingAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'UPDATE_APP_TRACKING');
}

export function isUpdateAppTrackingAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'DELETE_APP_TRACKING');
}

export function isInstallDeviceAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'INSTALL_DEVICES');
}

export function isViewDevicesAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'VIEW_DEVICES');
}

export function isDeleteDeviceAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'UPDATE_DEVICE');
}

export function isUpdateDeviceAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'UPDATE_DEVICE');
}

export function isDetachDeviceAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'DETACH_DEVICE');
}

export function isAttachDeviceAllowedForUser(scopes, loggedInUser) {
    return includes(getScopesArr(scopes), 'ATTACH_DEVICE');
}

export function isViewControlRoomAllowedForUser(modules, scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONTROL_ROOM');
}

export function isViewEPODAllowedForUser(modules, scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EPODS');
}

export function isViewERPConfigurationAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ERP_CONFIGURATION');
}

export function isCreateERPConfigurationAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ERP_CONFIGURATION');
}

export function isUpdateERPConfigurationAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ERP_CONFIGURATION');
}

export function isDeleteERPConfigurationAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ERP_CONFIGURATION');
}

export function isCreateEPODAllowedForUser(modules, scopes) {
    return includes(getScopesArr(scopes), 'CREATE_EPOD');
}

export function isViewCustomFieldAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CUSTOM_FIELDS');
}

export function isCreateCustomFieldAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CUSTOM_FIELD');
}

export function isUpdateCustomFieldAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CUSTOM_FIELD');
}

export function isDeleteCustomFieldAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CUSTOM_FIELD');
}

export function isViewStoresAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_STORES');
}

export function isCreatedStoreAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_STORE');
}

export function isUpdateStoreAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_STORE');
}

export function isDeleteStoreAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_STORE');
}

export function isViewTripSheetAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRIP_SHEETS');
}

export function isCreateTripSheetAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TRIP_SHEET');
}

export function isUpdateTripSheetAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TRIP_SHEET');
}

export function isDeleteTripSheetAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TRIP_SHEET');
}

export function isViewRolesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ROLES');
    return false;
}

export function isCreateRoleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ROLE');
}

export function isUpdateRoleAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ROLE');
}

export function isDeleteDutyAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TECHNICIAN_DUTY');
}

export function isViewDutyAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TECHNICIAN_DUTIES');
}

export function isCreateDutyAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TECHNICIAN_DUTY');
}

export function isUpdateDutyAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TECHNICIAN_DUTY');
}

export function isViewAccountAnalyticsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ACCOUNT_ANALYTICS');
}

export function isViewAccountSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ACCOUNT_SETTINGS');
}

export function isViewAccountInvoicesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ACCOUNT_INVOICES');
}

export function isUpdateAccountSettingsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ACCOUNT_SETTINGS');
    return true;
}

export function isViewAccountModulesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ACCOUNT_MODULES');
}

// TODO: not implemented
export function isViewAllPlansAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ALL_PLANS');
}

// Not implemented
export function isViewPurchaseOrdersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_PURCHASE_ORDERS');
}

export function isUpdatePurchaseOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_PURCHASE_ORDER');
}

export function isDeletePurchaseOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_PURCHASE_ORDER');
}

export function isUpdateAccountInvoiceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ACCOUNT_INVOICE');
}

export function isDeleteAccountInvoiceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_ACCOUNT_INVOICE');
}

export function isUpdateTripAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TRIP');
}

export function isDeleteTripAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TRIP');
}

export function isUpdateAccountsStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'EDIT_ACCOUNTS_STATUS');
}

export function isViewCustomReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CUSTOM_REPORT');
}

export function isCreateCustomReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CUSTOM_REPORT');
}

export function isUpdateCustomReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CUSTOM_REPORT');
}

export function isDeleteCustomReportAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CUSTOM_REPORT');
}

export function isViewInternalTagsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_INTERNAL_TAG');
}

export function isCreateInternalTagsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_INTERNAL_TAG');
}

export function isUpdateInternalTagsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_INTERNAL_TAG');
}

export function isDeleteInternalTagsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_INTERNAL_TAG');
}

export function isViewInternalCommentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_INTERNAL_COMMENTS');
}

export function isCreateInternalCommentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_INTERNAL_COMMENT');
}

export function isUpdateInternalCommentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_INTERNAL_COMMENT');
}

export function isDeleteInternalCommentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_INTERNAL_COMMENT');
}

export function isViewInternalIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_INTERNAL_ISSUES');
}

export function isCreateInternalIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_INTERNAL_ISSUE');
}

export function isUpdateInternalIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_INTERNAL_ISSUE');
}

export function isDeleteInternalIssuesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_INTERNAL_ISSUE');
}

export function isSimInventoryAllowedForUser(loggedInUser) {
    return isRoleMaster(loggedInUser) || isRoleDataMaster(loggedInUser);
}

export function isPartsModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.PARTS);
}

export function isWorkOrdersModuleEnabled(modules) {
    return isModuleEnabled(modules, MODULE.WORK_ORDERS);
}

export function isViewPartsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_PARTS');
}

export function isManagePartsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_PARTS');
}

export function isDeletePartsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_PART');
}

export function isCreatePartsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_PART');
}

export function isUpdatePartsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_PART');
}

export function isDeleteBranchAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_BRANCH');
}

export function isViewBranchAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_BRANCH');
}

export function isCreateBranchAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_BRANCH');
}

export function isUpdateBranchAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_BRANCH');
}

export function isViewStationaryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_STATIONARY');
}

export function isCreateStationaryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_STATIONARY');
}

export function isUpdateStationaryAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_STATIONARY');
}

export function isViewWorkOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_WORK_ORDERS');
}

export function isManageWorkOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'MANAGE_WORK_ORDERS');
}

export function isDeleteWorkOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_WORK_ORDER');
}

export function isCreateWorkOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_WORK_ORDER');
}

export function isUpdateWorkOrderAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_WORK_ORDER');
}

export function isViewWorkOrderStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_WORK_ORDERS_STATUS');
}

export function isDeleteWorkOrderStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_WORK_ORDER_STATUS');
}

export function isCreateWorkOrderStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_WORK_ORDER_STATUS');
}

export function isUpdateWorkOrderStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_WORK_ORDER_STATUS');
}

export function isManageConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONSIGNMENT');
}

export function isViewConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONSIGNMENT');
}

export function isCreateConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CONSIGNMENT');
}

export function isUpdateConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CONSIGNMENT');
}

export function isDeleteConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CONSIGNMENT');
}

export function isViewFreightBillAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FREIGHT_BILL');
}

export function isCreateFreightBillAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_FREIGHT_BILL');
}

export function isUpdateFreightBillAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_FREIGHT_BILL');
}

export function isCreateEInvoiceInFreightBillAllow(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_EINVOICE');
}

export function isViewEInvoiceInFreightBillAllow(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EINVOICE');
}

export function isDeleteFreightBillAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_FREIGHT_BILL');
}

export function isViewChallansAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CHALLAN');
}

export function isCreateChallansAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CHALLAN');
}

export function isUpdateChallansAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CHALLAN');
}

export function isDeleteChallansAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CHALLAN');
}

export function isViewAdvanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONSIGNMENT_ADVANCE');
}

export function isCreateAdvanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CONSIGNMENT_ADVANCE');
}

export function isUpdateAdvanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CONSIGNMENT_ADVANCE');
}

export function isDeleteAdvanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CONSIGNMENT_ADVANCE');
}

export function isViewGatePassllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_GATE_PASS');
}

export function isCreateGatePassAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_GATE_PASS');
}

export function isUpdateGatePassAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_GATE_PASS');
}

export function isDeleteGatePassAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_GATE_PASS');
}

export function isViewHireSlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_HIRE_SLIP');
}

export function isCreateHireSlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_HIRE_SLIP');
}

export function isUpdateHireSlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_HIRE_SLIP');
}

export function isDeleteHireSlipAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_HIRE_SLIP');
}

export function isViewVoucherAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VOUCHER');
}

export function isCreateVoucherAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VOUCHER');
}

export function isDeleteVoucherAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_VOUCHER');
}

export function isUnapproveVoucherAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UNAPPROVE_VOUCHER');
}

export function isViewGstItemsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_GST_ITEM');
}

export function isCreateGstItemAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_GST_ITEM');
}

export function isUpdateGstItemAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_GST_ITEM');
}

export function isDeleteGstItemAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_GST_ITEM');
}

export function isViewLedgersAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_LEDGER');
}

export function isCreateLedgerAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_LEDGER');
}

export function isUpdateLedgerAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_LEDGER');
}

export function isDeleteLedgerAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_LEDGER');
}

export function isViewLedgersGroupsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_LEDGER_GROUP');
}

export function isCreateLedgerGroupsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_LEDGER_GROUP');
}

export function isUpdateLedgerGroupsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_LEDGER_GROUP');
}

export function isDeleteLedgerGroupsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_LEDGER_GROUP');
}

export function isViewDivisionsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DIVISION');
}

export function isCreateDivisionsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_DIVISION');
}

export function isUpdateDivisionsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_DIVISION');
}

export function isDeleteDivisionsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_DIVISION');
}

export function isViewChargeHeadsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CHARGE_HEAD');
}

export function isCreateChargeHeadAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CHARGE_HEAD');
}

export function isUpdateChargeHeadAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CHARGE_HEAD');
}

export function isDeleteChargeHeadAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CHARGE_HEAD');
}

export function isViewGstRatesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_GST_RATE');
}

export function isCreateGstRateAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_GST_RATE');
}

export function isUpdateGstRateAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_GST_RATE');
}

export function isDeleteGstRateAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_GST_RATE');
}

export function isManageRateChartAllowedForUser(scopes) {
    return (
        includes(getScopesArr(scopes), 'VIEW_CLIENT_RATE_CHART') ||
        includes(getScopesArr(scopes), 'VIEW_VENDOR_RATE_CHART')
    );
}

export function isCreateClientRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CLIENT_RATE_CHART');
}

export function isUpdateClientRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CLIENT_RATE_CHART');
}

export function isDeleteClientRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CLIENT_RATE_CHART');
}

export function isViewClientRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CLIENT_RATE_CHART');
}

export function isCreateVendorRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_VENDOR_RATE_CHART');
}

export function isUpdateVendorRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_VENDOR_RATE_CHART');
}

export function isViewVendorRateChartAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VENDOR_RATE_CHART');
}

export function isCreateMaterialAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_MATERIALS');
}

export function isUpdateMaterialAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_MATERIALS');
}

export function isViewMaterialsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_MATERIALS');
}

export function isDeleteMaterialAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_MATERIALS');
}

export function isCreateLoadTypeAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_LOAD_TYPE');
}

export function isUpdateLoadTypeAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_LOAD_TYPE');
}

export function isViewLoadTypesAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_LOAD_TYPE');
}

export function isDeleteLoadTypeAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_LOAD_TYPE');
}

export function isCreateAuctionAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_AUCTION');
}

export function isUpdateAuctionAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_AUCTION');
}

export function isViewAuctionsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_AUCTION');
}

export function isDeleteAuctionAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_AUCTION');
}

export function isCreateContractAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_CONTRACT');
}

export function isUpdateContractAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CONTRACT');
}

export function isViewContractsManagementAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_CONTRACT');
}

export function isViewEPODNegotiationAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EPODS');
}

export function isViewFreightSettlementAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_BILL');
}

export function isViewCreditDebitModuleAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_BILL');
}

export function isDeleteContractAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_CONTRACT');
}

export function isCheckListQuestionsModuleEnabled() {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    return isRoleOwner(loggedInUser) || isRoleAdmin(loggedInUser);
}

export function isInspectionCheckListModuleEnabled() {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    return (
        isRoleOwner(loggedInUser) ||
        isRoleAdmin(loggedInUser) ||
        isRoleMaster(loggedInUser) ||
        (get(loggedInUser, 'accountId') === FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA && isRoleManager(loggedInUser))
    );
}

export function isDiagnosticsPortalAllowed() {
    const { isSwitchedUser } = window.FLEETX_LOGGED_IN_DATA || {};
    const { switchedUserOriginalRoleId } = window.SWITCHED_USER_ORIGINAL_DATA || {};
    return (
        isSwitchedUser && includes([ROLE_MAP.ROLE_MASTER.id, ROLE_MAP.ROLE_DATA_MASTER.id], switchedUserOriginalRoleId)
    );
}

export function isViewSlotsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SLOTS');
}

export function isUpdateSlotsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_SLOTS');
}

export function isCreateSlotsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_SLOTS');
}

export function isCreateShiftsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_SHIFTS');
}

export function isViewShiftsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_SHIFTS');
}

export function isViewTMSAnalyticsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TRANSPORTER_ANALYTICS');
}

export function isViewTmsConsignmentsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_TMS_CONSIGNMENT');
}

export function isViewEwayBill(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EWAY_BILL');
}

export function isCreateTmsConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TMS_CONSIGNMENT');
}

export function isUpdateTmsConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_TMS_CONSIGNMENT');
}

export function isDeleteTmsConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_TMS_CONSIGNMENT');
}

export function isViewEwayBillProfileAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EWAY_BILL_PROFILE');
}

export function isCreateEwayBillProfileAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_EWAY_BILL_PROFILE');
}

export function isUpdateEwayBillProfileAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_EWAY_BILL_PROFILE');
}

export function isViewEwayBillsAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EWAY_BILL');
}

export function isViewReportTemplatesAllowed(scopes) {
    return isRealtimeVisible(scopes);
}

export function isCreateReportTemplateAllowed(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_REPORT_TEMPLATE_CONFIG');
}

export function isUpdateReportTemplateAllowed(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_REPORT_TEMPLATE_CONFIG');
}

export function isDeleteReportTemplateAllowed(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_REPORT_TEMPLATE_CONFIG');
}

export function isViewEVBatteryLevelAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_EV_BATTERY_LEVEL');
}

export function isCreateIndentVisible(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_INDENT');
}

export function isCreateJobBookigVisible(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_JOB_BOOKING');
}

export function isDeleteIndentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_INDENT');
}

export function isViewLatestFeaturesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_FEATURE');
}

export function isCreateNewFeatureAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_FEATURE');
}

export function isCreateLatestFeatureReviewAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_FEATURE_REVIEW');
}

export function isViewIssuanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ISSUANCE_EVENT');
}

export function isViewRemovalAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_REMOVAL_EVENT');
}

export function isCreateIssuanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ISSUANCE_EVENT');
}

export function isCreateRemovalAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_REMOVAL_EVENT');
}

export function isCreateErpEventAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_ERP_EVENT');
}

export function isViewErpEventAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ERP_EVENT');
}

export function isFetchReportTemplateAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_REPORT_TEMPLATE_CONFIG');
}

export function isFetchQuestionAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_QUESTION');
}

export function viewReasonAllowed(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_REASON_MASTER');
}

export function isViewDispatchPlanAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DISPATCH_PLAN');
}

export function isUpdateDispatchPlanAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_DISPATCH_PLAN');
}

export function isViewLoadPlanAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_LOAD_PLAN');
}

export function isCreateDispatchPlanAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_DISPATCH_PLAN');
}

export function isUpdateDispatchPlanConfigAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_DISPATCH_PLAN_CONFIG');
}

export function isViewDispatchPlanConfigAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_DISPATCH_PLAN_CONFIG');
}

export function isUpdateClientContractChartAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_CLIENT_CONTRACT_CHART');
}

export function isCreateTransferJobAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_TRANSFER_JOB');
}

export function isViewUserAttendanceAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_ATTENDANCE');
}

export function isUpdateAttendanceStatusAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_ATTENDANCE');
}

export function isApplyLeaveAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'APPLY_LEAVE');
}

export function isViewHolidayAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_HOLIDAY');
}

export function isCreateHolidayAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_HOLIDAY');
}

export function isUpdateHolidayAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_HOLIDAY');
}

export function isDeleteHolidayAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_HOLIDAY');
}

export function isApproveLeaveAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'APPROVE_LEAVE');
}

export function isCreateLeaveAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_LEAVE');
}

export function isDeleteLeaveAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_LEAVE');
}

export function isCreateUserPaymentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'CREATE_USER_PAYMENT');
}

export function isViewUserPaymentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_USER_PAYMENT');
}

export function isUpdateUserPaymentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'UPDATE_USER_PAYMENT');
}

export function isDeleteUserPaymentAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'DELETE_USER_PAYMENT');
}

export function isViewIndentAllowedForUser(scopes) {
    return isWithinScope('VIEW_INDENT', getScopesArr(scopes));
}

export function isCreatePaymentAllowedForUser(scopes) {
    return isWithinScope('CREATE_PAYMENT_BILL', getScopesArr(scopes));
}

export function isViewWorkShiftAllowedForUser(scopes) {
    return isWithinScope('VIEW_WORK_SHIFT', getScopesArr(scopes));
}

export function isUpdateWorkShiftAllowedForUser(scopes) {
    return isWithinScope('UPDATE_WORK_SHIFT', getScopesArr(scopes));
}

export function isCreateWorkShiftAllowedForUser(scopes) {
    return isWithinScope('CREATE_WORK_SHIFT', getScopesArr(scopes));
}

export function isDeleteWorkShiftAllowedForUser(scopes) {
    return isWithinScope('DELETE_WORK_SHIFT', getScopesArr(scopes));
}

export function isViewCNFreightChargesAllowedForUser(scopes) {
    return isWithinScope('VIEW_CN_FREIGHT', getScopesArr(scopes));
}

export function isForceUpdateConsignmentsAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'FORCE_UPDATE_TMS_CONSIGNMENT');
}

export function isViewReportsAllowedForUser(scopes) {
    return isWithinScope('VIEW_REPORT', getScopesArr(scopes));
}

export function isViewVihicleAssignment(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_VEHICLE_ASSIGNMENTS');
}

export function isRFQAllowed(scopes, permission) {
    return includes(getScopesArr(scopes), permission);
}

export function isViewStagesAllowedForUser(scopes) {
    return includes(getScopesArr(scopes), 'VIEW_STAGES');
}

export function showBulkVehicleRemove(scopes, loggedInUser) {
    return isDeviceAttachDetachAllowed();
}
