import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Styled from 'styled-components';
import { useToken } from '../../theme';
const PulseLoader = Styled.div `
    &  span{
        width: 15px;
        height: 15px;
        margin: 0 5px;
        background-color: ${(props) => props.$bgcolor};
        border-radius: 50%;
        display: inline-block;
        -webkit-animation: dots 1.2s linear infinite;
         animation: dots 1.2s linear infinite;
    }
        
    & span:nth-child(2){
        animation-delay: 0.2s;
    }
    
    & span:nth-child(3){
        animation-delay: 0.4s;
    }
    
    @keyframes dots {
        50% {
            opacity: 0;
            scale: (0);
            transform: scale(0.2) translateY(8px);
            -webkit-transform: scale(0.2) translateY(8px);
        }
     }

`;
const Loader = (props) => {
    const { inner, tip } = props;
    const { token } = useToken();
    return (_jsxs("div", { className: `${inner ? 'h-100' : 'app'} flex-column d-flex justify-content-center align-items-center`, children: [_jsxs(PulseLoader, { "$bgcolor": token.colorPrimary, children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }), tip && _jsx("div", { children: tip })] }));
};
export default Loader;
