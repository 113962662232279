import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditVehicleLoansEmiComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-consignments-consignments-add-edit" */ 'components/dashboard/vehicleLoansEmi/form/AddEditVehicleLoansEmiComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleLoansEmiComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-consignments-consignments" */ 'components/dashboard/vehicleLoansEmi/VehicleLoansEmiComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const vehicleLoansAndEmiRoutes = [
    {
        path: 'add',
        lazy: AddEditVehicleLoansEmiComponent,
    },
    {
        path: 'edit/:id/',
        lazy: AddEditVehicleLoansEmiComponent,
    },
    {
        index: true,
        lazy: VehicleLoansEmiComponent,
    },
];
export default vehicleLoansAndEmiRoutes;
