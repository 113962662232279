import { jsx as _jsx } from "react/jsx-runtime";
import { filter, map, replace, split, toString } from 'lodash';
const HighLightedText = ({ text, searchWords, highlightStyle }) => {
    const validText = typeof text === 'string' ? text : toString(text);
    const searchWordsNew = filter(searchWords, (word) => word);
    if (!validText || !searchWordsNew.length)
        return _jsx("span", { children: validText });
    const escapedWords = map(searchWordsNew, (word) => replace(word, /[.*+?^${}()|[\]\\]/g, '\\$&'));
    const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');
    const parts = split(validText, regex);
    return (_jsx("div", { children: map(parts, (part, index) => regex.test(part) ? (_jsx("span", { style: highlightStyle, children: part }, index)) : (part)) }));
};
export default HighLightedText;
